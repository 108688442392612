import {
  GripSwitchingModes,
  SingleElectrodeMode,
  SpeedControlStrategies
} from 'bluetooth/bluetoothCommunication/Control';
import {
  DeviceConfigTemplate,
  emgThresholdsEntry
} from 'configurator/consts/deviceConfig/deviceConfig.types';
import toast from 'react-hot-toast';
import i18next from 'i18next';
import { NotificationFactory } from 'lib/NotificationFactory';

export const checkBatteryBeepEmergency = (
  currentConfig: DeviceConfigTemplate,
  differenceAllowedPercentage = 5,
  batteryFunctionVoltageToPercent,
  batteryFunctionPercentToVoltage
) => {
  let triggered = false;
  let batteryBeep = currentConfig?.batteryBeep;
  const emergencyBatterySettings = currentConfig?.emergencyBatterySettings;
  const batteryBeepVoltage = batteryBeep?.[0];
  const batteryBeepStatus = batteryBeep?.[1];
  const emergencyVoltage = emergencyBatterySettings?.[2];

  const batteryPercentageBeep = batteryFunctionVoltageToPercent(batteryBeepVoltage);
  const batteryPercentageEmergency = batteryFunctionVoltageToPercent(emergencyVoltage);
  const batteryBeepDifferencePercentage = batteryPercentageBeep - batteryPercentageEmergency;

  if (
    batteryBeepDifferencePercentage < differenceAllowedPercentage &&
    batteryBeep &&
    batteryBeepStatus === 1
  ) {
    const newBatteryBeepVoltage = batteryFunctionPercentToVoltage(
      batteryPercentageEmergency + differenceAllowedPercentage
    );

    batteryBeep = [newBatteryBeepVoltage, batteryBeep[1]];
    triggered = true;
  }

  return { adjustedSetting: batteryBeep, triggered };
};

export const checkEmgValidity = (config: DeviceConfigTemplate, emgSettings: emgThresholdsEntry) => {
  const changeSignalOpen = emgSettings[0];
  const changeSignalClose = emgSettings[1];
  const speed1Opening = emgSettings[2];
  let speed2Opening = emgSettings[3];
  let speed3Opening = emgSettings[4];
  const speed1Closing = emgSettings[5];
  let speed2Closing = emgSettings[6];
  let speed3Closing = emgSettings[7];

  if (typeof config.speedControlStrategy[0] === 'boolean') return;

  const speedControlStrategy = config.speedControlStrategy[0];
  const singleElectrodeMode = config?.singleElectrodeMode?.[0];
  const singleElectrodeModeSettings = config?.singleElectrodeModeSettings;
  let startPointSignalThresholds = singleElectrodeModeSettings?.[0];
  let freezeModeRelaxationClosing = config?.freezeModeEmg?.[0];
  let freezeModeRelaxationOpening = config?.freezeModeEmg?.[1];
  let doubleStageOpening = config?.freezeModeEmgSettings?.[0];
  let doubleStageClosing = config?.freezeModeEmgSettings?.[1];
  const freezeModeEmgStatus = config?.freezeModeEmg?.[2];

  const maxOpenValue = Math.min(speed1Opening, changeSignalOpen);
  const maxCloseValue = Math.min(speed1Closing, changeSignalClose);

  if (singleElectrodeMode !== undefined) {
    let triggered = false;
    const isSlopeOn = singleElectrodeMode === SingleElectrodeMode.kFastRising;
    if (startPointSignalThresholds >= maxOpenValue) {
      startPointSignalThresholds = maxOpenValue - 1;
      if (isSlopeOn) triggered = true;
    }

    if (triggered)
      NotificationFactory.warningNotification(
        i18next.t(
          'configurator:emg_settings.warning.start_point_signal_threshold',
          'Start point signal threshold must be lower than CS/activation open/close, this value has been adjusted automatically'
        ),
        '',
        {
          id: 'startPointWarning',
          duration: 10000
        }
      );
  }

  if (freezeModeRelaxationClosing !== undefined) {
    let triggered = false;
    const freezeModeOn = freezeModeEmgStatus !== 0;
    if (freezeModeRelaxationOpening >= maxOpenValue) {
      freezeModeRelaxationOpening = maxOpenValue - 1;
      if (freezeModeOn) triggered = true;
    }
    if (freezeModeRelaxationClosing >= maxCloseValue) {
      freezeModeRelaxationClosing = maxCloseValue - 1;
      if (freezeModeOn) triggered = true;
    }

    if (triggered)
      NotificationFactory.warningNotification(
        i18next.t(
          'configurator:emg_settings.warning.freeze_mode_emg',
          'Freeze mode relaxation thresholds must be lower than EMG change signal and activation thresholds, those values has been adjusted automatically'
        ),
        '',
        {
          id: 'freezeModeEmgRelaxationWarning',
          duration: 10000
        }
      );
  }

  if (doubleStageOpening !== undefined) {
    if (doubleStageOpening <= freezeModeRelaxationOpening) {
      doubleStageOpening = freezeModeRelaxationOpening + 1;
    }

    if (doubleStageClosing <= freezeModeRelaxationClosing) {
      doubleStageClosing = freezeModeRelaxationClosing + 1;
    }
  }

  // Correct wrong values
  if (speed1Opening > speed2Opening) {
    speed2Opening = speed1Opening;
    if (speedControlStrategy === SpeedControlStrategies.kProportional)
      NotificationFactory.warningNotification(
        i18next.t(
          'configurator:emg_settings.warning.speed_2_bigger_than_speed_1',
          "Speed 2 must be bigger than speed 1, check 'Proportional' view in emg settings"
        ),
        '',
        {
          id: 'speedControlStrategiesSpeed2BiggerThanSpeed1',
          duration: 10000
        }
      );
  }

  if (speed2Opening > speed3Opening) {
    speed3Opening = speed2Opening;
    if (speedControlStrategy === SpeedControlStrategies.kProportional)
      NotificationFactory.warningNotification(
        i18next.t(
          'configurator:emg_settings.warning.speed_3_bigger_than_speed_2',
          "Speed 3 must be bigger than speed 2, check 'Proportional' view in emg settings"
        ),
        '',
        {
          id: 'speedControlStrategiesSpeed3BiggerThanSpeed2',
          duration: 10000
        }
      );
  }
  if (speed1Closing > speed2Closing) {
    speed2Closing = speed1Closing;
    if (speedControlStrategy === SpeedControlStrategies.kProportional)
      NotificationFactory.warningNotification(
        i18next.t(
          'configurator:emg_settings.warning.speed_2_bigger_than_speed_1',
          "Speed 2 must be bigger than speed 1, check 'Proportional' view in emg settings"
        ),
        '',
        {
          id: 'speedControlStrategiesSpeed2BiggerThanSpeed1',
          duration: 10000
        }
      );
  }
  if (speed2Closing > speed3Closing) {
    speed3Closing = speed2Closing;
    if (speedControlStrategy === SpeedControlStrategies.kProportional)
      NotificationFactory.warningNotification(
        i18next.t(
          'configurator:emg_settings.warning.speed_3_bigger_than_speed_2',
          "Speed 3 must be bigger than speed 2, check 'Proportional' view in emg settings"
        ),
        '',
        {
          id: 'speedControlStrategiesSpeed3BiggerThanSpeed2',
          duration: 10000
        }
      );
  }

  const validatedEmg = [
    changeSignalOpen,
    changeSignalClose,
    speed1Opening,
    speed2Opening,
    speed3Opening,
    speed1Closing,
    speed2Closing,
    speed3Closing,
    emgSettings[8],
    emgSettings[9]
  ];

  return {
    emgThresholds: validatedEmg,
    freezeModeEmg: [
      freezeModeRelaxationClosing,
      freezeModeRelaxationOpening,
      config?.freezeModeEmg?.[2],
      config?.freezeModeEmg?.[3]
    ],
    freezeModeEmgSettings: [
      doubleStageOpening,
      doubleStageClosing,
      config?.freezeModeEmgSettings?.[2],
      config?.freezeModeEmgSettings?.[3]
    ],
    singleElectrodeModeSettings: [
      startPointSignalThresholds,
      singleElectrodeModeSettings[1],
      singleElectrodeModeSettings[2],
      singleElectrodeModeSettings[3]
    ]
  };
};
