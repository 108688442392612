import * as Sentry from '@sentry/react';
import Login from 'adp-panel/pages/Auth/Login';
import RegisterUser from 'adp-panel/pages/Auth/RegisterUser/RegisterUser';
import RegisterInvitedUser from 'adp-panel/pages/Auth/RegisterUser/RegisterInvitedUser';
import ResetPassword from 'adp-panel/pages/Auth/ResetPassword';
import SetUpPass from 'adp-panel/pages/Auth/SetUpPass';
import * as routes from 'constants/routes';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const NotProtectedApp = () => {
  const [searchParams] = useSearchParams();

  return (
    <SentryRoutes>
      <Route path={routes.LOGIN} element={<Login />} />
      <Route path={routes.FORGOTTEN_PASSWORD} element={<ResetPassword />} />
      <Route path={routes.SETUP_PASS} element={<SetUpPass />} />
      <Route path={routes.ACCEPT_INVITATION} element={<RegisterInvitedUser />} />
      <Route path={routes.REGISTER_USER} element={<RegisterUser />} />
      <Route path='/' element={<Navigate to={routes.LOGIN} replace />} />
      <Route
        path='*'
        element={
          <Navigate
            to={
              searchParams.get('redirect')
                ? `${routes.LOGIN}${window.location.search}`
                : routes.LOGIN
            }
            replace
          />
        }
      />
    </SentryRoutes>
  );
};

export default NotProtectedApp;
