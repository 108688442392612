import { gripsImagesMap } from 'configurator/utils/gripsImages';
import { useEffect, useState } from 'react';
import Divider from 'configurator/components/atoms/Divider/Divider';
import GripsChooser2 from 'configurator/components/molecules/GripsChooser2/GripsChooser2';
import GraphChooserTree from 'configurator/components/molecules/GripsChooserTree/GripsChooserTree';
import { HISTORY_EVENTS, NonOpposedGrips, OpposedGrips } from 'configurator/consts/consts';
import {
  gripPairsConfigEntry,
  gripSequentialConfigEntry
} from 'configurator/consts/deviceConfig/deviceConfig.types';
import { useConfigStore } from 'configurator/reducers/configStore';
import { getCurrentConfigSelector } from 'configurator/reducers/helpers/selectors';
import { useReplayStore } from 'configurator/reducers/replayStore';
import { configSettingsNamesMap, controlModeMap } from 'configurator/utils/definesLocal';
import { useTranslation } from 'react-i18next';
import { Grips } from '../../../bluetooth/bluetoothCommunication/Grips';
import { ControlModes } from '../../../bluetooth/bluetoothCommunication/Control';
import { InnerWrapper } from './styled';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import CustomPageHeader from 'configurator/components/atoms/Typography/CustomHeader';
import useCanAccess from 'adp-panel/hoc/useCanAccess';
import { useGetCustomGrips } from 'configurator/hooks/api/useDevice';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import CheckIcon from '@mui/icons-material/Check';

const ChooseGripsComponent = () => {
  const [sequentialMode, setSequentialMode] = useState(false);
  const [opened, setOpened] = useState<number | null>(null);
  const [gripsOpposed, setGripsOpposed] = useState<Grips[]>(OpposedGrips);
  const [gripsNonOpposed, setGripsNonOpposed] = useState<Grips[]>(NonOpposedGrips);
  const setConfigProperty = useConfigStore((state) => state.setConfigProperty);
  const { canAccess } = useCanAccess({ action: 'changeGrips', resource: 'grips' });
  const { canAccess: canAccessTransition } = useCanAccess({
    action: 'transitionMode',
    resource: 'grips'
  });
  const { enabled: replayIsEnabled } = useReplayStore();
  const { gripPairsConfig, controlMode, gripSequentialConfig, gripsPositions } =
    useConfigStore(getCurrentConfigSelector);
  const addConfigHistory = useConfigStore((state) => state.addConfigHistory);
  const { deviceId } = useDeviceInfoStore((state) => ({
    deviceId: state.deviceId
  }));
  const { result: customGripsAllowed, isLoading: isLoadingCustomGrips } = useGetCustomGrips({
    deviceId
  });
  const opposedCustomGrips =
    customGripsAllowed
      ?.filter((customGrip) => Boolean(customGrip?.opposed))
      ?.map((customGrip) => customGrip.grip_number) || [];
  const nonOpposedCustomGrips =
    customGripsAllowed
      ?.filter((customGrip) => !customGrip?.opposed)
      ?.map((customGrip) => customGrip.grip_number) || [];
  const { t } = useTranslation();
  const pairsOpposed = [
    gripPairsConfig?.[0],
    gripPairsConfig?.[1],
    gripPairsConfig?.[2],
    gripPairsConfig?.[3]
  ];
  const pairsNonOpposed = [
    gripPairsConfig?.[4],
    gripPairsConfig?.[5],
    gripPairsConfig?.[6],
    gripPairsConfig?.[7]
  ];
  const sequentialOpposed = [
    gripSequentialConfig?.[0],
    gripSequentialConfig?.[1],
    gripSequentialConfig?.[2],
    gripSequentialConfig?.[3],
    gripSequentialConfig?.[4],
    gripSequentialConfig?.[5]
  ];
  const sequentialNonOpposed = [
    gripSequentialConfig?.[6],
    gripSequentialConfig?.[7],
    gripSequentialConfig?.[8],
    gripSequentialConfig?.[9],
    gripSequentialConfig?.[10],
    gripSequentialConfig?.[11]
  ];

  useEffect(() => {
    if (Object.keys(gripsPositions).length > 0) {
      setGripsOpposed(
        Object.keys(gripsPositions)
          .map((position) => parseInt(position, 10))
          .filter(
            (position) =>
              position !== Grips.kGripCamera &&
              position !== Grips.kGripTypeUnknown &&
              [...opposedCustomGrips, ...OpposedGrips].includes(position)
          )
      );
      setGripsNonOpposed(
        Object.keys(gripsPositions)
          .map((position) => parseInt(position, 10))
          .filter(
            (position) =>
              position !== Grips.kGripCamera &&
              position !== Grips.kGripTypeUnknown &&
              [...nonOpposedCustomGrips, ...NonOpposedGrips].includes(position)
          )
      );
    }
  }, [gripsPositions]);

  const updateHistory = (prevState) => {
    addConfigHistory(HISTORY_EVENTS.chooseGrips, prevState);
  };

  useEffect(() => {
    if (controlMode?.[0] !== null) {
      setSequentialMode(controlMode?.[0] === ControlModes.kGripSequence);
      setOpened(null);
    }
  }, [controlMode]);

  const updateMode = (mode: ControlModes) => {
    if (replayIsEnabled || !canAccessTransition) {
      return;
    }
    updateHistory(setConfigProperty('controlMode', [mode]));
  };

  const updatePairGrips = (option, gripOrder, isNonOpposed) => {
    let nonOpposed;
    let opposed;

    if (sequentialMode) {
      nonOpposed = sequentialNonOpposed;
      opposed = sequentialOpposed;
    } else {
      nonOpposed = pairsNonOpposed;
      opposed = pairsOpposed;
    }

    let newGripsArray: Grips[] = [];
    let oldGripsArray: Grips[] = [];
    if (isNonOpposed) {
      oldGripsArray = nonOpposed;
    } else {
      oldGripsArray = opposed;
    }
    newGripsArray = oldGripsArray.map((element, index) => {
      if (index === gripOrder) {
        element = option;
      }
      return element;
    });

    let gripsArray: Grips[] = [];

    if (isNonOpposed) {
      gripsArray = [...opposed, ...newGripsArray];
    } else {
      gripsArray = [...newGripsArray, ...nonOpposed];
    }
    if (sequentialMode) {
      updateHistory(
        setConfigProperty('gripSequentialConfig', gripsArray as gripSequentialConfigEntry)
      );
    } else {
      updateHistory(setConfigProperty('gripPairsConfig', gripsArray as gripPairsConfigEntry));
    }
  };

  const updateOpened = (index) => {
    if (index === opened) {
      setOpened(null);
    } else {
      setOpened(index);
    }
  };

  const disableConfigToggle = replayIsEnabled || !canAccessTransition;
  const disableToggle = disableConfigToggle || controlMode?.[0] === ControlModes.kCoapt;
  const toggleTip = (toggleDisabled, controlMode, disableConfigToggle) => {
    let tip: null | string = null;
    const noAccess = t('configurator:component.toggle_tip.no_access', "You don't have access");

    if (toggleDisabled) tip = noAccess;
    if (controlMode[0] === ControlModes.kCoapt)
      tip = disableConfigToggle
        ? noAccess
        : t(
            'configurator:component.choose_grips_component.toggle_tip.cannot_change',
            `You can't change {{controlMode}} when {{selectedMode}} is selected as {{inputDevice}}`,
            {
              // @ts-ignore
              controlMode: t(configSettingsNamesMap.get('controlMode')),
              selectedMode: t(controlModeMap.get(ControlModes.kCoapt)),
              // @ts-ignore
              inputDevice: t(configSettingsNamesMap.get('inputDevice'))
            }
          );

    return tip;
  };

  const selectedToggleButtonStyle = {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#33499C',
      color: 'white'
    }
  };

  const toggleIconStyle = { marginRight: '6px', fontSize: '20px' };

  return (
    <>
      <InnerWrapper>
        <CustomPageHeader
          header={t('configurator:prosthesis_settings.choose_grips')}
          sx={{ margin: 0 }}
          tooltipText={t('configurator:header.choose_grips.tooltip', 'Tooltip')}
        />
        <Tooltip title={toggleTip(disableToggle, controlMode, disableConfigToggle)}>
          <ToggleButtonGroup value={controlMode} disabled={disableToggle}>
            <ToggleButton
              value={ControlModes.kGripPairs}
              key='pairing'
              sx={selectedToggleButtonStyle}
              onClick={() => updateMode(ControlModes.kGripPairs)}>
              {controlMode?.[0] === ControlModes.kGripPairs && <CheckIcon sx={toggleIconStyle} />}
              {t('configurator:component.choose_grips.pairing_mode', 'Pairing mode')}
            </ToggleButton>
            <ToggleButton
              value={ControlModes.kGripSequence}
              key='sequential'
              sx={selectedToggleButtonStyle}
              onClick={() => updateMode(ControlModes.kGripSequence)}>
              {controlMode?.[0] === ControlModes.kGripSequence && (
                <CheckIcon sx={toggleIconStyle} />
              )}
              {t('configurator:component.choose_grips.sequential_mode', 'Sequential mode')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Tooltip>
      </InnerWrapper>
      {sequentialMode ? (
        <GripsChooser2
          data-tour-general='opposed'
          grips={gripsOpposed}
          gripsImgs={gripsImagesMap}
          values={sequentialOpposed}
          opened={opened}
          indexes={[0, 1, 2, 3, 4]}
          nonOpposed={false}
          onChange={updatePairGrips}
          updateOpened={updateOpened}
          disabled={replayIsEnabled || !canAccess}
          customGripsAllowed={customGripsAllowed}
        />
      ) : (
        <GraphChooserTree
          data-tour-general='opposed'
          grips={gripsOpposed}
          gripsImgs={gripsImagesMap}
          values={pairsOpposed}
          onChange={updatePairGrips}
          updateOpened={updateOpened}
          opened={opened}
          indexes={[0, 1, 2, 3]}
          disabled={replayIsEnabled || !canAccess}
          customGripsAllowed={customGripsAllowed}
        />
      )}
      <Divider margin='40px' />
      {sequentialMode ? (
        <GripsChooser2
          grips={gripsNonOpposed}
          gripsImgs={gripsImagesMap}
          nonOpposed
          opened={opened}
          indexes={[5, 6, 7, 8, 9]}
          values={sequentialNonOpposed}
          updateOpened={updateOpened}
          onChange={updatePairGrips}
          disabled={replayIsEnabled || !canAccess}
          customGripsAllowed={customGripsAllowed}
        />
      ) : (
        <GraphChooserTree
          grips={gripsNonOpposed}
          gripsImgs={gripsImagesMap}
          values={pairsNonOpposed}
          nonOpposed
          onChange={updatePairGrips}
          updateOpened={updateOpened}
          opened={opened}
          indexes={[4, 5, 6, 7]}
          disabled={replayIsEnabled || !canAccess}
          customGripsAllowed={customGripsAllowed}
        />
      )}
    </>
  );
};

export default ChooseGripsComponent;
