import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { EVENTS } from 'configurator/consts/events';
import { abortTestProcedure } from 'bluetooth/bluetoothFunctions';
import { useConfigStore } from 'configurator/reducers/configStore';
import { ProcedureTypes } from 'bluetooth/bluetoothCommunication/Procedures';
import ModalWrapper from 'components/Modals/ModalWrapper';

const PreventInputModal = () => {
  const { t } = useTranslation();
  const { procedureState, blockModals } = useUiStore((state) => ({
    procedureState: state.procedureState,
    blockModals: state.blockModals
  }));
  const { currentlyRunningProcedure } = useConfigStore((state) => ({
    currentlyRunningProcedure: state.currentlyRunningProcedure
  }));

  const abortProcedure = () => {
    const event = new Event(EVENTS.stopProcedure);
    window.dispatchEvent(event);
    abortTestProcedure();
  };

  const isRunningCheckMovementRangeCombined =
    procedureState === FETCHING_STATES.loading &&
    currentlyRunningProcedure === ProcedureTypes.checkMovementRangeTestClosingCombined;

  const showMessage = blockModals?.[0]?.showMessage;

  const canShowMessage = () => {
    if (showMessage === undefined || showMessage === true) return true;
    if (showMessage === false) return false;

    return true;
  };

  return (
    <ModalWrapper maxWidth='200px'>
      {canShowMessage() && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
          {t('configurator:component.prevent_input_modal.wait', 'Please wait...')}
          {isRunningCheckMovementRangeCombined && (
            <div style={{ marginTop: '16px' }}>
              <Button onClick={abortProcedure}>
                {t('configurator:component.prevent_input_modal.abort', 'Abort')}
              </Button>
            </div>
          )}
        </div>
      )}
    </ModalWrapper>
  );
};

export default PreventInputModal;
