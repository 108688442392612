/* eslint-disable react/jsx-key */
import React, { useRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import Card from 'configurator/components/atoms/Card/Card';
import CompanyImg from 'assets/company_img.jpg';
import { CheckComponent } from 'configurator/views/DeviceConfig/components';
import { useModal } from 'configurator/hooks/useModal';
import ShowNotesModal from 'configurator/modals/ShowNotesModal';
import { useDeviceManager } from 'configurator/hooks/api/useDeviceManager';
import {
  findDifferencesImport,
  transformConfigAPI
} from 'configurator/utils/Config/transformConfig';
import { useConfigStore } from 'configurator/reducers/configStore';
import useImportButton from 'configurator/hooks/useImportButton';
import RestoreConfigHistoryModal from 'configurator/modals/RestoreConfigHistoryModal';
import { ReactComponent as Undo } from 'assets/undo-solid.svg';
import { ReactComponent as Eye } from 'assets/eye-solid.svg';
import { ReactComponent as NotesIcon } from 'assets/note-sticky-regular.svg';
import {
  CalendarDay,
  CalendarDayWrapper,
  CalendarHeaderWrapper,
  CalendarTable,
  ClinicianImg,
  DetailsWrapper,
  EventWrapper,
  ModalButtonWrapper,
  ModalDetail,
  ModalInnerWrapper,
  ModalWrapper,
  StyledTimes
} from './styled';
import { DEVICE_HISTORY } from 'constants/routes';
import CustomButton from 'components/Button/CustomButton';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

const transformMonth = (month: any) => {
  const previousMonth = month.month(month.month() - 1);
  const nextMonth = month.month(month.month() + 1);

  let previousMonthDays: any = [];
  if (month.day() - 1 > 0) {
    previousMonthDays = [...Array(month.day() - 1).keys()].map((previousDay) => ({
      day: previousMonth.daysInMonth() - previousDay,
      month: previousMonth.month(),
      year: previousMonth.year()
    }));
  }

  const coreMonthDays = [...Array(month.daysInMonth()).keys()].map((coreDay) => ({
    day: coreDay + 1,
    month: month.month(),
    year: month.year()
  }));

  const nextMonthDays = [...Array(43 - (month.day() + month.daysInMonth())).keys()].map(
    (nextDay) => ({
      day: nextDay + 1,
      month: nextMonth.month(),
      year: nextMonth.year()
    })
  );
  return [...previousMonthDays.reverse(), ...coreMonthDays, ...nextMonthDays];
};

const CalendarDayItem = ({ day, active, events }: any) => {
  const [showModal, setShowModal] = useState(false);
  const [modalPositions, setModalPosition] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 0,
    height: 0,
    flip: false
  });
  const [selectedEvent, setSelectedEvent] = useState<number>();
  const refContainer = useRef(null);
  const { handleClose, handleOpen, isOpen } = useModal();
  const navigate = useNavigate();
  const {
    handleClose: handleCloseRestoreConfigHistoryModal,
    handleOpen: handleOpenRestoreConfigHistoryModal,
    isOpen: isRestoreConfigHistoryModalOpen
  } = useModal();
  const { common, modes } = useConfigStore((state: any) => ({
    modes: state.config.modes,
    common: state.config.common
  }));
  const { restoreConfigHistory, isLoadingDeviceManager } = useDeviceManager();
  const [importDifferences, setImportDifferences] = useState(false);
  const { importTooltip, disableImportButton } = useImportButton(importDifferences);
  const { t } = useTranslation();

  const updateModalPosition = () => {
    if (refContainer.current) {
      const viewPort = document.querySelector('body')!;
      const viewPortWidth = viewPort.getBoundingClientRect().width;
      const viewPortHeight = viewPort.getBoundingClientRect().height;
      // @ts-ignore
      const eventCardPosition = refContainer.current!.getBoundingClientRect();
      let offsetTop = 0;
      let doFlip = false;
      if (eventCardPosition.top < 150) {
        offsetTop = 200;
      }
      if (viewPortHeight - eventCardPosition.bottom < 150) {
        offsetTop = -100;
      }
      if (viewPortWidth - eventCardPosition.right < 280) {
        doFlip = true;
      }
      setModalPosition({
        top: eventCardPosition.top,
        left: eventCardPosition.left,
        bottom: eventCardPosition.bottom,
        right: eventCardPosition.right,
        width: eventCardPosition.width,
        height: eventCardPosition.height + offsetTop,
        flip: doFlip
      });
    }
  };

  const handleOpenModal = () => {
    setSelectedEvent(0);
    updateModalPosition();
    setShowModal(true);
  };

  const handleRestore = async () => {
    await restoreConfigHistory(events?.[selectedEvent!].id);
    handleCloseRestoreConfigHistoryModal();
  };

  const handlePush = (configId: number, changeEntry: any) => {
    navigate(`${DEVICE_HISTORY}/${configId}`, { state: { detail: changeEntry } });
  };

  useEffect(() => {
    if (selectedEvent !== undefined) {
      setImportDifferences(
        findDifferencesImport(
          common,
          modes,
          transformConfigAPI(events?.[selectedEvent], modes).parsedConfig
        )
      );
    }
  }, [selectedEvent]);

  return (
    <CalendarDayWrapper ref={refContainer}>
      <CalendarDay active={active}>{day.day}</CalendarDay>
      {events && (
        <EventWrapper onClick={handleOpenModal}>
          <DetailsWrapper>
            <span>{events.length} configs</span>
            <ClinicianImg src={CompanyImg} alt='Clinician' />
          </DetailsWrapper>
        </EventWrapper>
      )}
      {showModal && selectedEvent !== undefined && (
        <>
          <ModalWrapper />
          <Card
            padding='20px'
            style={
              modalPositions.flip
                ? {
                    zIndex: '999',
                    position: 'absolute',
                    width: '280px',
                    bottom: `${-modalPositions.height}px`,
                    right: `${modalPositions.width}px`
                  }
                : {
                    zIndex: '999',
                    position: 'absolute',
                    width: '280px',
                    bottom: `${-modalPositions.height}px`,
                    left: `${modalPositions.width}px`
                  }
            }>
            <ModalInnerWrapper>
              <StyledTimes onClick={() => setShowModal(false)} />
              <ModalDetail>
                <TextField
                  select
                  label='Entry date'
                  id='entry-date'
                  SelectProps={{
                    onChange: (e: any) => setSelectedEvent(e.target.value),
                    value: selectedEvent,
                    MenuProps: {
                      sx: { maxHeight: 250 }
                    }
                  }}>
                  {events.map((event: any, index: any) => (
                    <MenuItem key={`entry-date_${event.index}`} value={index}>{`[${
                      event.index
                    }] ${dayjs(event.created_at).format('DD.MM.YYYY HH:mm')}`}</MenuItem>
                  ))}
                </TextField>
              </ModalDetail>
              <ModalDetail>
                <h5>Created at</h5>
                <p>{dayjs(events?.[selectedEvent]?.created_at).format('DD.MM.YYYY HH:mm')}</p>
              </ModalDetail>
              <ModalDetail>
                <h5>Author name</h5>
                <Typography>{events?.[selectedEvent]?.author?.name}</Typography>
              </ModalDetail>
              <ModalDetail>
                <h5>Restore point</h5>
                {events?.[selectedEvent]?.restore_point === 1 ? <CheckComponent /> : '-'}
              </ModalDetail>
              <div>
                <CustomButton
                  Icon={NotesIcon}
                  onClick={handleOpen}
                  iconProps={{ width: '12px', fill: '#FFF' }}>
                  {t('configurator:component.device_history.button.show_notes', 'Show notes')}
                </CustomButton>
              </div>
              <ModalButtonWrapper>
                <Tooltip title={importTooltip()}>
                  <CustomButton
                    Icon={Undo}
                    iconProps={{ width: '14px' }}
                    onClick={handleOpenRestoreConfigHistoryModal}
                    disabled={disableImportButton()}>
                    Load
                  </CustomButton>
                </Tooltip>
                <CustomButton
                  Icon={Eye}
                  iconProps={{ width: '14px', fill: '#FFF' }}
                  onClick={() => handlePush(events?.[selectedEvent]?.id, events?.[selectedEvent])}>
                  See
                </CustomButton>
              </ModalButtonWrapper>
            </ModalInnerWrapper>
          </Card>
        </>
      )}
      {isRestoreConfigHistoryModalOpen && selectedEvent !== undefined && (
        <RestoreConfigHistoryModal
          handleClose={handleCloseRestoreConfigHistoryModal}
          handleAccept={handleRestore}
          entry={transformConfigAPI(events?.[selectedEvent], modes).parsedConfig}
          isLoading={isLoadingDeviceManager}
        />
      )}
      {isOpen && selectedEvent !== undefined && (
        <ShowNotesModal
          handleClose={handleClose}
          deviceId={events?.[selectedEvent]?.device_id}
          configId={events?.[selectedEvent]?.id}
        />
      )}
    </CalendarDayWrapper>
  );
};

const Calendar = ({ month, events, months }: any) => {
  const calendarDays = transformMonth(month);
  const { t } = useTranslation();

  return (
    <div>
      <CalendarHeaderWrapper>
        <h2>
          {t(months[month.month()])}, {month.year()}
        </h2>
      </CalendarHeaderWrapper>
      <div>
        <CalendarTable>
          <tr>
            <th>{t('common:weekdays.monday', 'Monday')}</th>
            <th>{t('common:weekdays.tuesday', 'Tuesday')}</th>
            <th>{t('common:weekdays.wednesday', 'Wednesday')}</th>
            <th>{t('common:weekdays.thursday', 'Thursday')}</th>
            <th>{t('common:weekdays.friday', 'Friday')}</th>
            <th>{t('common:weekdays.saturday', 'Saturday')}</th>
            <th>{t('common:weekdays.sunday', 'Sunday')}</th>
          </tr>
          {[...Array(6).keys()].map((week) => (
            <tr>
              {[...Array(7).keys()].map((dayOfWeek) => {
                const currentDate = calendarDays[7 * week + dayOfWeek];
                const currentDateParsed = dayjs()
                  .year(currentDate.year)
                  .month(currentDate.month)
                  .date(currentDate.day);
                let groupIndex: null | number = null;
                for (let index = 0; index < events.length; index += 1) {
                  const eventGroupDate = dayjs(events[index][0].created_at);
                  if (eventGroupDate.isSame(currentDateParsed, 'day')) {
                    groupIndex = index;
                    break;
                  }
                }
                return (
                  <CalendarDayItem
                    day={currentDate}
                    active={currentDate.month === month.month()}
                    events={groupIndex !== null ? events[groupIndex] : groupIndex}
                  />
                );
              })}
            </tr>
          ))}
        </CalendarTable>
      </div>
    </div>
  );
};

export default Calendar;
