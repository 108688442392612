import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const useOfflineNotification = (isOnline) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!isOnline) {
      toast(
        t(
          'notifications:offline_status',
          'It seems you are offline, please check your internet connection.'
        ),
        { icon: '⚠️', id: 'offlineStatus', duration: Infinity }
      );
    } else {
      toast.dismiss('offlineStatus');
    }
  }, [isOnline, t]);
};

export default useOfflineNotification;
