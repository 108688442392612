/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import Divider from 'configurator/components/atoms/Divider/Divider';
import {
  aetherBatteryVoltageFunctions,
  allUserFeedbackTypeMap,
  configSettingsNamesMap,
  controlModeMap,
  freezeModeMap,
  genericSwitchingMap,
  gripsGroupsOptionsMap,
  gripsGroupsOptionsReversedMap,
  gripSwitchingMap,
  inputDeviceMap,
  inputSiteMap,
  singleElectrodeModeMap,
  speedControlMap,
  startOnStartupMap
} from 'configurator/utils/definesLocal';
import { TypesConfig } from 'configurator/utils/types';
import ConfigItem from 'configurator/components/atoms/ConfigItem/ConfigItem';
import { DeviceConfigTemplate } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { Table } from 'configurator/components/atoms/Table/Table';
import Accordion from 'configurator/components/atoms/Accordion/Accordion';
import { InputSites } from 'bluetooth/bluetoothCommunication/Control';
import { useConfigStore } from 'configurator/reducers/configStore';
import { singleElectrodeSettingsAlternatingPositions } from 'configurator/consts/deviceConfig/configPropertiesPositions';
import { singleElectrodeSettingsAlternatingNamingsMap } from 'configurator/consts/deviceConfig/configPropertiesNamings';
import Card from 'adp-panel/components/Card/Card';
import { MenuItem, TextField } from '@mui/material';
import i18next from 'i18next';
import { Grips } from 'bluetooth/bluetoothCommunication/Grips';

export enum ToggleTypesChangesHistory {
  all = 0,
  emg = 1,
  grips = 2,
  prosthesis = 3
}

interface ConfigComponentsProps {
  config?: DeviceConfigTemplate;
  transformedConfig?: any;
  currentToggle?: ToggleTypesChangesHistory;
}

const ConfigWrapper = styled.div`
  display: grid;
  gap: 24px;
`;

const DividerWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

const ConfigList = styled.ul`
  display: grid;
  gap: 16px;
  list-style: none;
`;

export const ConfigDetailsMap = {
  gripsPositions: (config) => (transformedConfig, selectedGrip, setSelectedGrip, gripNumber) => (
    <Accordion
      header={i18next.t(
        'configurator:component.config_template.grips_positions.accordion_header',
        'Grip positions'
      )}>
      <DividerWrapper>
        <Card>
          <div style={{ width: '300px' }}>
            <TextField
              fullWidth
              id='selected-grip'
              label={i18next.t(
                'configurator:component.config_template.grips_positions.grip_label',
                'Grip'
              )}
              select
              sx={{ width: '300px' }}
              SelectProps={{
                value: selectedGrip,
                onChange: (e: any) => setSelectedGrip(e.target.value),
                renderValue: (value) => {
                  if (!value) return 'Placeholder';
                  // @ts-ignore
                  return i18next.t(value, 'Placeholder');
                }
              }}>
              {transformedConfig?.grips.map((grip: any) => (
                <MenuItem key={`selected-grip-${grip}`} value={grip}>
                  {i18next.t(grip)}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <Table>
            <tr>
              <th style={{ width: '60%' }}>
                {i18next.t(
                  'configurator:component.config_template.grips_positions.finger',
                  'Finger'
                )}
              </th>
              <th>
                {i18next.t(
                  'configurator:component.config_template.grips_positions.initial',
                  'Initial'
                )}
              </th>
              <th>
                {i18next.t('configurator:component.config_template.grips_positions.limit', 'Limit')}
              </th>
            </tr>
            <tr>
              <td>
                {i18next.t('configurator:component.config_template.grips_positions.thumb', 'Thumb')}
              </td>
              <td>{config?.gripsPositions?.[gripNumber]?.initial?.[0]}</td>
              <td>{config?.gripsPositions?.[gripNumber]?.limit?.[0]}</td>
            </tr>
            <tr>
              <td>
                {i18next.t('configurator:component.config_template.grips_positions.index', 'Index')}
              </td>
              <td>{config?.gripsPositions?.[gripNumber]?.initial?.[1]}</td>
              <td>{config?.gripsPositions?.[gripNumber]?.limit?.[1]}</td>
            </tr>
            <tr>
              <td>
                {i18next.t(
                  'configurator:component.config_template.grips_positions.middle',
                  'Middle'
                )}
              </td>
              <td>{config?.gripsPositions?.[gripNumber]?.initial?.[2]}</td>
              <td>{config?.gripsPositions?.[gripNumber]?.limit?.[2]}</td>
            </tr>
            <tr>
              <td>
                {i18next.t('configurator:component.config_template.grips_positions.ring', 'Ring')}
              </td>
              <td>{config?.gripsPositions?.[gripNumber]?.initial?.[3]}</td>
              <td>{config?.gripsPositions?.[gripNumber]?.limit?.[3]}</td>
            </tr>
            <tr>
              <td>
                {i18next.t('configurator:component.config_template.grips_positions.pinky', 'Pinky')}
              </td>
              <td>{config?.gripsPositions?.[gripNumber]?.initial?.[4]}</td>
              <td>{config?.gripsPositions?.[gripNumber]?.limit?.[4]}</td>
            </tr>
          </Table>
        </Card>
      </DividerWrapper>
    </Accordion>
  ),
  gripPairsConfig: (transformedConfig) => (
    <Accordion
      header={i18next.t(
        'configurator:component.config_template.grip_pairs_config.accordion_header',
        'Pairs config'
      )}>
      <DividerWrapper>
        <Card>
          <Table>
            <caption>
              {i18next.t(
                'configurator:component.config_template.grip_pairs_config.opposed',
                'Opposed'
              )}
            </caption>
            <tr>
              <th colSpan={2} style={{ width: '50%' }}>
                {i18next.t(
                  'configurator:component.config_template.grip_pairs_config.primary',
                  'Primary'
                )}
              </th>
              <th colSpan={2} style={{ width: '50%' }}>
                {i18next.t(
                  'configurator:component.config_template.grip_pairs_config.secondary',
                  'Secondary'
                )}
              </th>
            </tr>
            <tr>
              <td>{transformedConfig?.gripPairsConfig[0]}</td>
              <td>{transformedConfig?.gripPairsConfig[1]}</td>
              <td>{transformedConfig?.gripPairsConfig[2]}</td>
              <td>{transformedConfig?.gripPairsConfig[3]}</td>
            </tr>
          </Table>
          <Divider margin='20px' />
          <Table>
            <caption>
              {i18next.t(
                'configurator:component.config_template.grip_pairs_config.non_opposed',
                'Non-opposed'
              )}
            </caption>
            <tr>
              <th colSpan={2}>
                {i18next.t(
                  'configurator:component.config_template.grip_pairs_config.primary',
                  'Primary'
                )}
              </th>
              <th colSpan={2}>
                {i18next.t(
                  'configurator:component.config_template.grip_pairs_config.secondary',
                  'Secondary'
                )}
              </th>
            </tr>
            <tr>
              <td colSpan={1}>{transformedConfig?.gripPairsConfig[4]}</td>
              <td colSpan={1}>{transformedConfig?.gripPairsConfig[5]}</td>
              <td colSpan={1}>{transformedConfig?.gripPairsConfig[6]}</td>
              <td colSpan={1}>{transformedConfig?.gripPairsConfig[7]}</td>
            </tr>
          </Table>
        </Card>
      </DividerWrapper>
    </Accordion>
  ),
  gripSequentialConfig: (transformedConfig) => (
    <Accordion
      header={i18next.t(
        'configurator:component.config_template.grip_sequential_config.accordion_header',
        'Grips sequential config'
      )}>
      <DividerWrapper>
        <Card>
          <Table>
            <caption>
              {i18next.t(
                'configurator:component.config_template.grip_sequential_config.opposed',
                'Opposed'
              )}
            </caption>
            <tr>
              <td>{transformedConfig?.gripSequentialConfig[0]}</td>
              <td>{transformedConfig?.gripSequentialConfig[1]}</td>
              <td>{transformedConfig?.gripSequentialConfig[2]}</td>
              <td>{transformedConfig?.gripSequentialConfig[3]}</td>
              <td>{transformedConfig?.gripSequentialConfig[4]}</td>
            </tr>
          </Table>
          <Divider margin='20px' />
          <Table>
            <caption>
              {i18next.t(
                'configurator:component.config_template.grip_sequential_config.non_opposed',
                'Non-opposed'
              )}
            </caption>
            <tr>
              <td>{transformedConfig?.gripSequentialConfig[6]}</td>
              <td>{transformedConfig?.gripSequentialConfig[7]}</td>
              <td>{transformedConfig?.gripSequentialConfig[8]}</td>
              <td>{transformedConfig?.gripSequentialConfig[9]}</td>
              <td>{transformedConfig?.gripSequentialConfig[10]}</td>
            </tr>
          </Table>
        </Card>
      </DividerWrapper>
    </Accordion>
  ),
  inputSite: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(configSettingsNamesMap.get('inputSite') || '')}
        items={[
          {
            header: i18next.t('configurator:component.config_template.input_site.value', 'Value'),
            content: `${i18next.t(inputSiteMap.get(config.inputSite[0]))}`
          }
        ]}
      />
    </li>
  ),
  inputDevice: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(configSettingsNamesMap.get('inputDevice') || '')}
        items={[
          {
            header: i18next.t('configurator:component.config_template.input_device.value', 'Value'),
            content: `${i18next.t(inputDeviceMap.get(config.inputDevice[0]))}`
          }
        ]}
      />
    </li>
  ),
  speedControlStrategy: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(
          'configurator:component.config_template.speed_control_strategy.header',
          'Speed control strategy'
        )}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.speed_control_strategy.value',
              'Value'
            ),
            content: `${i18next.t(speedControlMap.get(config.speedControlStrategy[0]))}`
          }
        ]}
      />
    </li>
  ),
  gripSwitchingMode: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(
          'configurator:component.config_template.grip_switching_mode.header',
          'Grip switching modes'
        )}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.grip_switching_mode.value',
              'Value'
            ),
            content: `${i18next.t(gripSwitchingMap.get(config.gripSwitchingMode[0]))}`
          }
        ]}
      />
    </li>
  ),
  controlMode: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(
          'configurator:component.config_template.control_mode.header',
          'Control mode'
        )}
        items={[
          {
            header: i18next.t('configurator:component.config_template.control_mode.value', 'Value'),
            content: `${i18next.t(controlModeMap.get(config.controlMode[0]))}`
          }
        ]}
      />
    </li>
  ),
  softGrip: (config) => (
    <li>
      <ConfigItem
        header={i18next.t('configurator:component.config_template.soft_grip.header', 'Soft-grip')}
        items={[
          {
            header: i18next.t('configurator:component.config_template.soft_grip.value', 'Status'),
            content: i18next.t(genericSwitchingMap.get(config.softGrip[0])),
            type: TypesConfig.bool,
            value: config?.softGrip?.[0]
          }
        ]}
      />
    </li>
  ),
  fingerStrength: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(
          'configurator:component.config_template.finger_strength.header',
          'Finger strength'
        )}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.finger_strength.value',
              'Value'
            ),
            content: `${config?.fingerStrength?.[1]}`
          }
        ]}
      />
    </li>
  ),
  autoGrasp: (config) => (
    <li>
      <ConfigItem
        header={i18next.t('configurator:component.config_template.auto_grasp.header', 'Auto grasp')}
        items={[
          {
            header: i18next.t('configurator:component.config_template.auto_grasp.status', 'Status'),
            content: `${i18next.t(genericSwitchingMap.get(config.autoGrasp[0]))}`,
            type: TypesConfig.bool,
            value: config?.autoGrasp?.[0]
          },
          {
            header: i18next.t('configurator:component.config_template.auto_grasp.value', 'Value'),
            content: `${config.autoGrasp[1]}`
          }
        ]}
      />
    </li>
  ),
  holdOpen: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(
          'configurator:component.config_template.hold_open.header',
          'Hold open timings'
        )}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.hold_open.primary',
              'Primary hold open time'
            ),
            content: `${config.holdOpen[0]} ms`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.hold_open.secondary',
              'Secondary hold open time'
            ),
            content: `${config.holdOpen[1]} ms`
          }
        ]}
      />
    </li>
  ),
  pulseTimings: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(
          'configurator:component.config_template.pulse_timings.header',
          'Pulse timings'
        )}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.pulse_timings.min_pulse_time',
              'Min. pulse time'
            ),
            content: `${config.pulseTimings[0]} ms`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.pulse_timings.max_pulse_time',
              'Max. pulse time'
            ),
            content: `${config.pulseTimings[1]} ms`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.pulse_timings.min_time_between_pulses',
              'Min. time between pulses'
            ),
            content: `${config.pulseTimings[2]} ms`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.pulse_timings.max_time_between_pulses',
              'Max. time between pulses'
            ),
            content: `${config.pulseTimings[3]} ms`
          }
        ]}
      />
    </li>
  ),
  coContractionTimings: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(
          'configurator:component.config_template.co_contraction_timings.header',
          'Co-contraction timings'
        )}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.co_contraction_timings.long_co_contraction_time',
              'Long co-contraction time'
            ),
            content: `${config?.coContractionTimings[0]} ms`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.co_contraction_timings.signal_rise_offset_time',
              'Signal rise offset time'
            ),
            content: `${config?.coContractionTimings[1]} ms`
          }
        ]}
      />
    </li>
  ),
  batteryBeep: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(
          'configurator:component.config_template.battery_beep.header',
          'Low battery beep'
        )}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.battery_beep.alarm_trigger_level',
              'Alarm trigger level'
            ),
            content: `${aetherBatteryVoltageFunctions.voltageToPercent(config?.batteryBeep[0])} %`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.battery_beep.status',
              'Status'
            ),
            content: `${i18next.t(genericSwitchingMap.get(config.batteryBeep[1]))}`,
            type: TypesConfig.bool,
            value: config?.batteryBeep?.[1]
          }
        ]}
      />
    </li>
  ),
  freezeModeEmg: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(
          'configurator:component.config_template.freeze_mode_emg.header',
          'Freeze mode EMG'
        )}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.freeze_mode_emg.status',
              'Status'
            ),
            content: `${i18next.t(freezeModeMap.get(config?.freezeModeEmg[2]) || '')}`,
            type: TypesConfig.bool,
            value: config?.freezeModeEmg?.[2]
          },
          {
            header: i18next.t(
              'configurator:component.config_template.freeze_mode_emg.threshold_closing',
              'Threshold closing'
            ),
            content: `${config?.freezeModeEmg[0]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.freeze_mode_emg.threshold_opening',
              'Threshold opening'
            ),
            content: `${config?.freezeModeEmg[1]}`
          }
        ]}
      />
    </li>
  ),
  generalHandSettings: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(
          'configurator:component.config_template.general_hand_settings.header',
          'Wait for CS before startup'
        )}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.general_hand_settings.status',
              'Status'
            ),
            content: `${i18next.t(startOnStartupMap.get(config?.generalHandSettings[2]))}`,
            type: TypesConfig.bool,
            value: !config?.generalHandSettings?.[2]
          }
        ]}
      />
    </li>
  ),
  emgSpike: (config) => (
    <li>
      <ConfigItem
        header={i18next.t('configurator:component.config_template.emg_spike.header', 'EMG spike')}
        items={[
          {
            header: i18next.t('configurator:component.config_template.emg_spike.status', 'Status'),
            content: `${i18next.t(genericSwitchingMap.get(config.emgSpike[0]))}`,
            type: TypesConfig.bool,
            value: config?.emgSpike?.[0]
          },
          {
            header: i18next.t('configurator:component.config_template.emg_spike.value', 'Value'),
            content: `${config.emgSpike[1]} ms`
          }
        ]}
      />
    </li>
  ),
  emgGains: (config) => (
    <li>
      <ConfigItem
        header={i18next.t('configurator:component.config_template.emg_gains.header', 'EMG gains')}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.emg_gains.extension',
              'Extension'
            ),
            content: `${config.emgGains[0]}%`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.emg_gains.flexion',
              'Flexion'
            ),
            content: `${config.emgGains[1]}%`
          }
        ]}
      />
    </li>
  ),
  emgThresholds: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(
          'configurator:component.config_template.emg_thresholds.header',
          'EMG thresholds'
        )}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.emg_thresholds.cs_extension_thresholds',
              'CS extension thresholds'
            ),
            content: `${config.emgThresholds[0]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.emg_thresholds.cs_flexion_thresholds',
              'CS flexion thresholds'
            ),
            content: `${config.emgThresholds[1]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.emg_thresholds.threshold_control_open_value',
              'Threshold control open value'
            ),
            content: `${config.emgThresholds[2]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.emg_thresholds.proportional_control_open_second_value',
              'Proportional control open second value'
            ),
            content: `${config.emgThresholds[3]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.emg_thresholds.proportional_control_open_third_value',
              'Proportional control open third value'
            ),
            content: `${config.emgThresholds[4]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.emg_thresholds.threshold_control_close_value',
              'Threshold control close value'
            ),
            content: `${config.emgThresholds[5]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.emg_thresholds.proportional_control_close_second_value',
              'Proportional control close second value'
            ),
            content: `${config.emgThresholds[6]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.emg_thresholds.proportional_control_close_third_value',
              'Proportional control close third value'
            ),
            content: `${config.emgThresholds[7]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.emg_thresholds.soft_grip_open_threshold_second_value',
              'Soft-grip open threshold second value'
            ),
            content: `${config.emgThresholds[8]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.emg_thresholds.soft_grip_close_threshold_second_value',
              'Soft-grip close threshold second value'
            ),
            content: `${config.emgThresholds[9]}`
          }
        ]}
      />
    </li>
  ),
  buzzingVolumeSettings: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(configSettingsNamesMap.get('buzzingVolumeSettings') || '')}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.volume_settings.volume_header',
              'Volume'
            ),
            content: `${Math.round((config.buzzingVolumeSettings[0] / 255) * 100)} %`
          }
        ]}
      />
    </li>
  ),
  followingGrip: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(configSettingsNamesMap.get('followingGrip') || '')}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.following_grip.status',
              'Status'
            ),
            content: `${i18next.t(genericSwitchingMap.get(config.followingGrip[0]))}`,
            type: TypesConfig.bool,
            value: config?.followingGrip?.[0]
          }
        ]}
      />
    </li>
  ),
  freezeModeEmgSettings: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(configSettingsNamesMap.get('freezeModeEmgSettings') || '')}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.freeze_mode_emg_settings.threshold_opening',
              'Threshold opening'
            ),
            content: `${config.freezeModeEmgSettings[0]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.freeze_mode_emg_settings.threshold_closing',
              'Threshold closing'
            ),
            content: `${config.freezeModeEmgSettings[1]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.freeze_mode_emg_settings.stage_1_time',
              'Stage 1 time'
            ),
            content: `${config.freezeModeEmgSettings[2]} ms`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.freeze_mode_emg_settings.stage_2_time',
              'Stage 2 time'
            ),
            content: `${config.freezeModeEmgSettings[3]} ms`
          }
        ]}
      />
    </li>
  ),
  singleElectrodeMode: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(configSettingsNamesMap.get('singleElectrodeMode') || '')}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.single_electrode_mode.mode',
              'Mode'
            ),
            content: `${i18next.t(singleElectrodeModeMap.get(config.singleElectrodeMode[0]))}`
          }
        ]}
      />
    </li>
  ),
  singleElectrodeModeSettings: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(configSettingsNamesMap.get('singleElectrodeModeSettings') || '')}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.single_electrode_mode_settings.start_point_signal_thresholds',
              'Start point signal thresholds'
            ),
            content: `${config.singleElectrodeModeSettings[0]}`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.single_electrode_mode_settings.window_time',
              'Window time'
            ),
            content: `${config.singleElectrodeModeSettings[1]} ms`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.single_electrode_mode_settings.primary_hold_open_time',
              'Primary hold open time'
            ),
            content: `${config.singleElectrodeModeSettings[2]} ms`
          },
          {
            header: i18next.t(
              'configurator:component.config_template.single_electrode_mode_settings.secondary_hold_open_time',
              'Secondary hold open time'
            ),
            content: `${config.singleElectrodeModeSettings[3]} ms`
          }
        ]}
      />
    </li>
  ),
  userFeedbackType: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(configSettingsNamesMap.get('userFeedbackType') || '')}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.user_feedback_type.buzzer',
              'Buzzer'
            ),
            content: `${i18next.t(allUserFeedbackTypeMap.get(config.userFeedbackType[0]) || '')}`
          }
        ]}
      />
    </li>
  ),
  emergencyBatterySettings: (config: DeviceConfigTemplate) => (
    <li>
      <ConfigItem
        header={i18next.t(configSettingsNamesMap.get('emergencyBatterySettings') || '')}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.emergency_battery_settings.status',
              'Status'
            ),
            content: `${i18next.t(genericSwitchingMap.get(config.emergencyBatterySettings[0]))}`,
            type: TypesConfig.bool,
            value: config?.emergencyBatterySettings?.[0]
          },
          {
            header: i18next.t(
              'configurator:component.config_template.emergency_battery_settings.mode_trigger_level',
              'Mode trigger level'
            ),
            content: `${aetherBatteryVoltageFunctions.voltageToPercent(
              config.emergencyBatterySettings[2]
            )} %`
          }
        ]}
      />
    </li>
  ),
  oneSpeed: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(
          'configurator:component.config_template.one_speed.header',
          'One speed settings'
        )}
        items={[
          {
            header: i18next.t(
              'configurator:component.config_template.one_speed.fingers_speed',
              'Fingers speed'
            ),
            content: `${config.oneSpeed[0]}%`
          }
        ]}
      />
    </li>
  ),
  singleElectrodeSettingsAlternating: (config) => (
    <li>
      <ConfigItem
        header={i18next.t(configSettingsNamesMap.get('singleElectrodeSettingsAlternating') || '')}
        items={[
          {
            header: i18next.t(
              singleElectrodeSettingsAlternatingNamingsMap.get(
                singleElectrodeSettingsAlternatingPositions.stateSwitchDelay
              )
            ),
            content: `${
              config.singleElectrodeSettingsAlternating[
                singleElectrodeSettingsAlternatingPositions.stateSwitchDelay
              ]
            } ms`
          },
          {
            header: i18next.t(
              singleElectrodeSettingsAlternatingNamingsMap.get(
                singleElectrodeSettingsAlternatingPositions.minimumPulseTime
              )
            ),
            content: `${
              config.singleElectrodeSettingsAlternating[
                singleElectrodeSettingsAlternatingPositions.minimumPulseTime
              ]
            } ms`
          },
          {
            header: i18next.t(
              singleElectrodeSettingsAlternatingNamingsMap.get(
                singleElectrodeSettingsAlternatingPositions.maximumPulseTime
              )
            ),
            content: `${
              config.singleElectrodeSettingsAlternating[
                singleElectrodeSettingsAlternatingPositions.maximumPulseTime
              ]
            } ms`
          },
          {
            header: i18next.t(
              singleElectrodeSettingsAlternatingNamingsMap.get(
                singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
              )
            ),
            content: `${
              config.singleElectrodeSettingsAlternating[
                singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
              ]
            } ms`
          },
          {
            header: i18next.t(
              singleElectrodeSettingsAlternatingNamingsMap.get(
                singleElectrodeSettingsAlternatingPositions.maximumTimeBetweenPulses
              )
            ),
            content: `${
              config.singleElectrodeSettingsAlternating[
                singleElectrodeSettingsAlternatingPositions.maximumTimeBetweenPulses
              ]
            } ms`
          }
        ]}
      />
    </li>
  )
};

const prosthesisSettings: Array<keyof DeviceConfigTemplate> = [
  'inputSite',
  'inputDevice',
  'speedControlStrategy',
  'gripSwitchingMode',
  'controlMode',
  'singleElectrodeMode',
  'singleElectrodeModeSettings',
  'softGrip',
  'fingerStrength',
  'autoGrasp',
  'holdOpen',
  'pulseTimings',
  'coContractionTimings',
  'batteryBeep',
  'buzzingVolumeSettings',
  'followingGrip',
  'freezeModeEmg',
  'freezeModeEmgSettings',
  'generalHandSettings',
  'userFeedbackType',
  'emergencyBatterySettings',
  'oneSpeed',
  'singleElectrodeSettingsAlternating'
];
const emgSettings: Array<keyof DeviceConfigTemplate> = ['emgSpike', 'emgGains', 'emgThresholds'];

const ConfigComponent = ({
  config,
  transformedConfig,
  currentToggle = ToggleTypesChangesHistory.all
}: ConfigComponentsProps) => {
  const [selectedGrip, setSelectedGrip] = useState(gripsGroupsOptionsMap.get(Grips.kGripRestOpp));
  const { commonKeys, modeKeys } = useConfigStore((state) => ({
    commonKeys: state.commonPropertiesAPI,
    modeKeys: state.modePropertiesAPI
  }));
  const configKeys = commonKeys && modeKeys ? [...commonKeys, ...modeKeys] : null;

  const showProsthesis =
    currentToggle === ToggleTypesChangesHistory.all ||
    currentToggle === ToggleTypesChangesHistory.prosthesis;

  const showGrips =
    currentToggle === ToggleTypesChangesHistory.all ||
    currentToggle === ToggleTypesChangesHistory.grips;

  const showEMG =
    currentToggle === ToggleTypesChangesHistory.all ||
    currentToggle === ToggleTypesChangesHistory.emg;

  if (!config) return <div>Config is not initialized</div>;

  const gripNumber: any = gripsGroupsOptionsReversedMap.get(selectedGrip);
  const filterKeys = (setting: keyof DeviceConfigTemplate) =>
    configKeys ? configKeys.includes(setting) : true;
  const showSetting = (setting: keyof DeviceConfigTemplate) =>
    Boolean(config?.[`${setting}`]) && ConfigDetailsMap[`${setting}`](config);

  console.log(selectedGrip, gripNumber, 'CONFIG');

  return (
    <ConfigWrapper>
      {showGrips && (
        <>
          {config?.gripsPositions &&
            ConfigDetailsMap.gripsPositions(config)(
              transformedConfig,
              selectedGrip,
              setSelectedGrip,
              gripNumber
            )}
          {transformedConfig?.gripPairsConfig &&
            ConfigDetailsMap.gripPairsConfig(transformedConfig)}
          {transformedConfig?.gripSequentialConfig &&
            ConfigDetailsMap.gripSequentialConfig(transformedConfig)}
        </>
      )}
      <ConfigList>
        {showProsthesis && (
          <>
            {prosthesisSettings.filter(filterKeys).map((setting) => {
              if (setting === 'singleElectrodeMode') {
                return (
                  config?.singleElectrodeMode &&
                  config?.inputSite &&
                  config.inputSite[0] === InputSites.kSingleElectrode &&
                  ConfigDetailsMap.singleElectrodeMode(config)
                );
              }

              if (setting === 'singleElectrodeModeSettings') {
                return (
                  config?.singleElectrodeModeSettings &&
                  config.inputSite &&
                  config.inputSite[0] === InputSites.kSingleElectrode &&
                  ConfigDetailsMap.singleElectrodeModeSettings(config)
                );
              }

              return showSetting(setting);
            })}
          </>
        )}
        {showEMG && <>{emgSettings.filter(filterKeys).map(showSetting)}</>}
      </ConfigList>
    </ConfigWrapper>
  );
};

export default ConfigComponent;
