import React, { useEffect, useRef, useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useGraph } from 'configurator/hooks/useGraph';
import { emgColors } from 'configurator/theme/emgColors/emgColors';
import { getCurrentConfigSelector } from 'configurator/reducers/helpers/selectors';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import {
  ChartCombined,
  ChartCombinedData,
  ChartProportional,
  ChartProportionalData
} from './Charts';
import { parseTelemetryData, updateAllGraphs } from './utils';
import { SpeedControlStrategies } from 'bluetooth/bluetoothCommunication/Control';

const chartOptions = [
  { id: 'One speed', name: 'One speed' },
  { id: 'Proportional', name: 'Proportional' }
];

const StandaloneChart = ({ ...props }) => {
  const graphContainer = useRef(null);
  const graphContainerSeparate1 = useRef(null);
  const graphContainerSeparate2 = useRef(null);
  const graphContainerProportional = useRef(null);
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const { emgThresholds, speedControlStrategy } = useConfigStore(getCurrentConfigSelector);
  const { enabled: liveConfiguratorEnabled, channel } = useLiveConfiguratorStore((state) => ({
    enabled: state.enabled,
    channel: state.channel
  }));
  const [graphMode, setGraphMode] = useState(
    speedControlStrategy?.[0] === SpeedControlStrategies.kThreshold
      ? chartOptions[0].id
      : chartOptions[1].id
  );

  const { updateData: updateDataCombined } = useGraph(
    graphContainer,
    {
      time: 500,
      interval: 400,
      thresholds: [
        {
          values: emgThresholds?.[0],
          label: 'CS Open',
          color: emgColors.csOpen
        },
        {
          values: emgThresholds?.[1],
          label: 'CS Close',
          color: emgColors.csClose
        },
        {
          values: emgThresholds?.[2],
          label: 'SCS Open',
          color: emgColors.activationOpen
        },
        {
          values: emgThresholds?.[5],
          label: 'SCS Close',
          color: emgColors.activationClose
        }
      ],
      showThreshold: false
    },
    500,
    [graphMode, emgThresholds],
    [graphMode]
  );
  const { updateData: updateDataSeparate1 } = useGraph(
    graphContainerSeparate1,
    {
      time: 500,
      interval: 400,
      thresholds: [
        {
          values: emgThresholds?.[0],
          label: 'CS Open',
          color: emgColors.csOpen
        },
        {
          values: emgThresholds?.[2],
          label: 'SCS Open',
          color: emgColors.activationOpen
        }
      ],
      showThreshold: false
    },
    500,
    [graphMode, emgThresholds],
    [graphMode]
  );
  const { updateData: updateDataSeparate2 } = useGraph(
    graphContainerSeparate2,
    {
      time: 500,
      interval: 400,
      thresholds: [
        {
          values: emgThresholds?.[1],
          label: 'CS Close',
          color: emgColors.csClose
        },
        {
          values: emgThresholds?.[5],
          label: 'SCS Close',
          color: emgColors.activationClose
        }
      ],
      showThreshold: false
    },
    500,
    [graphMode, emgThresholds],
    [graphMode]
  );
  const { updateData: updateDataProportional } = useGraph(
    graphContainerProportional,
    {
      time: 500,
      interval: 400,
      thresholds: [
        {
          values: emgThresholds?.[9],
          label: 'Power Closing',
          color: emgColors.powerClose
        },
        {
          values: emgThresholds?.[4],
          label: 'Speed 3 Opening',
          color: emgColors.speed3Open
        },
        {
          values: emgThresholds?.[7],
          label: 'Speed 3 Closing',
          color: emgColors.speed3Close
        },
        {
          values: emgThresholds?.[3],
          label: 'Speed 2 Opening',
          color: emgColors.speed2Open
        },
        {
          values: emgThresholds?.[6],
          label: 'Speed 2 Closing',
          color: emgColors.speed2Close
        },
        {
          values: emgThresholds?.[2],
          label: 'Speed 1 Opening',
          color: emgColors.activationOpen
        },
        {
          values: emgThresholds?.[5],
          label: 'Speed 1 Closing',
          color: emgColors.activationClose
        },
        {
          values: emgThresholds?.[1],
          label: 'SCS Closing',
          color: emgColors.csClose
        },
        {
          values: emgThresholds?.[0],
          label: 'SCS Opening',
          color: emgColors.csOpen
        }
      ]
    },
    500,
    [graphMode, emgThresholds],
    [graphMode]
  );

  const updateGraphs = () => {
    const emgData = parseTelemetryData();
    if (!emgData) return;
    const { emgExtensionOptions, emgFlexionOptions } = emgData;
    updateAllGraphs([
      {
        container: graphContainer,
        updateFunc: () => updateDataCombined([emgExtensionOptions, emgFlexionOptions])
      },
      {
        container: graphContainerSeparate2,
        updateFunc: () => updateDataSeparate1([emgExtensionOptions])
      },
      {
        container: graphContainerSeparate2,
        updateFunc: () => updateDataSeparate2([emgFlexionOptions])
      },
      {
        container: graphContainerProportional,
        updateFunc: () => updateDataProportional([emgExtensionOptions, emgFlexionOptions])
      }
    ]);
  };

  useEffect(() => {
    let graphInterval;
    if (deviceConnected) {
      graphInterval = setInterval(updateGraphs, 50);
    }

    if (liveConfiguratorEnabled) {
      console.log('liveConfiguratorEnable', liveConfiguratorEnabled);
      console.log('channel', channel);
      graphInterval = setInterval(updateGraphs, 50);
    }

    return function clear() {
      if (graphInterval) {
        clearInterval(graphInterval);
      }
    };
  }, [deviceConnected, liveConfiguratorEnabled]);

  return (
    <div
      {...props}
      style={{
        ...props.style,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
      {graphMode === chartOptions[0].id && <ChartCombinedData graphContainer={graphContainer} />}
      {graphMode === chartOptions[1].id && (
        <ChartProportionalData graphContainer={graphContainerProportional} />
      )}
      <TextField
        select
        id='select-graph-mode'
        SelectProps={{
          onChange: (e: any) => setGraphMode(e.target.value),
          value: graphMode
        }}
        size='small'>
        {chartOptions.map((option) => (
          <MenuItem key={`select-graph-mode_${option.id}`} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default StandaloneChart;
