import * as yup from 'yup';

export const emailSchema = yup
  .string()
  .trim()
  .email('Must be a valid email')
  .matches(/@[^.]*\./, 'Must be a valid email');

export const stringSchema = yup.string().trim();

export const isStrongPassword = (value) => {
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasNumber = /[0-9]/.test(value);
  // eslint-disable-next-line no-useless-escape
  const hasSymbole = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
  const isProperLength = value.length >= 8;
  let validConditions = 0;
  const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole, isProperLength];
  // eslint-disable-next-line no-return-assign
  conditions.forEach((condition) => (condition ? (validConditions += 1) : null));
  if (validConditions >= conditions.length) {
    return true;
  }
  return false;
};
