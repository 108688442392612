/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import TelemetryController from '../../bluetooth/telemetryController';

const noop = () => {};

export function useInterval(
  callback: () => void,
  delay: number | null | false,
  immediate?: boolean
) {
  const [currentDelay, setDelay] = useState(delay);
  const savedCallback = useRef(noop);

  const toggleRunning = useCallback(
    () => setDelay((currentDelay) => (currentDelay === null ? delay : null)),
    [delay]
  );

  useEffect(() => {
    if (!immediate) {
      setDelay(null);
    }
  }, []);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Execute callback if immediate is set.
  useEffect(() => {
    if (!immediate) return;
    if (currentDelay === null || currentDelay === false) return;
    savedCallback.current();
  }, [immediate]);

  // Set up the interval.
  useEffect(() => {
    if (currentDelay === null || currentDelay === false) return undefined;
    const tick = () => savedCallback.current();
    const id = setInterval(tick, currentDelay);
    return () => clearInterval(id);
  }, [currentDelay]);

  return [toggleRunning, !!currentDelay];
}

export const useReplay = (
  replayData: { time: number; telemetry: number[] }[] = [],
  replayIsEnabled = false
) => {
  const [lastIndex, setLastIndex] = useState(0);

  const [toggle, active] = useInterval(
    () => {
      if (replayData.length === 0) return;
      if (lastIndex === replayData.length) {
        // @ts-ignore
        toggle();
        return;
      }
      const payload = replayData.slice(0, lastIndex).map((item) => ({ payload: item.telemetry }));
      const event = new CustomEvent('replay', {
        detail: { reset: true, data: payload }
      });
      window.dispatchEvent(event);
      setLastIndex((prev) => prev + 1);
    },
    50,
    false
  );

  const play = () => {
    // @ts-ignore
    toggle();
  };

  const pause = () => {
    // @ts-ignore
    toggle();
  };

  const handleSlider = (valueChanged: any) => {
    setLastIndex(valueChanged);
    const event = new CustomEvent('replay', {
      detail: {
        data: replayData.slice(0, valueChanged).map((item) => ({ payload: item.telemetry })),
        reset: true
      }
    });

    window.dispatchEvent(event);
  };

  useEffect(() => {
    TelemetryController.abortReplay();

    if (replayIsEnabled) {
      TelemetryController.enableReplay();
    }
    return () => {
      TelemetryController.abortReplay();
    };
  }, []);

  return {
    play,
    pause,
    isPlay: active,
    index: lastIndex,
    handleSlider
  };
};
