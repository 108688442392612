import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Card from 'adp-panel/components/Card/Card';
import CustomTable, { ActionsWrapper } from 'adp-panel/components/Table/CustomTable';
import CustomButton from 'components/Button/CustomButton';
import { ReactComponent as TrashSvg } from 'assets/trash-icon.svg';
import { ReactComponent as HandSvg } from 'assets/hand-device-x.svg';
import { ReactComponent as HandAddSvg } from 'assets/hand-device-add.svg';
import { RoleEnum } from '../../../api/users/users.types';
import { userHasPermissions } from '../../../utils/permissionUtils';
import { DeviceTableRow, RolesByName } from '../types/patient.types';
import { NoDevicesContainer, NoDevicesContent } from '../styled/PatientDevices.styled';
import { useModal } from 'configurator/hooks/useModal';
import ConfirmDeleteModal from 'adp-panel/components/Modals/ConfirmDeleteModal';

interface ExtendedDeviceEntry extends DeviceEntry {
  dumps?: {
    manual: string | null;
    background: string | null;
    fetching: string | null;
  };
}

interface PatientDevicesProps {
  devices: DeviceEntry[];
  totalDevices: number;
  isLoadingDevices: boolean;
  rolesByName: RolesByName;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  setPaginationModel: (model: { page: number; pageSize: number }) => void;
  handleUnassignDevice: (deviceId: number) => void;
  handleAssignDeviceModalOpen: () => void;
}

const mapDeviceRows = (apiData: ExtendedDeviceEntry[]): DeviceTableRow[] =>
  apiData.map((device) => ({
    id: device.id,
    serial: device.serial || '',
    model: device.model?.name || '',
    mobileStatus: true,
    lastDumUpdate: {
      manual: device.dumps?.manual || null,
      background: device.dumps?.background || null,
      fetching: device.dumps?.fetching || null
    }
  }));

export const PatientDevices: React.FC<PatientDevicesProps> = ({
  devices,
  totalDevices,
  isLoadingDevices,
  rolesByName,
  paginationModel,
  setPaginationModel,
  handleUnassignDevice,
  handleAssignDeviceModalOpen
}) => {
  const { t } = useTranslation('patients');
  const deviceExists = devices?.[0]?.serial;
  const isClinician = userHasPermissions([RoleEnum.clinician, RoleEnum.clinicAdmin], rolesByName);
  const [selectedDevice, setSelectedDevice] = useState<any>(false);
  const formatDate = (date: any) => dayjs(date).tz(dayjs.tz.guess()).format('YYYY-MM-DD, HH:mm');

  const {
    isOpen: isDetachModalOpen,
    handleOpen: handleDeleteModalOpen,
    handleClose: handleDeleteModalClose
  } = useModal();

  if (!deviceExists) {
    return (
      <Card style={{ marginTop: '32px' }}>
        <NoDevicesContainer>
          <NoDevicesContent>
            <Card>
              <HandSvg />
            </Card>
            <Typography sx={{ fontWeight: 600 }}>
              {t('patients:details.no_devices', 'No devices')}
            </Typography>
            {isClinician && (
              <>
                <Typography variant='caption' sx={{ width: '250px' }}>
                  {t(
                    'patients:details.no_devices_description',
                    'Click the button below to add first device to the patient.'
                  )}
                </Typography>
                <CustomButton Icon={HandAddSvg} onClick={handleAssignDeviceModalOpen}>
                  {t('patients:details.assign_device', 'Assign device')}
                </CustomButton>
              </>
            )}
          </NoDevicesContent>
        </NoDevicesContainer>
      </Card>
    );
  }

  const handleDetachDevice = (id: number) => {
    setSelectedDevice(id);
    handleDeleteModalOpen();
  };

  const unassignDevice = async () => {
    if (selectedDevice) {
      await handleUnassignDevice(selectedDevice);
      handleDeleteModalClose();
    }
  };

  return (
    <div style={{ marginTop: '32px' }}>
      {isDetachModalOpen && (
        <ConfirmDeleteModal
          handleClose={handleDeleteModalClose}
          handleAccept={() => unassignDevice()}
          isLoading={isLoadingDevices}
          title={t('patients:details.detach_device_title', 'Remove device from patient')}
          message={t(
            'patients:details.detach_device_text',
            "We don't delete the device, patient just won't be attached to it anymore. You can still find this device in Devices tab."
          )}
        />
      )}
      <CustomTable
        tableData={devices}
        totalItems={totalDevices}
        isLoading={isLoadingDevices}
        autoHeight
        getRowHeight={() => 'auto'}
        columns={[
          {
            field: 'serial',
            headerName: t('patients:details.table.serial_number', 'Serial Number'),
            flex: 1
          },
          {
            field: 'model',
            headerName: t('patients:details.table.model', 'Model'),
            flex: 1,
            sortable: false
          },
          {
            field: 'lastDumUpdate',
            headerName: t(
              'patients:details.table.last_update',
              'Date of last update of device usage data'
            ),
            flex: 2,
            sortable: false,
            renderCell: (params) => {
              return (
                <ul
                  style={{
                    margin: '5px 0',
                    height: '100%',
                    listStyle: 'none'
                  }}>
                  {params.value.manual && (
                    <li>
                      <span style={{ fontWeight: 600 }}>
                        {t('patients:details.manual', 'Manual')}
                      </span>
                      : {formatDate(params.value.manual)}
                    </li>
                  )}
                  {params.value.background && (
                    <li>
                      <span style={{ fontWeight: 600 }}>
                        {t('patients:details.background', 'Background')}
                      </span>
                      : {formatDate(params.value.background)}
                    </li>
                  )}
                  {params.value.fetching && (
                    <li>
                      <span style={{ fontWeight: 600 }}>
                        {t('patients:details.fetching', 'Fetching')}
                      </span>
                      : {formatDate(params.value.fetching)}
                    </li>
                  )}
                </ul>
              );
            }
          },
          {
            field: 'actions',
            flex: 1,
            headerName: '',
            renderCell: (params) => {
              const { id } = params.row;
              const canAssignDevice = userHasPermissions(
                [RoleEnum.clinician, RoleEnum.clinicAdmin],
                rolesByName
              );

              if (!canAssignDevice) return null;

              return (
                <ActionsWrapper>
                  <CustomButton
                    Icon={TrashSvg}
                    color='light'
                    data-testid='button-delete'
                    onClick={() => handleDetachDevice(id)}
                  />
                </ActionsWrapper>
              );
            }
          }
        ]}
        columnVisibilityModel={{
          active_code: userHasPermissions([RoleEnum.superAdmin], rolesByName)
        }}
        mapTableData={mapDeviceRows}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </div>
  );
};
