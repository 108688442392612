/* eslint-disable react/jsx-key */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MenuItem, TextField, Tooltip, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { GripSwitchingModes, InputSites } from 'bluetooth/bluetoothCommunication/Control';
import {
  DeviceConfigTemplate,
  CommonConfigTemplate
} from 'configurator/consts/deviceConfig/deviceConfig.types';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { getCurrentConfigApiSelector } from 'configurator/reducers/helpers/selectors';
import {
  configSettingsNamesMap,
  gripSwitchingMap,
  inputSiteMap
} from 'configurator/utils/definesLocal';
import { useConfigStore } from 'configurator/reducers/configStore';
import { transformCompared } from 'configurator/utils/Config/transformConfig';
import { ChangesWrapper } from './RestoreConfigHistoryModal';
import { TableBody, TableHeader } from 'configurator/views/DeviceConfig/DeviceConfig/styled';
import { ConfigHistoryItem } from 'configurator/components/organisms/Config/ConfigHistoryItem';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const ParagraphBold = styled.p`
  ${TextS};
  margin-top: 30px;
  margin-bottom: 24px;
`;

type ConfirmAbortModeProps = {
  handleAccept: Function;
  handleClose: any;
  header: string;
  entry: DeviceConfigTemplate;
  isLoading: boolean;
};

const isPossibleConfigIssueAfterImport = (
  currentConfig: CommonConfigTemplate,
  importedConfig: DeviceConfigTemplate
) => {
  const importedInputSite = importedConfig?.inputSite;

  if (importedInputSite) return;

  const currentInputSiteValue = currentConfig?.inputSite?.[0];
  // @ts-ignore
  const importedGripSwitchingModeValue = importedConfig?.gripSwitchingMode[0];

  const wrongGripSwitchingMode =
    currentInputSiteValue === InputSites.kSingleElectrode &&
    importedGripSwitchingModeValue !== GripSwitchingModes.kSingleGripSwitching;

  const wrongInputSite =
    currentInputSiteValue !== InputSites.kSingleElectrode &&
    importedGripSwitchingModeValue === GripSwitchingModes.kSingleGripSwitching;

  return wrongGripSwitchingMode || wrongInputSite;
};

const ImportTemplateModal = ({
  handleAccept,
  handleClose,
  header,
  entry,
  isLoading
}: ConfirmAbortModeProps) => {
  const { t } = useTranslation();
  const [selectedModeSlot, setSelectedModeSlot] = useState(0);
  const configStoreState = useConfigStore();
  const { common, modes } = configStoreState.config;
  const selectedModeConfig = getCurrentConfigApiSelector(configStoreState, selectedModeSlot);

  const configNow = {
    ...common.configAPI,
    ...modes.find((mode: any) => mode.slot === selectedModeSlot)?.configAPI
  } as DeviceConfigTemplate;
  const compared = transformCompared(configNow, entry);

  return (
    <ModalWrapper title={header}>
      {modes && (
        <TextField
          fullWidth
          id='selected-mode'
          label={t('configurator:component.import_template_modal.selected_mode', 'Selected mode')}
          select
          SelectProps={{
            value: selectedModeSlot,
            onChange: (e: any) => setSelectedModeSlot(e.target.value)
          }}>
          {modes.map((mode: any) => (
            <MenuItem key={`selected-mode_${mode.name}`} value={mode.slot}>
              {mode.name}
            </MenuItem>
          ))}
        </TextField>
      )}
      <ParagraphBold>
        {t(
          'configurator:component.import_template_modal.do_you_want_to_import_and_send',
          'Do you want to import this template and send it to patient?'
        )}
      </ParagraphBold>
      {isPossibleConfigIssueAfterImport(configNow, entry) && (
        <Alert severity='warning' style={{ marginBottom: '24px' }}>
          {t('configurator:component.import_template_modal.conflict_warning', {
            gripSwitchingModeName: configSettingsNamesMap.get('gripSwitchingMode'),
            gripSwitchingValue: gripSwitchingMap.get(entry.gripSwitchingMode[0]),
            inputSiteName: configSettingsNamesMap.get('inputSite'),
            inputSiteValue: inputSiteMap.get(selectedModeConfig?.inputSite?.[0]),
            defaultValue:
              'You are trying to import {{gripSwitchingModeName}}: {{gripSwitchingValue}} that conflicts with current {{inputSiteName}}: {{inputSiteValue}}.'
          })}
        </Alert>
      )}
      {compared?.length > 0 && (
        <ChangesWrapper>
          <TableBody sticky>
            <TableHeader>
              {t('configurator:component.import_template_modal.configuration', 'Configuration')}
            </TableHeader>
            <TableHeader>
              {t('configurator:component.restore_config_history_modal.being_sent', 'Being sent')}
            </TableHeader>
            <TableHeader>
              {t('configurator:component.import_template_modal.current', 'Current')}
            </TableHeader>
          </TableBody>
          {compared.map((entry: any) => (
            <ConfigHistoryItem
              name={entry.key}
              configName={entry.key}
              after={entry.value}
              now={configNow}
            />
          ))}
        </ChangesWrapper>
      )}
      <FormButtonsWrapper>
        <CustomButton onClick={handleClose} type='button' color='light'>
          {t('configurator:component.import_template_modal.cancel', 'Cancel')}
        </CustomButton>
        <Tooltip
          title={
            compared?.length !== 0
              ? ''
              : t(
                  'configurator:component.import_template_modal.no_changes_to_import',
                  'No changes to import'
                )
          }>
          <span>
            <LoadingButton
              type='button'
              onClick={() =>
                handleAccept(modes.find((mode: any) => mode.slot === selectedModeSlot)?.id)
              }
              loading={isLoading}
              disabled={compared?.length === 0}>
              <span>{t('configurator:component.import_template_modal.yes_send', 'Yes, send')}</span>
            </LoadingButton>
          </span>
        </Tooltip>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default ImportTemplateModal;
