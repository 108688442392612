/* eslint-disable no-bitwise */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */

import { Commands } from 'bluetooth/bluetoothCommunication/Defines';
import { delay } from 'bluetooth/bluetoothCommunication/Utilities';
import { HandMovementStates } from 'configurator/utils/definesLocal';
import BluetoothWebController from 'bluetooth/bluetoothWeb';
import TelemetryParser from 'configurator/utils/TelemetryParser';

const telemetryParser = new TelemetryParser();

type FingerStateType = {
  encoderTicks: number;
  pwm: number;
  velocity: number;
  current: number;
};

const TelemetryController: {
  gripInTransition: boolean;
  telemetryListening: boolean;
  telemetryReplayListening: boolean;
  telemetryData: any;
  eventListenerController: AbortController;
  eventListenerReplayController: AbortController;
  fingerStates: FingerStateType[] | null;
  prosthesisGrip: number | null;
  prosthesisVoltage: number | null;
  newestTelemetryData: any;
  oldTelemetry: boolean;
  handMovementState: HandMovementStates | null;
  parseTelemetry: (telemetryData: any) => void;
  parseReplayTelemetry: (telemetryData: any) => void;
  abortReplay: () => void;
  enableReplay: () => void;
  checkVoltage: () => Promise<number | null>;
  initiateTelemetry: () => void;
  abortTelemetry: () => void;
} = {
  gripInTransition: false,
  telemetryListening: false,
  telemetryReplayListening: false,
  telemetryData: <any>[],
  prosthesisGrip: null,
  prosthesisVoltage: null,
  newestTelemetryData: null,
  fingerStates: null,
  handMovementState: null,
  oldTelemetry: false,
  eventListenerController: new AbortController(),
  eventListenerReplayController: new AbortController(),
  parseTelemetry(telemetryData: any) {
    const [{ payload: telemetry }] = telemetryData.detail;

    this.oldTelemetry = telemetry?.length > 10;

    this.newestTelemetryData = telemetryData.detail;
    const newData = telemetryParser.parseTelemetry(telemetryData.detail, 500, this.oldTelemetry);

    if (this.oldTelemetry) {
      const fingers: FingerStateType[] = [];
      for (let index = 0; index < 5; index += 1) {
        const encoderTicks = telemetry[4 * index + 0];
        const pwm = telemetry[4 * index + 1];
        const velocity = telemetry[4 * index + 2];
        const current = telemetry[4 * index + 3];

        fingers.push({ encoderTicks, pwm, velocity, current });
      }
      this.fingerStates = fingers;

      for (let index = 0; index < 5; index += 1) {
        const fingerVelocity = this.fingerStates[index]?.velocity;

        if (fingerVelocity > 0) {
          this.handMovementState = HandMovementStates.closing;
          break;
        }
        if (fingerVelocity < 0) {
          this.handMovementState = HandMovementStates.opening;
          break;
        }
        if (index === 4) {
          this.handMovementState = HandMovementStates.idle;
        }
      }

      const binaryStates = telemetry[22];
      const newGripInTransition = ((binaryStates >> 3) & 1) === 1;
      this.gripInTransition = newGripInTransition;
    } else {
      if ((telemetry[5] & 0x03) === 0) this.handMovementState = HandMovementStates.idle;
      if ((telemetry[5] & 0x03) === 1) this.handMovementState = HandMovementStates.closing;
      if ((telemetry[5] & 0x03) === 2) this.handMovementState = HandMovementStates.opening;
      this.gripInTransition = (telemetry[5] & 0x04) >> 2 === 1;
    }
    const newGrip = this.oldTelemetry ? telemetry[23] : telemetry[4];
    const voltage = this.oldTelemetry ? telemetry[24] : Math.ceil(telemetry[3] / 10);
    if (newGrip !== this.prosthesisGrip) {
      this.prosthesisGrip = newGrip;
    }
    // @ts-ignore
    this.telemetryData = newData;
    this.prosthesisVoltage = voltage;
  },
  parseReplayTelemetry(telemetryData: any) {
    const { reset, data } = telemetryData.detail;
    if (reset) telemetryParser.clear();
    if (data.length > 10) this.oldTelemetry = true;
    const newData = telemetryParser.parseTelemetry(data, 500, this.oldTelemetry);
    this.telemetryData = newData;
  },
  abortReplay() {
    this.eventListenerReplayController.abort();
    this.telemetryReplayListening = false;
    telemetryParser.clear();
    this.telemetryData = null;
  },
  enableReplay() {
    this.eventListenerReplayController = new AbortController();
    if (!this.telemetryReplayListening) {
      window.addEventListener(`replay`, this.parseReplayTelemetry.bind(this), {
        signal: this.eventListenerReplayController.signal
      });
      this.telemetryReplayListening = true;
      telemetryParser.clear();
    }
  },
  async checkVoltage() {
    if (!BluetoothWebController.telemetryEnabled) {
      await BluetoothWebController.telemetryOn();
      await delay(400);
      await BluetoothWebController.telemetryOff();
    }
    return this.prosthesisVoltage;
  },
  initiateTelemetry() {
    this.eventListenerController = new AbortController();
    const commandToListen = BluetoothWebController.isSupportingAck
      ? Commands.kNewTelemetry
      : Commands.kTelemetryData;
    if (!this.telemetryListening) {
      window.addEventListener(`received${commandToListen}`, this.parseTelemetry.bind(this), {
        signal: this.eventListenerController.signal
      });
      this.telemetryListening = true;
    }
  },
  abortTelemetry() {
    this.eventListenerController.abort();
    this.telemetryListening = false;
  }
};

export default TelemetryController;
