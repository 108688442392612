import { VALIDATOR_TEXT } from 'constants/validatorText';
import * as yup from 'yup';
import { stringSchema } from 'utils/schema';

export const customGripSchema = yup.object().shape({
  name: stringSchema.required(VALIDATOR_TEXT.REQUIRED).max(30, ({ max, value }) => ({
    key: VALIDATOR_TEXT.MAX_LENGTH,
    values: { max, currentLength: value.length, exceededBy: value.length - max }
  }))
});
