import { postActiveMode, postCommunicateMode } from 'bluetooth/bluetoothFunctions';
import { MODALS } from 'configurator/views/Modals';
import { useConfigStore } from 'configurator/reducers/configStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useDeviceManager } from './api/useDeviceManager';
import useRemoteSession from './useRemoteSession';
import { useUiStore } from 'configurator/reducers/uiStore';
import { NotificationFactory } from 'lib/NotificationFactory';
import DeviceConfigurationService from 'bluetooth/configController/configController';

const useModes = () => {
  const { setItemConfigStore, modes } = useConfigStore((state) => ({
    modes: state.config.modes,
    setItemConfigStore: state.setItemConfigStore
  }));
  const { switchOnRemoteSession, initialConfigApiState, closeModal, openModal } = useUiStore(
    (state) => ({
      switchOnRemoteSession: state.switchOnRemoteSession,
      setItemUiStore: state.setItemUiStore,
      initialConfigApiState: state.initialConfigApiState,
      closeModal: state.closeModal,
      openModal: state.openModal
    })
  );
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const { sendConfigOnlyToApi, sendConfigToDevice, isLoadingDeviceManager } = useDeviceManager();
  const { sendModeChange } = useRemoteSession();

  const handleChangeMode = async (modeSlot) => {
    if (modes.length < 2) return;

    if (deviceConnected) {
      await postCommunicateMode(modeSlot);
      await postActiveMode(modeSlot);
    }

    setItemConfigStore('slotSelected', modeSlot);

    const modeInfo = modes?.find((mode) => mode.slot === modeSlot);

    if (!modeInfo?.id || !modeInfo?.name) {
      return;
    }

    await sendModeChange({ slot: modeSlot, id: modeInfo.id, name: modeInfo.name });
  };

  const handleOpenSave = (isUnsaved, args: { action: any; args: any } | undefined = undefined) => {
    if (isUnsaved) {
      openModal(MODALS.confirmSave, args);
      return;
    }

    NotificationFactory.errorNotification("Configuration hasn't changed", '', {
      id: 'configurationDontChangedToast'
    });
  };

  const handleSaveMode = async (data?) => {
    if (deviceConnected) await sendConfigToDevice();
    await sendConfigOnlyToApi(data?.note);
    await DeviceConfigurationService.getInitialConfigAPI();
    closeModal(MODALS.confirmSave);
  };

  const handleSaveModeBeforeAutoLogout = async (data) => {
    await sendConfigOnlyToApi(data.note, { updateConfigWhenDeviceIsConnected: false });
    await DeviceConfigurationService.getInitialConfigAPI();
    closeModal(MODALS.confirmSave);
  };

  return {
    switchOnRemoteSession,
    handleOpenSave,
    handleChangeMode,
    handleSaveMode,
    handleSaveModeBeforeAutoLogout,
    isLoadingConfigSave: isLoadingDeviceManager || initialConfigApiState === FETCHING_STATES.loading
  };
};

export default useModes;
