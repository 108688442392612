import './theme/web-therapy.css';
import * as Sentry from '@sentry/react';
import { setApiAuthToken } from 'adp-panel/api/utils/apiClient';
import Toaster from 'adp-panel/components/Toaster/Toaster';
import { LIVE_CHAT_LICENCE } from 'adp-panel/constants/config';
import useRefreshToken from 'adp-panel/hoc/useRefreshToken';
import useLocalizeDocumentAttributes from 'adp-panel/hooks/useLocalizeDocumentAttributes';
import AuthenticatedAppLayout from 'app/AuthenticatedAppLayout';
import NotProtectedApp from 'app/NotProtectedApp';
import BluetoothEventsHandler from 'bluetooth/bluetoothEventsHandler';
import BootloaderController from 'bluetooth/bootloaderController';
import LiveConfigurator from 'configurator/components/organisms/LiveConfigurator/LiveConfigurator';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import useModes from 'configurator/hooks/useModes';
import useSidebar from 'configurator/hooks/useSidebar';
import useUnsaved from 'configurator/hooks/useUnsaved';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import Modals, { MODALS, MODALS_ARGS } from 'configurator/views/Modals';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthStore } from 'reducers/authStore';
import ConfirmationLoader from './adp-panel/layouts/ConfirmationLoader';
import useUserData from './hooks/useUserData';
import { LiveChatWidget } from '@livechat/widget-react';
import useIsOnline from 'hooks/useIsOnline.';
import AudioPlayer, { playSoundElement } from 'components/AudioPlayer';
import useOfflineNotification from 'hooks/useOfflineNotifications';
import { REGISTER_USER_2FA } from './constants/routes';

const Bootloader = new BootloaderController();

const App = () => {
  const { mfa, token, isUserLoggingOut } = useAuthStore((state) => ({
    token: state.token,
    mfa: state.mfa,
    isUserLoggingOut: state.isUserLoggingOut
  }));
  const { refreshToken } = useRefreshToken();
  const currentUser = useUserData();
  const { data: userData, rolesByName, isLoading: loadingUserData } = currentUser;
  const loggedIn = Boolean(token);
  const isOnline = useIsOnline();
  useOfflineNotification(isOnline);
  const { bluetoothDisconnect } = useBluetooth();
  const { isUnsaved } = useUnsaved();
  const { ticketCanBeSent } = useSidebar();
  const { blockModals, isModalOpen } = useUiStore((state) => ({
    setItemUiStore: state.setItemUiStore,
    blockModals: state.blockModals,
    isModalOpen: state.isModalOpen
  }));
  const { deviceConnected } = useDeviceInfoStore((state) => ({ deviceConnected: state.connected }));
  const remoteSessionState = useLiveConfiguratorStore((state) => state);
  const { enabled: remoteSessionEnabled } = remoteSessionState;
  const { handleOpenSave } = useModes();

  useLocalizeDocumentAttributes();

  const authenticatedUser = loggedIn && userData !== undefined && mfa.required === false;

  useEffect(() => {
    if (!token) refreshToken();
    setApiAuthToken(token);
  }, [token]);

  useEffect(() => {
    Bootloader.listenBootloaderStatus();
    BluetoothEventsHandler.initiateBluetoothEventsListening();

    return function clearConnection() {
      if (deviceConnected) {
        bluetoothDisconnect();
      }
    };
  }, []);

  const preventBrowserWarningModalIsOpen =
    isModalOpen(MODALS.firmware) ||
    isModalOpen(MODALS.confirmSave) ||
    isModalOpen(MODALS.closeSession) ||
    isModalOpen(MODALS.disruptiveDisconnect);

  useEffect(() => {
    function handleLeave(event) {
      if (
        isUnsaved &&
        !preventBrowserWarningModalIsOpen &&
        !remoteSessionEnabled &&
        ticketCanBeSent &&
        token
      ) {
        playSoundElement();
        handleOpenSave(isUnsaved, {
          action: () => false,
          args: MODALS_ARGS.unsavedChangesDisconnect
        });
        event.returnValue = 'There is pending work. Sure you want to leave?';
      }
    }

    window.addEventListener('beforeunload', handleLeave);

    return () => window.removeEventListener('beforeunload', handleLeave);
  }, [
    isUnsaved,
    blockModals,
    remoteSessionEnabled,
    ticketCanBeSent,
    preventBrowserWarningModalIsOpen,
    token
  ]);

  const [searchParams] = useSearchParams();
  const isRedirectTo2FA = REGISTER_USER_2FA === searchParams.get('redirect');
  const firstLoginDuringCreateUserProcess = !authenticatedUser && isRedirectTo2FA;

  if (loadingUserData || isUserLoggingOut || firstLoginDuringCreateUserProcess) {
    return <ConfirmationLoader fullScreen />;
  }

  return (
    <>
      <LiveChatWidget license={LIVE_CHAT_LICENCE} />
      <LiveConfigurator remoteSessionState={{ ...remoteSessionState, isUnsaved }}>
        <Toaster />
        <Modals />
        <AudioPlayer />
        {authenticatedUser ? (
          <AuthenticatedAppLayout currentUser={currentUser} rolesByName={rolesByName} />
        ) : (
          <NotProtectedApp />
        )}
      </LiveConfigurator>
    </>
  );
};

export default Sentry.withProfiler(App);
