import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HeaderM } from 'configurator/components/atoms/Typography/Typography';
import { MODALS } from 'configurator/views/Modals';
import { useUiStore } from 'configurator/reducers/uiStore';
import BluetoothWebController from 'bluetooth/bluetoothWeb';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const ParagraphBold = styled.p`
  ${HeaderM}
`;

type AboutProps = {
  handleClose: Function;
};

const BootloaderModeModal = ({ handleClose }: AboutProps) => {
  const openModal = useUiStore((state) => state.openModal);
  const { t } = useTranslation();

  const accepted = async () => {
    openModal(MODALS.firmware);
    handleClose();
  };

  const denied = async () => {
    BluetoothWebController.disconnectBluetooth();
    handleClose();
  };

  return (
    <ModalWrapper
      title={t(
        'configurator:component.bootloader_mode_modal.firmware_update_mode',
        'Firmware update mode'
      )}>
      <ParagraphBold>
        {t(
          'configurator:component.bootloader_mode_modal.device_in_firmware_update_mode',
          'Device is in firmware update mode, please update to use the software.'
        )}{' '}
      </ParagraphBold>
      <FormButtonsWrapper>
        <CustomButton color='light' onClick={denied}>
          {t('configurator:component.bootloader_mode_modal.no_disconnect', 'No, disconnect')}
        </CustomButton>
        <CustomButton onClick={accepted}>
          {t('configurator:component.bootloader_mode_modal.yes_update', 'Yes, update')}
        </CustomButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default BootloaderModeModal;
