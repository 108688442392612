import { ProcedureTypes } from 'bluetooth/bluetoothCommunication/Procedures';
import React from 'react';
import { ProcedureRow, ProcedureStatusBox, ProcedureTable } from '../CalibrationProcedure/styled';

const valuesMapping = {
  [ProcedureTypes.calibrateSoftGrip]: 'Max currents (mA)',
  [ProcedureTypes.calibrateFingersSpeed]: 'Speeds'
};

const ProcedureFingersTable = ({
  procedureType,
  procedureStatus,
  procedureValues
}: {
  procedureType: ProcedureTypes;
  procedureStatus: [0 | 1, 0 | 1, 0 | 1, 0 | 1, 0 | 1];
  procedureValues: [number, number, number, number, number];
}) => (
  <table>
    <ProcedureTable>
      <ProcedureRow>
        <td aria-label='Procedure information'>&nbsp;</td>
        <td>Thumb</td>
        <td>Index</td>
        <td>Middle</td>
        <td>Ring</td>
        <td>Pinky</td>
      </ProcedureRow>
      <ProcedureRow>
        <td colSpan={1}>Status</td>
        {procedureStatus.map((status, index) => (
          <ProcedureStatusBox colSpan={1} status={procedureStatus[index]}>
            {status === 1 ? 'OK' : 'NOT OK'}
          </ProcedureStatusBox>
        ))}
      </ProcedureRow>
      <ProcedureRow>
        <td colSpan={1}>{valuesMapping[procedureType]}</td>
        {procedureValues.map((current) => (
          <td colSpan={1}>{current}</td>
        ))}
      </ProcedureRow>
    </ProcedureTable>
  </table>
);

export default ProcedureFingersTable;
