import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { useConfigStore } from 'configurator/reducers/configStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import DeviceConfigurationService from 'bluetooth/configController/configController';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const ParagraphBold = styled.p`
  ${TextS};
  margin-bottom: 40px;
`;

type RestoreRecentConfigModalProps = {
  handleClose: any;
};

const RestoreRecentConfigModal = ({ handleClose }: RestoreRecentConfigModalProps) => {
  const closeModal = useUiStore((state) => state.closeModal);
  const { t } = useTranslation();
  const { clearConfigHistory } = useConfigStore((state) => ({
    clearConfigHistory: state.clearConfigHistory
  }));
  const initialConfigApiState = useUiStore((state) => state.initialConfigApiState);
  const restoreRecentChannel = new BroadcastChannel('handleRestoreRecent');

  const restoreRecentConfig = async () => {
    clearConfigHistory();
    await DeviceConfigurationService.getInitialConfigAPI();
    closeModal(MODALS.restoreRecent);
    restoreRecentChannel.postMessage({ event: 'restoreRecent' });
  };

  return (
    <ModalWrapper
      title={t(
        'configurator:component.restore_recent_config_modal.restore_configuration',
        'Restore configuration'
      )}>
      <ParagraphBold>
        {t(
          'configurator:component.restore_recent_config_modal.confirm_restore_recent',
          'Do you want to restore recent config? Unsaved changes will be lost.'
        )}
      </ParagraphBold>
      <FormButtonsWrapper>
        <CustomButton onClick={handleClose} variant='outlined' type='button'>
          {t('configurator:component.restore_recent_config_modal.cancel', 'Cancel')}
        </CustomButton>
        <LoadingButton
          type='button'
          onClick={restoreRecentConfig}
          loading={initialConfigApiState === FETCHING_STATES.loading}>
          <span>
            {t(
              'configurator:component.restore_recent_config_modal.restore_recent',
              'Restore recent'
            )}
          </span>
        </LoadingButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default RestoreRecentConfigModal;
