import { flatten, unflatten } from 'flat';
import { areAllKeysUninitialized } from 'configurator/reducers/helpers';
import { ModeType, ConfigResult } from './types';
import {
  ModeConfigTemplate,
  CommonConfigTemplate,
  DeviceConfigTemplate
} from 'configurator/consts/deviceConfig/deviceConfig.types';
import {
  defaultConfig,
  commonConfigProperties,
  modeConfigProperties
} from 'configurator/consts/deviceConfig/deviceConfig';
import BluetoothWebController from '../bluetoothWeb';
import {
  getAllThresholds,
  getCurrentConfigApiSelector,
  getCurrentConfigSelector,
  getFwVersionSelector
} from 'configurator/reducers/helpers/selectors';
import { keys, pickBy, isEmpty } from 'lodash';
import { EVENTS } from 'configurator/consts/events';
import { HASH } from 'adp-panel/constants/featureToggles';
import { getFeatureToggles } from 'adp-panel/api/productFeature/productFeature';
import toast from 'react-hot-toast';
import {
  ConfigToSendFunctionMapping,
  getAllHashes,
  getDeviceConfigurations,
  postCommunicateMode,
  postRtcTime
} from '../../bluetooth/bluetoothFunctions';
import {
  getDeviceConfig,
  getDeviceHashes,
  updateDeviceConfig,
  updateDeviceHashes
} from 'configurator/api/device/device';
import dayjs from 'dayjs';
import { delay } from 'bluetooth/bluetoothCommunication/Utilities';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useConfigStore } from 'configurator/reducers/configStore';
import i18next from 'i18next';
import { produce } from 'immer';
import DeviceConfigurationService from './configController';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { compareConfigs } from 'configurator/reducers/helpers/bluetoothHelpers';
import { getModesConfigForDevice } from 'configurator/api/modes/modes';

export const prepareConfigProperties = (
  config: {
    common: {
      config: CommonConfigTemplate;
      configAPI: CommonConfigTemplate | null;
    };
    modes: {
      config: ModeConfigTemplate;
      configAPI: ModeConfigTemplate | null;
      slot: number;
      name: string;
      id: number | null;
    }[];
  },
  deviceId
) => {
  const filteredConfigAPI = (configAPI): any => {
    const flattenConfigAPI: any = flatten(configAPI, { safe: true });
    const filteredConfigAPI = Object.fromEntries(
      Object.entries(flattenConfigAPI).filter(([, value]) => value !== false)
    );

    //@ts-ignore
    return unflatten(filteredConfigAPI, { safe: true });
  };
  const featureIsInactive = (val) => !val;

  const countDisabledFeatures = (config) => {
    if (!config) {
      return 0;
    }
    const flattenConfig: any = flatten(config, { safe: true });
    return keys(pickBy(flattenConfig, featureIsInactive)).length;
  };

  const configs = [
    [config.common.config, config.common.configAPI],
    ...config.modes.map((mode) => [mode.config, mode.configAPI])
  ];
  let needsUpdate = false;
  for (let i = 0; i < configs.length; i += 1) {
    if (countDisabledFeatures(configs[i][1]) > 0) {
      needsUpdate = true;
      break;
    }
  }
  let configPayload: null | { deviceId: number; data: any } = null;
  if (needsUpdate) {
    const filteredCommonConfig = filteredConfigAPI(config.common.configAPI);
    configPayload = {
      deviceId,
      data: {
        common: JSON.stringify({
          ...config.common.config,
          ...filteredCommonConfig,
          gripsPositions: {
            ...config.common.config.gripsPositions,
            ...filteredCommonConfig.gripsPositions
          }
        }),
        modes: config.modes.map((mode) => ({
          id: mode.id!,
          config: JSON.stringify({
            ...mode.config,
            ...filteredConfigAPI(mode.configAPI)
          })
        }))
      }
    };
  }
  return configPayload;
};

export const getHashStatus = async (deviceId) => {
  const { connected } = useDeviceInfoStore.getState();

  if (!connected) return null;

  let globalHash, modeHashes;
  const hashes: any = {
    global: { hash: null, mismatch: null },
    modes: [
      { hash: null, mismatch: null },
      { hash: null, mismatch: null },
      { hash: null, mismatch: null }
    ]
  };
  const toggles = await getFeatureToggles();
  const isHashToggleEnabled = toggles?.find((toggle) => toggle.slug === HASH)?.enabled;

  const deviceHashes = await getDeviceHashes(deviceId);
  if (!deviceHashes) return null;

  const firmwareSupportsHash = getFwVersionSelector() >= 20301;
  const hashSupported = await getAllHashes(firmwareSupportsHash);

  if (hashSupported && isHashToggleEnabled) {
    globalHash = hashSupported.globalHash;
    modeHashes = hashSupported.modeHashes;
  }

  // Hash is not changed
  if (globalHash && deviceHashes?.hash_global) {
    if (globalHash !== deviceHashes.hash_global) {
      hashes.global.mismatch = true;
    }
    for (let index = 0; index < hashes.modes.length; index++) {
      const modeHashApi = deviceHashes[`hash_mode${index + 1}`];
      if (modeHashes[index] !== modeHashApi) hashes.modes[index].mismatch = true;
    }
  } else {
    hashes.global.mismatch = true;
    hashes.modes = hashes.modes.map((mode) => ({ ...mode, mismatch: true }));
  }

  return { hashes, globalHash, modeHashes, hashSupported, isHashToggleEnabled };
};

export const afterConfigurationConflictCheck = async ({ deviceId, commonConfig, modesConfigs }) => {
  const configAPI = await getDeviceConfig(Number(deviceId));
  const deviceDifferencesArray: any = [];

  const differenceCommon = compareConfigs(commonConfig, configAPI.common);
  if (!isEmpty(differenceCommon)) deviceDifferencesArray.push(differenceCommon);

  modesConfigs.forEach((element) => {
    const difference = compareConfigs(
      element.config,
      configAPI.modes.find((mode) => mode.slot === element.slot)?.config
    );

    if (!isEmpty(difference)) deviceDifferencesArray.push({ difference, slot: element.slot });
  });

  const isConfigConflict = deviceDifferencesArray.length > 0;

  return isConfigConflict;
};

export const sendWholeConfigDevice = async ({
  configToSend
}: {
  configToSend: any;
  sendPermanently?: boolean;
}) => {
  const infoMessage = toast.loading(
    i18next.t('configurator:config_store.notification.sending_changes', 'Sending changes...')
  );
  try {
    if (!useDeviceInfoStore.getState().connected) {
      NotificationFactory.errorNotification(
        i18next.t(
          'configurator:config_store.notification.device_not_connected',
          'Device not connected'
        )
      );
      return false;
    }
    const { configConflict } = useConfigStore.getState();
    const deviceInfoState = useDeviceInfoStore.getState();
    const prevState = { ...useConfigStore.getState() };
    const allThresholds = getAllThresholds(prevState, deviceInfoState, configConflict);

    for (const key in configToSend) {
      if (Object.prototype.hasOwnProperty.call(configToSend, key)) {
        let args;
        const apiConfig = getCurrentConfigApiSelector(prevState);
        const deviceConfig = getCurrentConfigSelector(prevState);

        switch (key) {
          case 'freezeModeEmg':
            args = [
              configToSend[key],
              deviceInfoState.versions?.current,
              configConflict ? apiConfig.inputSite : deviceConfig.inputSite,
              configConflict ? apiConfig?.inputDevice : deviceConfig?.inputDevice,
              allThresholds
            ];
            break;
          case 'emgThresholds':
            args = [configToSend[key], allThresholds];
            break;
          case 'singleElectrodeModeSettings':
            args = [configToSend[key], allThresholds];
            break;
          case 'freezeModeEmgSettings':
            args = [configToSend[key], allThresholds];
            break;
          default:
            args = [configToSend[key]];
        }

        await ConfigToSendFunctionMapping[key](...args);
        await delay(100);
      }
    }

    console.log(configToSend, 'TO SEND');

    toast.dismiss(infoMessage);
    NotificationFactory.successNotification(
      i18next.t('configurator:config_store.notification.config_sent', 'Config sent'),
      '',
      {
        id: 'configSentToast'
      }
    );
    return true;
  } catch (err) {
    toast.dismiss(infoMessage);
    return err;
  }
};

export const applyConfigurationUpdatesApi = async ({
  configAPI,
  commonKeys = null,
  modesKeys = null,
  modesData = null
}: {
  configAPI: any;
  commonKeys?: null | Array<keyof DeviceConfigTemplate>;
  modesKeys?: null | Array<keyof DeviceConfigTemplate>;
  modesData?: null | Array<{ active: 0 | 1; slot: 0 | 1 | 2 }>;
}) => {
  const { setConfigCopy } = useConfigStore.getState();
  if (commonKeys || modesKeys) {
    useConfigStore.setState({
      commonPropertiesAPI: commonKeys,
      modePropertiesAPI: modesKeys
    });
  }

  const isNewConfig =
    areAllKeysUninitialized(configAPI?.common) ||
    !configAPI?.common ||
    areAllKeysUninitialized(configAPI?.modes[0]?.config);

  if (!isNewConfig) {
    const modes = configAPI.modes.map((modeInfo) => ({
      config: modeInfo.config,
      configAPI: modeInfo.config,
      name: modeInfo.name,
      slot: modeInfo.slot,
      id: modeInfo.id,
      active: modesData?.find((modeData) => modeData.slot === modeInfo.slot)?.active
    }));

    const config = {
      common: {
        config: configAPI.common,
        configAPI: configAPI.common
      },
      modes
    };
    useConfigStore.setState(
      produce((state: any) => {
        state.config = config;
        state.firstConnection = false;
      }),
      false,
      { type: 'getInitialConfigAPI' }
    );
    setConfigCopy();
  }

  if (isNewConfig) {
    if (commonKeys && modesKeys) {
      const adjustedModeConfig = modesKeys.reduce(
        (prev, curr) => ({ ...prev, [`${curr}`]: defaultConfig[`${curr}`] }),
        {}
      );
      const adjustedCommonConfig = commonKeys.reduce(
        (prev, curr) => ({ ...prev, [`${curr}`]: defaultConfig[`${curr}`] }),
        {}
      );
      useConfigStore.setState(
        produce((state: any) => {
          state.config.common.config = adjustedCommonConfig;
          state.config.modes[0].config = adjustedModeConfig;
        }),
        false,
        { type: 'Adjust default config' }
      );
    }
    useConfigStore.setState({ firstConnection: true });
    toast(
      i18next.t(
        'configurator:config_store.notification.device_config_retrieved_fail',
        'Device config could not be retrieved, connect the device and send config'
      ),
      {
        icon: '⚠️',
        id: 'deviceConfigRetrievedFailToast'
      }
    );
  }
};

const setDumTime = async () => {
  const dateTime = dayjs();
  const getShortYear = (date) => Number(String(date).slice(2, 4));
  await postRtcTime([
    getShortYear(Number(dateTime.year())),
    Number(dateTime.month()) + 1,
    Number(dateTime.date()),
    Number(dateTime.hour()),
    Number(dateTime.minute()),
    Number(dateTime.second())
  ]);
};

export const applyConfigurationUpdates = async ({
  result,
  set,
  setConfigCopy,
  deviceId,
  hashIsEnabled,
  globalHash,
  modeHashes
}: {
  result: ConfigResult;
  set: typeof useConfigStore.setState;
  setConfigCopy: () => void;
  deviceId: number;
  hashIsEnabled: boolean;
  globalHash: any;
  modeHashes: any;
}) => {
  if (!result) return;

  const commonConfig = result.common;
  const modesConfigs = result.modes;
  const configurationDownloadedSuccess = commonConfig;

  if (Boolean(configurationDownloadedSuccess) === false) {
    NotificationFactory.errorNotification(
      i18next.t(
        'configurator:config_store.notification.configuration_downloaded_fail',
        'Could not connect to the Zeus Hand'
      ),
      '',
      {
        id: 'configurationDownloadedFailToast'
      }
    );
    return;
  }

  if (configurationDownloadedSuccess) {
    const isConflict = await afterConfigurationConflictCheck({
      deviceId,
      commonConfig,
      modesConfigs
    });

    set(
      produce((state: any) => {
        state.config.common.config = commonConfig;
        state.config.modes = modesConfigs;
        state.firstConnection = false;
        state.localConfigFetched = true;
        state.configConflict = isConflict;
      }),
      false,
      { type: 'getInitialConfig', commonConfig, modesConfigs }
    );
    setConfigCopy();

    let conflictResolved = isConflict ? false : true;
    if (isConflict) {
      console.log(isConflict, 'CONFLICT');
      const { config } = useConfigStore.getState();
      conflictResolved = (await DeviceConfigurationService.synchronizeConfig(config)) || false;
      console.log(conflictResolved, 'CONFLICT RESOLVED');
    }

    if (conflictResolved && hashIsEnabled) {
      await updateDeviceHashes({
        deviceId,
        data: {
          hash_global: String(globalHash),
          hash_mode1: String(modeHashes[0]),
          hash_mode2: String(modeHashes[1]),
          hash_mode3: String(modeHashes[2])
        }
      });
    }

    const { config } = useConfigStore.getState();
    const configPayload = prepareConfigProperties(config, deviceId);
    if (configPayload) {
      await updateDeviceConfig({ deviceId: configPayload.deviceId, data: configPayload.data });
      await DeviceConfigurationService.getInitialConfigAPI();
    }

    NotificationFactory.successNotification(
      i18next.t(
        'notifications:config_store.notification.configuration_downloaded',
        'Configuration downloaded'
      ),
      '',
      {
        id: 'configurationDownloadedToast'
      }
    );
  }
};

export const fetchConfigs = async ({
  deviceId,
  connected,
  commonPropertiesAPI,
  modePropertiesAPI,
  hashes
}) => {
  let aborted = false;
  const abort = (reject) => {
    aborted = true;
    return reject(false);
  };

  const executeConfig = async (
    resolve: (value: ConfigResult | false) => void,
    reject: (reason?: any) => void
  ) => {
    const infoMessage = toast.loading(
      i18next.t(
        'configurator:config_store.notification.preparing_download_config',
        'Preparing to download config...'
      )
    );
    try {
      window.addEventListener(EVENTS.disconnect, () => abort(reject));

      if (!deviceId) {
        NotificationFactory.errorNotification(
          i18next.t('configurator:config_store.notification.device_id_missing', 'Device id missing')
        );
        return resolve(false);
      }

      if (!connected) {
        toast.dismiss(infoMessage);
        return resolve(false);
      }

      if (BluetoothWebController.telemetryEnabled) {
        await BluetoothWebController.telemetryOff();
      }
      await delay(100);

      const commonProperties = commonPropertiesAPI || commonConfigProperties;
      const modeProperties = modePropertiesAPI || modeConfigProperties;

      const common = commonProperties.map((property) => ({
        name: property,
        arguments: []
      }));
      const mode = modeProperties.map((property) => ({
        name: property,
        arguments: []
      }));

      if (aborted) return resolve(false);

      const configAPI = await getDeviceConfig(Number(deviceId));

      // @ts-ignore
      let commonConfig: CommonConfigTemplate = configAPI.common;

      // Fallback to downloading configuration from device if there is a mismatch or at least 1 value in API is false
      if (hashes.global.mismatch || Object.values(commonConfig).includes(false)) {
        commonConfig = await getDeviceConfigurations(common, aborted);
      }

      if (aborted) return resolve(false);

      const modesData = await getModesConfigForDevice({ deviceId });
      const modesActivity = modesData
        ? modesData.map((modeData) => ({ active: modeData.active, slot: modeData.slot }))
        : null;
      const modesConfigs: ModeType[] = [];

      for (let index = 0; index < configAPI.modes.length; index += 1) {
        const element = configAPI.modes[index];
        await delay(100);
        await postCommunicateMode(element.slot);
        await delay(100);
        const loadingModeToast = toast.loading(
          i18next.t('configurator:config_store.notification.fetching_mode_settings', {
            mode: element.name,
            defaultValue: 'Fetching {{mode}} settings'
          }),
          {
            id: infoMessage
          }
        );

        // @ts-ignore
        let modeConfig: ModeConfigTemplate = element.config;
        // Fallback to downloading configuration from device if there is a mismatch or at least 1 value in API is false
        if (
          Array.isArray(element.config) ||
          hashes.modes[index].mismatch ||
          Object.values(modeConfig).includes(false)
        ) {
          modeConfig = await getDeviceConfigurations(mode, aborted);
        }

        if (aborted) {
          toast.dismiss(loadingModeToast);
          return resolve(false);
        }

        modesConfigs.push({
          config: modeConfig,
          slot: element.slot,
          name: element.name,
          id: element.id,
          configAPI: Array.isArray(element.config) ? null : element.config,
          active: modesActivity?.find((modeData) => modeData.slot === element.slot)?.active
        });
      }

      if (aborted) return resolve(false);

      await delay(100);
      await postCommunicateMode(0);
      await delay(100);
      await setDumTime();
      await delay(100);
      toast.dismiss(infoMessage);

      return resolve({ common: commonConfig, modes: modesConfigs });
    } catch (err: any) {
      console.error(err, 'Bad connection, disconnecting');
      toast.dismiss(infoMessage);
      await BluetoothWebController.disconnectBluetooth();
      return reject(err.message);
    }
  };

  const getInitialConfigFunc = new Promise<ConfigResult | false>((resolve, reject) => {
    executeConfig(resolve, reject);
  });

  const result = await getInitialConfigFunc;

  window.removeEventListener(EVENTS.disconnect, abort);
  return result;
};
