/* eslint-disable react/jsx-key */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import {
  controlModeMap,
  gripSwitchingMap,
  gripsGroupsOptionsMap,
  handSideMap,
  inputSiteMap,
  singleElectrodeModeMap,
  speedControlMap,
  thumbLengthMap,
  inputDeviceMap,
  allUserFeedbackTypeMap,
  aetherBatteryVoltageFunctions,
  genericSwitchingMap
} from 'configurator/utils/definesLocal';
import { StartOnStartup } from 'bluetooth/bluetoothCommunication/Control';
import { StatusIndicator } from 'configurator/components/atoms/ConfigItem/ConfigItem';
import { singleElectrodeSettingsAlternatingPositions } from 'configurator/consts/deviceConfig/configPropertiesPositions';
import { singleElectrodeSettingsAlternatingNamingsMap } from 'configurator/consts/deviceConfig/configPropertiesNamings';
import i18next from 'i18next';

const parseNumber = (number: number) => Number(number / 1000).toFixed(2);

export const ConfigToValueFunctionMapping = new Proxy(
  {
    gripsPositions: (value: any) => <div>{value}</div>,
    gripPairsConfig: (gripPairsConfig: any, index: any) => (
      <ul style={{ listStyle: 'none' }}>
        {index === 0 ? (
          <>
            <li>
              <strong>
                {i18next.t('configurator:component.value_mapping.primary', 'Primary')}
              </strong>
              <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
                {gripPairsConfig.slice(0, 2).map((grip: any) => (
                  <li>{i18next.t(gripsGroupsOptionsMap.get(grip))}</li>
                ))}
              </ul>
            </li>
            <li>
              <strong>
                {i18next.t('configurator:component.value_mapping.secondary', 'Secondary')}
              </strong>
              <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
                {gripPairsConfig.slice(2, 4).map((grip: any) => (
                  <li>{i18next.t(gripsGroupsOptionsMap.get(grip))}</li>
                ))}
              </ul>
            </li>
          </>
        ) : (
          <>
            <li>
              <strong>
                {i18next.t('configurator:component.value_mapping.primary', 'Primary')}
              </strong>
              <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
                {gripPairsConfig.slice(4, 6).map((grip: any) => (
                  <li>{i18next.t(gripsGroupsOptionsMap.get(grip))}</li>
                ))}
              </ul>
            </li>
            <li>
              <strong>
                {i18next.t('configurator:component.value_mapping.secondary', 'Secondary')}
              </strong>
              <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
                {gripPairsConfig.slice(6, 8).map((grip: any) => (
                  <li>{i18next.t(gripsGroupsOptionsMap.get(grip))}</li>
                ))}
              </ul>
            </li>
          </>
        )}
      </ul>
    ),
    inputSite: (inputSite: any) => i18next.t(inputSiteMap.get(inputSite)),
    inputDevice: (inputDevice: any) => i18next.t(inputDeviceMap.get(inputDevice)),
    controlMode: (controlMode: any) => i18next.t(controlModeMap.get(controlMode)),
    speedControlStrategy: (speedControlStrategy: any) =>
      i18next.t(speedControlMap.get(speedControlStrategy)),
    gripSwitchingMode: (gripSwitchingMode: any) =>
      i18next.t(gripSwitchingMap.get(gripSwitchingMode)),
    gripSequentialConfig: (gripSequentialConfig: any, index: any) =>
      index === 0 ? (
        <ul style={{ listStyle: 'none' }}>
          {gripSequentialConfig.slice(0, 5).map((grip: any) => (
            <li>{i18next.t(gripsGroupsOptionsMap.get(grip))}</li>
          ))}
        </ul>
      ) : (
        <ul style={{ listStyle: 'none' }}>
          {gripSequentialConfig.slice(6, 11).map((grip: any) => (
            <li>{i18next.t(gripsGroupsOptionsMap.get(grip))}</li>
          ))}
        </ul>
      ),
    emgThresholds: (value: any) => <div>{value}%</div>,
    fingerStrength: (value: any) => <div>{value}</div>,
    autoGrasp: (value: any, index: any) =>
      index === 0 ? (
        <div>
          <StatusIndicator status={value} />
          {value === 1
            ? i18next.t(genericSwitchingMap.get(1))
            : i18next.t(genericSwitchingMap.get(0))}
        </div>
      ) : (
        <div>{value}</div>
      ),
    emgSpike: (value: any, index: any) =>
      index === 0 ? (
        <div>
          <StatusIndicator status={value} />
          {value === 1
            ? i18next.t(genericSwitchingMap.get(1))
            : i18next.t(genericSwitchingMap.get(0))}
        </div>
      ) : (
        <div>
          <ReactTooltip id={`emgSpike-${value}`} place='top' type='info' effect='solid'>
            <span>{value} ms</span>
          </ReactTooltip>
          <span data-for={`emgSpike-${value}`} data-tip>
            {parseNumber(value)} s
          </span>
        </div>
      ),
    holdOpen: (value: any) => (
      <div>
        <ReactTooltip id={`holdOpen-${value}`} place='top' type='info' effect='solid'>
          <span>{value} ms</span>
        </ReactTooltip>
        <span data-for={`holdOpen-${value}`} data-tip>
          {parseNumber(value)} s
        </span>
      </div>
    ),
    softGrip: (value: any) => (
      <div>
        <StatusIndicator status={value} />
        {value === 1
          ? i18next.t(genericSwitchingMap.get(1))
          : i18next.t(genericSwitchingMap.get(0))}
      </div>
    ),
    singleElectrodeMode: (value: any) => i18next.t(singleElectrodeModeMap.get(value)),
    freezeModeEmg: (value: any, index: any) => {
      switch (index) {
        case 0:
          return <div>{value}</div>;
        case 1:
          return <div>{value}</div>;
        case 2:
          return (
            <div>
              <StatusIndicator status={value} />
              {value !== 0
                ? i18next.t('configurator:component.value_mapping.freeze_mode_enabled', 'Enabled')
                : i18next.t(
                    'configurator:component.value_mapping.freeze_mode_disabled',
                    'Disabled'
                  )}
            </div>
          );
        default:
          return null;
      }
    },
    emgGains: (value: any) => <div>{value}%</div>,
    pulseTimings: (value: any) => (
      <div>
        <ReactTooltip id={`pulseTimings-${value}`} place='top' type='info' effect='solid'>
          <span>{value} ms</span>
        </ReactTooltip>
        <span data-for={`pulseTimings-${value}`} data-tip>
          {parseNumber(value)} s
        </span>
      </div>
    ),
    singleElectrodeModeSettings: (value: any) => (
      <div>
        <ReactTooltip
          id={`singleElectrodeModeSettings-${value}`}
          place='top'
          type='info'
          effect='solid'>
          <span>{value} ms</span>
        </ReactTooltip>
        <span data-for={`singleElectrodeModeSettings-${value}`} data-tip>
          {parseNumber(value)} s
        </span>
      </div>
    ),
    coContractionTimings: (value: any) => (
      <div>
        <ReactTooltip id={`coContractionTimings-${value}`} place='top' type='info' effect='solid'>
          <span>{value} ms</span>
        </ReactTooltip>
        <span data-for={`coContractionTimings-${value}`} data-tip>
          {parseNumber(value)} s
        </span>
      </div>
    ),
    batteryBeep: (value: any, index: any) => {
      switch (index) {
        case 0:
          return <div>{aetherBatteryVoltageFunctions.voltageToPercent(value)} %</div>;
        case 1:
          return (
            <div>
              <StatusIndicator status={value} />
              {value === 1
                ? i18next.t(genericSwitchingMap.get(1))
                : i18next.t(genericSwitchingMap.get(0))}
            </div>
          );
        default:
          return null;
      }
    },
    generalHandSettings: (value: any, index: any) => {
      switch (index) {
        case 0:
          return <div>{i18next.t(handSideMap.get(value))}</div>;
        case 1:
          return <div>{i18next.t(thumbLengthMap.get(value))}</div>;
        case 2:
          return (
            <div>
              <StatusIndicator status={value === StartOnStartup.kWaitForFirstCoContraction} />
              {value === StartOnStartup.kWaitForFirstCoContraction
                ? i18next.t(genericSwitchingMap.get(1))
                : i18next.t(genericSwitchingMap.get(0))}
            </div>
          );
        default:
          return null;
      }
    },
    buzzingVolumeSettings: (value: any) => <div>{Math.round((value / 255) * 100)} %</div>,
    followingGrip: (value: any) => (
      <div>
        <StatusIndicator status={value} />
        {value === 1
          ? i18next.t(genericSwitchingMap.get(1))
          : i18next.t(genericSwitchingMap.get(0))}
      </div>
    ),
    freezeModeEmgSettings: (value: any, index: any) => {
      switch (index) {
        case 0:
          return <div>{value}</div>;
        case 1:
          return <div>{value}</div>;
        case 2:
          return <div>{value} ms</div>;
        case 3:
          return <div>{value} ms</div>;
        default:
          return null;
      }
    },
    userFeedbackType: (value: any) => (
      <div>{i18next.t(allUserFeedbackTypeMap.get(value) || '')}</div>
    ),
    emergencyBatterySettings: (value: any, index: any) => {
      switch (index) {
        case 0:
          return (
            <div>
              <StatusIndicator status={value} />
              {value !== 0
                ? i18next.t(genericSwitchingMap.get(1))
                : i18next.t(genericSwitchingMap.get(0))}
            </div>
          );
        case 2:
          return <div>{aetherBatteryVoltageFunctions.voltageToPercent(value)} %</div>;
        default:
          return null;
      }
    },
    oneSpeed: (value: any) => <div>{`${value}%`}</div>,
    singleElectrodeSettingsAlternating: (value: any, index: any) => {
      switch (index) {
        case singleElectrodeSettingsAlternatingPositions.stateSwitchDelay:
          return <div>{value} ms</div>;
        case singleElectrodeSettingsAlternatingPositions.minimumPulseTime:
          return <div>{value} ms</div>;
        case singleElectrodeSettingsAlternatingPositions.maximumPulseTime:
          return <div>{value} ms</div>;
        case singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses:
          return <div>{value} ms</div>;
        case singleElectrodeSettingsAlternatingPositions.maximumTimeBetweenPulses:
          return <div>{value} ms</div>;
        default:
          return null;
      }
    }
  },
  {
    get(obj: any, prop: any) {
      if (prop in obj) {
        return obj[prop];
      }
      return () => `Default ${String(prop)}`;
    }
  }
);

export const ConfigToNameFunctionMapping = new Proxy(
  {
    gripsPositions: (index: any) => {
      const gripsPositionsNamings = [
        i18next.t('configurator:component.value_mapping.grips_positions.thumb', 'Thumb'),
        i18next.t('configurator:component.value_mapping.grips_positions.index', 'Index'),
        i18next.t('configurator:component.value_mapping.grips_positions.middle', 'Middle'),
        i18next.t('configurator:component.value_mapping.grips_positions.ring', 'Ring'),
        i18next.t('configurator:component.value_mapping.grips_positions.pinky', 'Pinky')
      ];
      return gripsPositionsNamings[index];
    },
    gripPairsConfig: (index) => {
      const gripPairsConfigNaming = [
        i18next.t('configurator:component.value_mapping.grip_pairs_config.opposed', 'Opposed'),
        i18next.t(
          'configurator:component.value_mapping.grip_pairs_config.non_opposed',
          'Non-Opposed'
        )
      ];
      return gripPairsConfigNaming[index];
    },
    inputSite: () => i18next.t('configurator:component.value_mapping.input_site.value', 'Value'),
    inputDevice: () =>
      i18next.t('configurator:component.value_mapping.input_device.value', 'Value'),
    controlMode: () =>
      i18next.t('configurator:component.value_mapping.control_mode.value', 'Value'),
    speedControlStrategy: () =>
      i18next.t('configurator:component.value_mapping.speed_control_strategy.value', 'Value'),
    gripSwitchingMode: () =>
      i18next.t('configurator:component.value_mapping.grip_switching_mode.value', 'Value'),
    batteryBeep: (index) => {
      const batteryBeepNaming = [
        i18next.t(
          'configurator:component.value_mapping.battery_beep.alarm_trigger_level',
          'Alarm trigger level'
        ),
        i18next.t('configurator:component.value_mapping.battery_beep.status', 'Status')
      ];
      return batteryBeepNaming[index];
    },
    emgThresholds: (index) => {
      const thresholdsNamings = [
        i18next.t(
          'configurator:component.value_mapping.emg_thresholds.cs_extension_threshold',
          'CS extension threshold'
        ),
        i18next.t(
          'configurator:component.value_mapping.emg_thresholds.cs_flexion_threshold',
          'CS flexion threshold'
        ),
        i18next.t(
          'configurator:component.value_mapping.emg_thresholds.threshold_control_open_value',
          'Threshold control open value'
        ),
        i18next.t(
          'configurator:component.value_mapping.emg_thresholds.proportional_control_open_second_value',
          'Proportional control open second value'
        ),
        i18next.t(
          'configurator:component.value_mapping.emg_thresholds.proportional_control_open_third_value',
          'Proportional control open third value'
        ),
        i18next.t(
          'configurator:component.value_mapping.emg_thresholds.threshold_control_close_value',
          'Threshold control close value'
        ),
        i18next.t(
          'configurator:component.value_mapping.emg_thresholds.proportional_control_close_second_value',
          'Proportional control close second value'
        ),
        i18next.t(
          'configurator:component.value_mapping.emg_thresholds.proportional_control_close_third_value',
          'Proportional control close third value'
        ),
        i18next.t(
          'configurator:component.value_mapping.emg_thresholds.soft_grip_open_threshold_second_value',
          'Soft-grip open threshold second value'
        ),
        i18next.t(
          'configurator:component.value_mapping.emg_thresholds.soft_grip_close_threshold_second_value',
          'Soft-grip close threshold second value'
        )
      ];
      return thresholdsNamings[index];
    },
    fingerStrength: () =>
      i18next.t('configurator:component.value_mapping.finger_strength.value', 'Value'),
    autoGrasp: (index) => {
      const autoGraspNamings = [
        i18next.t('configurator:component.value_mapping.auto_grasp.status', 'Status'),
        i18next.t('configurator:component.value_mapping.auto_grasp.value', 'Value')
      ];
      return autoGraspNamings[index];
    },
    emgSpike: (index) => {
      const emgSpikeNamings = [
        i18next.t('configurator:component.value_mapping.emg_spike.status', 'Status'),
        i18next.t('configurator:component.value_mapping.emg_spike.value', 'Value')
      ];
      return emgSpikeNamings[index];
    },
    holdOpen: (index) => {
      const holdOpenNamings = [
        i18next.t(
          'configurator:component.value_mapping.hold_open.primary_hold_open_time',
          'Primary hold open time'
        ),
        i18next.t(
          'configurator:component.value_mapping.hold_open.secondary_hold_open_time',
          'Secondary hold open time'
        )
      ];
      return holdOpenNamings[index];
    },
    softGrip: () => i18next.t('configurator:component.value_mapping.soft_grip.status', 'Status'),
    emgGains: (index) => {
      const emgGainsNamings = [
        i18next.t(
          'configurator:component.value_mapping.emg_gains.open_signal_gain',
          'Open signal gain'
        ),
        i18next.t(
          'configurator:component.value_mapping.emg_gains.close_signal_gain',
          'Close signal gain'
        )
      ];
      return emgGainsNamings[index];
    },
    pulseTimings: (index) => {
      const pulseTimingsNamings = [
        i18next.t(
          'configurator:component.value_mapping.pulse_timings.min_pulse_time',
          'Min. pulse time'
        ),
        i18next.t(
          'configurator:component.value_mapping.pulse_timings.max_pulse_time',
          'Max. pulse time'
        ),
        i18next.t(
          'configurator:component.value_mapping.pulse_timings.min_time_between_pulses',
          'Min. time between pulses'
        ),
        i18next.t(
          'configurator:component.value_mapping.pulse_timings.max_time_between_pulses',
          'Max. time between pulses'
        )
      ];
      return pulseTimingsNamings[index];
    },
    generalHandSettings: (index) => {
      const generalHandSettingsNamings = [
        i18next.t(
          'configurator:component.value_mapping.general_hand_settings.hand_side',
          'Hand side'
        ),
        i18next.t(
          'configurator:component.value_mapping.general_hand_settings.thumb_length',
          'Thumb length'
        ),
        i18next.t(
          'configurator:component.value_mapping.general_hand_settings.wait_for_cs_before_startup',
          'Wait for CS before startup'
        )
      ];
      return generalHandSettingsNamings[index];
    },
    singleElectrodeMode: () =>
      i18next.t('configurator:component.value_mapping.single_electrode_mode.mode', 'Mode'),
    singleElectrodeModeSettings: (index) => {
      const settingsMapping = [
        i18next.t(
          'configurator:component.value_mapping.single_electrode_mode_settings.start_point_signal',
          'Start point signal'
        ),
        i18next.t(
          'configurator:component.value_mapping.single_electrode_mode_settings.window_time',
          'Window time'
        ),
        i18next.t(
          'configurator:component.value_mapping.single_electrode_mode_settings.short_hold_open',
          'Short hold open'
        ),
        i18next.t(
          'configurator:component.value_mapping.single_electrode_mode_settings.long_hold_open',
          'Long hold open'
        )
      ];
      return settingsMapping[index];
    },
    freezeModeEmg: (index) => {
      const freezeModeMapping = [
        i18next.t(
          'configurator:component.value_mapping.freeze_mode_emg.relaxation_closing',
          'Relaxation closing'
        ),
        i18next.t(
          'configurator:component.value_mapping.freeze_mode_emg.relaxation_opening',
          'Relaxation opening'
        ),
        i18next.t('configurator:component.value_mapping.freeze_mode_emg.status', 'Status')
      ];
      return freezeModeMapping[index];
    },
    coContractionTimings: (index) => {
      const coContractionNamings = [
        i18next.t(
          'configurator:component.value_mapping.co_contraction_timings.long_co_contraction_time',
          'Long co-contraction time'
        ),
        i18next.t(
          'configurator:component.value_mapping.co_contraction_timings.signal_rise_offset_time',
          'Signal rise offset time'
        )
      ];
      return coContractionNamings[index];
    },
    buzzingVolumeSettings: (index) => {
      const buzzingVolumeNamings = [
        i18next.t(
          'configurator:component.value_mapping.buzzing_volume_settings.buzzing_volume',
          'Buzzing volume'
        )
      ];
      return buzzingVolumeNamings[index];
    },
    followingGrip: (index) => {
      const followingGripNamings = [
        i18next.t('configurator:component.value_mapping.following_grip.status', 'Status')
      ];
      return followingGripNamings[index];
    },
    freezeModeEmgSettings: (index) => {
      const freezeModeEmgSettingsNamings = [
        i18next.t(
          'configurator:component.value_mapping.freeze_mode_emg_settings.threshold_opening',
          'Threshold opening'
        ),
        i18next.t(
          'configurator:component.value_mapping.freeze_mode_emg_settings.threshold_closing',
          'Threshold closing'
        ),
        i18next.t(
          'configurator:component.value_mapping.freeze_mode_emg_settings.stage_1_time',
          'Stage 1 time'
        ),
        i18next.t(
          'configurator:component.value_mapping.freeze_mode_emg_settings.stage_2_time',
          'Stage 2 time'
        )
      ];
      return freezeModeEmgSettingsNamings[index];
    },
    userFeedbackType: (index) => {
      const userFeedbackTypeNamings = [
        i18next.t('configurator:component.value_mapping.user_feedback_type.buzzer', 'Buzzer')
      ];
      return userFeedbackTypeNamings[index];
    },
    emergencyBatterySettings: (index) => {
      const emergencyBatterySettingsNamings = [
        i18next.t(
          'configurator:component.value_mapping.emergency_battery_settings.status',
          'Status'
        ),
        i18next.t(
          'configurator:component.value_mapping.emergency_battery_settings.unused',
          'UNUSED'
        ),
        i18next.t(
          'configurator:component.value_mapping.emergency_battery_settings.mode_trigger_level',
          'Mode trigger level'
        )
      ];
      return emergencyBatterySettingsNamings[index];
    },
    oneSpeed: (index) => {
      const oneSpeedNamings = [
        i18next.t('configurator:component.value_mapping.one_speed.fingers_speed', 'Fingers speed')
      ];
      return oneSpeedNamings[index];
    },
    singleElectrodeSettingsAlternating: (index: any) =>
      i18next.t(singleElectrodeSettingsAlternatingNamingsMap.get(index))
  },
  {
    get(obj: any, prop: any) {
      if (prop in obj) {
        return obj[prop];
      }
      return () => `Default ${String(prop)}`;
    }
  }
);
