import { useQuery } from '@tanstack/react-query';
import { getDevices } from 'adp-panel/api/devices/devices';
import { getDumRawFiles } from 'adp-panel/api/dumRawFiles/dumRawFiles';
import { DevicesQueryParams } from 'adp-panel/api/devices/device.types';
import { mapErrorMessage } from '../../utils/notifications';

export const DEVICES_WITH_DUMPS = 'devices_with_dumps';

export const useDevicesWithDumps = (params: DevicesQueryParams) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [DEVICES_WITH_DUMPS, params],
    async () => {
      const devicesResponse = await getDevices(params);
      const devicesWithDumps = await Promise.all(
        devicesResponse.items.map(async (device) => {
          const dumParams = { perpage: 1, order: 'asc' };
          const [manualDump, backgroundDump, fetchingDump] = await Promise.all([
            getDumRawFiles({ deviceId: device.id?.toString(), type: 'manual', ...dumParams }),
            getDumRawFiles({ deviceId: device.id?.toString(), type: 'background', ...dumParams }),
            getDumRawFiles({ deviceId: device.id?.toString(), type: 'fetching', ...dumParams })
          ]);
          console.log('fetchingDump', fetchingDump);
          return {
            ...device,
            dumps: {
              manual: manualDump.items?.[0]?.created_at || null,
              background: backgroundDump.items?.[0]?.created_at || null,
              fetching: fetchingDump.items?.[0]?.created_at || null
            }
          };
        })
      );

      return {
        items: devicesWithDumps,
        paginator: devicesResponse.paginator
      };
    },
    {
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data?.items || [],
    total: data?.paginator?.total || 0,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};
