export const playSoundElement = () => {
  const audio_file1: HTMLAudioElement | null = document.getElementById(
    'audioID'
  ) as HTMLAudioElement;

  if (!audio_file1) return;

  audio_file1.volume = 0.2;
  audio_file1.currentTime = 0;
  audio_file1.play();
};

export const pauseSoundElement = () => {
  const audio_file1: HTMLAudioElement | null = document.getElementById(
    'audioID'
  ) as HTMLAudioElement;

  if (!audio_file1) return;

  audio_file1.pause();
};

const AudioPlayer = () => (
  <audio
    id='audioID'
    src='https://aetherbiomedical-images.s3.amazonaws.com/soundEffect.wav'
    preload='auto'
  />
);
export default AudioPlayer;
