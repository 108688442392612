/* eslint-disable no-await-in-loop */
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { compareConfigs } from 'configurator/reducers/helpers/bluetoothHelpers';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useSettingsStore } from 'configurator/reducers/settingsStore';
import { useUiStore } from '../reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import DeviceConfigurationService from 'bluetooth/configController/configController';

const useUnsaved = () => {
  const { config, configCopy, setConfigCopy, configConflict } = useConfigStore((state) => ({
    config: state.config,
    configCopy: state.configCopy,
    setConfigCopy: state.setConfigCopy,
    configConflict: state.configConflict
  }));
  const { disconnectingState, synchronizingState, initialConfigState } = useUiStore((state) => ({
    disconnectingState: state.disconnectingState,
    synchronizingState: state.synchronizingState,
    initialConfigState: state.initialConfigState
  }));
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const demoMode = useSettingsStore((state) => state.demoMode);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [differences, setDifferences] = useState<any[]>([]);

  const areModesLoaded = config.common.configAPI && config.modes.every((mode) => mode.configAPI);

  useEffect(() => {
    if (areModesLoaded) {
      const localDifferences: any = [];

      // Check common config changes

      const beforeCommonConfig = config.common.configAPI;
      const afterCommonConfig = config.common.config;

      if (!config?.common?.config || !config?.common?.configAPI) return;

      const commonDifferences = compareConfigs(config.common.config, config.common.configAPI);

      if (!isEmpty(commonDifferences)) {
        localDifferences.push({
          slot: 'common',
          before: beforeCommonConfig,
          after: afterCommonConfig,
          changes: commonDifferences
        });
      }

      // Check all modes changes

      config.modes.forEach((mode) => {
        const beforeConfig = mode.configAPI;
        const afterConfig = mode.config;
        const modeDifference = compareConfigs(mode.config, mode.configAPI!);
        if (!isEmpty(modeDifference)) {
          localDifferences.push({
            id: mode.id,
            slot: mode.slot,
            before: beforeConfig,
            after: afterConfig,
            changes: modeDifference
          });
        }
      });

      if (localDifferences.length > 0) {
        setIsUnsaved(true);
        setDifferences(localDifferences);
      } else {
        setIsUnsaved(false);
      }
    }
  }, [config.common, config.modes]);

  const sendDifferencesDemo = async () => {
    const isLoading =
      disconnectingState === FETCHING_STATES.loading ||
      synchronizingState === FETCHING_STATES.loading ||
      initialConfigState === FETCHING_STATES.loading;

    if (!configCopy?.common?.config || !config?.common?.config) return;

    const commonDifference = compareConfigs(configCopy.common.config, config.common.config);

    const modesDifferences: any = [];
    config.modes.forEach((mode) => {
      const modeDifference = compareConfigs(
        configCopy.modes.find((_mode) => _mode.slot === mode.slot).config,
        mode.config
      );
      if (!isEmpty(modeDifference)) modesDifferences.push({ ...mode, difference: modeDifference });
    });

    const hasConfigChanged = !isEmpty(commonDifference) || modesDifferences.length > 0;

    if (hasConfigChanged && demoMode && deviceConnected && !isLoading && !configConflict) {
      setConfigCopy();
      await DeviceConfigurationService.sendAllConfig(
        { commonDifference, modes: modesDifferences },
        false
      );
    }
  };

  return { isUnsaved, sendDifferencesDemo, differences };
};

export default useUnsaved;
