import { Grips } from 'bluetooth/bluetoothCommunication/Grips';
import { defaultConfig } from 'configurator/consts/deviceConfig/deviceConfig';
import { DeviceConfigTemplate } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { ProcedureTypes } from 'bluetooth/bluetoothCommunication/Procedures';

export type BluetoothState = {
  device: {};
  config: DeviceConfigTemplate;
  trackedConfig: DeviceConfigTemplate | {};
  configCopy: DeviceConfigTemplate | {};
  gripsConfiguration: {
    currentGrip: Grips;
    handMovementAllowed: boolean;
  };
  telemetryInitiated: boolean;
  updateFilesReady: boolean;
  updateStatus: number;
  freezeMode: number[] | false;
  procedureReply: number[] | null;
  procedureUsedType: ProcedureTypes | null;
  maxParts: number;
  versions: {
    current: string | null;
    minimal: string | null;
    recent: string;
    numerical: number | null;
  };
  configConflict: boolean;
  firstConnection: boolean | null;
  modeSelected: number | null;
  supported: boolean;
  configHistory: any[];
  localConfigFetched: boolean;
  demoMode: boolean;
  pcbVersion: string | false;
  modes: number[];
};

export const initialState: BluetoothState = {
  device: {
    connected: false,
    serialNumber: ''
  },
  config: defaultConfig,
  trackedConfig: {},
  configCopy: {},
  gripsConfiguration: {
    currentGrip: Grips.kGripTypeUnknown,
    handMovementAllowed: false
  },
  telemetryInitiated: false,
  updateFilesReady: false,
  updateStatus: 0,
  freezeMode: [0],
  procedureReply: null,
  procedureUsedType: null,
  maxParts: 0,
  versions: {
    current: null,
    minimal: null,
    recent: '01.07.05',
    numerical: null
  },
  configConflict: false,
  firstConnection: null,
  modeSelected: null,
  supported: true,
  configHistory: [],
  localConfigFetched: false,
  demoMode: true,
  pcbVersion: false,
  modes: [1, 2, 3]
};
