import { Tab, Tabs } from '@mui/material';
import Card from 'adp-panel/components/Card/Card';
import { DeviceDropdownFilter } from 'adp-panel/components/DeviceUsageMonitoring/Filters/CustomGraphFilters';
import {
  TotalGripBreakdownGraph,
  TotalGripBreakdownGraphPie
} from 'adp-panel/components/DeviceUsageMonitoring/Graphs/GripBreakdownGraph';
import {
  GripCountGraph,
  GripCountGraphPie
} from 'adp-panel/components/DeviceUsageMonitoring/Graphs/GripCountGraph';
import {
  GripSwitchingGraph,
  GripSwitchingGraphPie
} from 'adp-panel/components/DeviceUsageMonitoring/Graphs/GripSwitching';
import { DeviceUsageProvider } from 'adp-panel/pages/DeviceUsage/DeviceUsageContext';
import { TabsContainer } from 'adp-panel/pages/Patients/styled/PatientTabs.styled';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartsWrapper } from '../Patients/styled';
import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import {
  EmgPeakChartQueryParams,
  EmgPeakPeriodEnum,
  GroupEnum,
  PeriodEnum,
  TimeSpentChartQueryParams,
  UsageMonitoryQueryParams,
  VelocityLevelChartQueryParams,
  VelocityLevelGroupEnum
} from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import UsageMonitoringGraph, {
  GraphFilters
} from '../../components/DeviceUsageMonitoring/UsageMonitoringGraph';
import {
  EmgPeakGraphNew,
  GripBreakdownGraphHour,
  GripCountHourlyGraph
} from 'adp-panel/components/DeviceUsageMonitoring/Graphs';
import useDeviceUsageTab, {
  filterParser,
  formatData
} from 'adp-panel/pages/DeviceUsage/useDeviceUsageTab';
import { FeatureToggle } from 'AppProviders';
import {
  DUM_EMG_PEAK_PLOT_WEB,
  DUM_TIME_SPENT_PLOT_WEB,
  DUM_VELOCITY_LEVELS_PLOT_WEB
} from 'adp-panel/constants/featureToggles';
import { useFeatureToggleIsEnabled } from 'adp-panel/hooks/useFeatureToggleIsEnabled';
import {
  useDeviceUsageChartData,
  useDeviceUsageGripsCountData,
  useDeviceUsageTimeSpentData,
  useEmgPeakChartData,
  useVelocityLevelChatData
} from 'adp-panel/hooks/api/useDeviceUseageMonitoring';
import fillDataGaps from 'adp-panel/components/DeviceUsageMonitoring/Mappers/ChartDataMapper';
import {
  VelocityLevelByHourGraph,
  VelocityLevelCloseGraph,
  VelocityLevelOpenGraph
} from 'adp-panel/components/DeviceUsageMonitoring/Graphs/VelocityLevelGraph';
import velocityLevelMapperByHours, {
  velocityLevelCloseMapper,
  velocityLevelOpenMapper
} from 'adp-panel/components/DeviceUsageMonitoring/Mappers/VelocityLevelDataMapper';
import { OneColumnGraphLayout, TwoColumnGraphLayout } from 'adp-panel/pages/DeviceUsage/styled';
import { TimeSpentGraphWithCustomLegend } from '../../components/DeviceUsageMonitoring/Graphs/TimeSpentGraph';
import dayjs from 'dayjs';
import { CustomTabPanel } from 'components/TabPanel/CustomTabPanel';
import i18n from 'i18n';
import { useGripTimeMapper } from 'adp-panel/components/DeviceUsageMonitoring/Mappers/GripTimeDataMapper';

const mapTimeSpentData = (timeSpentData: any) => {
  return [
    {
      id: 'Active Time',
      label: i18n.t('devices:acitve_time', 'Active Time'),
      color: 'hsl(219, 96%, 57%)',
      Time: timeSpentData?.active_time ? timeSpentData?.active_time : 0,
      Percentage: parseFloat(timeSpentData?.percentage_active_time).toFixed(2),
      description: i18n.t(
        'devices:active_time_desc',
        'Periods where EMG signals are produced, indicating muscle activity. If signals are detected within 300 seconds of each other, the system continuously counts this as active time, allowing for brief pauses without resetting the activity count.'
      )
    },
    {
      id: 'Passive Time',
      label: i18n.t('devices:passive_time', 'Passive Time'),
      color: 'hsl(210, 10%, 91%)',
      Time: timeSpentData?.passive_time ? timeSpentData?.passive_time : 0,
      Percentage: parseFloat(timeSpentData?.percentage_passive_time).toFixed(2),
      description: i18n.t(
        'passive_time_desc',
        "When no EMG signals are detected for a duration exceeding the 5 minutes threshold, it's marked as inactive time. Brief, isolated signals after long pauses require confirmation (multiple signals) to be considered active again."
      )
    }
  ];
};

const legendMap = new Map<string, string>([
  ['Instance', 'Number of grip switches performed'],
  ['Percentage', 'Percentage of grip switches [%]'],
  ['Time', 'Time spent in a grip [min]']
]);

const legendMapForGripCountText = new Map<string, string>([
  ['Instance', 'Total grip count'],
  ['Percentage', 'Percentage count [%]'],
  ['Time', 'Total minutes spent in all grips']
]);

const legendMapCount = new Map<string, string>([
  ['Instance', 'Number of grips performed'],
  ['Percentage', 'Percentage of grips performed [%]'],
  ['Time', '']
]);

const DeviceUsageTabContent = ({ devices }: { devices: DeviceEntry[] }) => {
  const timeSpentGraphIsEnabled = useFeatureToggleIsEnabled(DUM_TIME_SPENT_PLOT_WEB);
  const [selectedDevice, setSelectedDevice] = useState<DeviceEntry>(devices[0]);
  const { mapTotalGripData, mapGripTimeData, mapHourlyGripData } = useGripTimeMapper(
    selectedDevice.id
  );
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);

  const {
    chartData: velocityLevelByHours,
    handleFilterChange: handleVelocityLevelByHoursFilter,
    isLoading: velocityLevelByHoursLoading
  } = useDeviceUsageTab<VelocityLevelChartQueryParams>(
    useVelocityLevelChatData,
    {
      date_from: formatData(new Date()),
      group: VelocityLevelGroupEnum.hourly
    },
    filterParser.velocity
  );

  const {
    chartData: velocityLevel,
    handleFilterChange: handleVelocityLevelFilter,
    isLoading: velocityLevelLoading
  } = useDeviceUsageTab<VelocityLevelChartQueryParams>(
    useVelocityLevelChatData,
    {
      date_from: formatData(new Date()),
      group: VelocityLevelGroupEnum.none
    },
    filterParser.velocity
  );

  const {
    chartData: totalGripCountData,
    handleFilterChange: handleTotalGripCountFilter,
    isLoading: totalGripCountDataLoading
  } = useDeviceUsageTab<UsageMonitoryQueryParams>(useDeviceUsageGripsCountData);

  const {
    chartData: hourlyGripData,
    handleFilterChange: handleHourlyGripFilter,
    isLoading: hourlyGripLoading
  } = useDeviceUsageTab<UsageMonitoryQueryParams>(
    useDeviceUsageChartData,
    {
      group: GroupEnum.hourly
    },
    filterParser.hourly
  );

  const {
    chartData: hourlyGripCountData,
    handleFilterChange: handleHourlyGripCountFilter,
    isLoading: hourlyGripCountLoading
  } = useDeviceUsageTab<UsageMonitoryQueryParams>(
    useDeviceUsageGripsCountData,
    {
      group: GroupEnum.hourly
    },
    filterParser.hourly
  );

  const {
    chartData: totalGripData,
    handleFilterChange: handleTotalGripFilter,
    isLoading: totalGripLoading
  } = useDeviceUsageTab<UsageMonitoryQueryParams>(useDeviceUsageChartData);

  const {
    chartData: totalGripTimeData,
    handleFilterChange: handleTotalGripTimeFilter,
    isLoading: totalGripTimeLoading
  } = useDeviceUsageTab<UsageMonitoryQueryParams>(useDeviceUsageChartData);

  //EMG peaks
  const {
    chartData: emgPeaksData,
    handleFilterChange: handleEmgPeakFilter,
    isLoading: emgPeaksLoading
  } = useDeviceUsageTab<EmgPeakChartQueryParams>(
    useEmgPeakChartData,
    { date_from: formatData(new Date()), period: EmgPeakPeriodEnum.all },
    filterParser.emgPeak
  );

  //Time spent
  const {
    chartData: timeSpentData,
    handleFilterChange: handleTimeSpentFilter,
    isLoading: timeSpentLoading
  } = useDeviceUsageTab<TimeSpentChartQueryParams>(useDeviceUsageTimeSpentData);

  const handleVelocityFilter = (filters: GraphFilters) => {
    handleVelocityLevelByHoursFilter(filters);
    handleVelocityLevelFilter(filters);
  };

  const handleSelect = (_: any, tabIndex: any) => {
    setSelected(tabIndex);
  };

  const todayDate = dayjs();
  const initialDataRangeFilter = { end: new Date(), start: dayjs().subtract(7, 'day').toDate() };
  return (
    <ChartsWrapper>
      <DeviceDropdownFilter
        devices={devices ?? []}
        selectedDevice={selectedDevice}
        setDevice={setSelectedDevice}
        style={{ width: '100%' }}
      />
      <UsageMonitoringGraph
        header={t('dum:active_passive_time_plot_header', 'Active and passive time plot')}
        chartType='active-passive-time'
        totalCounter={false}
        devices={devices ?? []}
        isLoading={timeSpentLoading}
        selectedDevice={selectedDevice}
        initialFilters={{
          instance: 'Time',
          period: PeriodEnum.week,
          dateRange: initialDataRangeFilter
        }}
        instancesOptions={[
          { text: 'Time', id: ['Time'] },
          { text: 'Percentage', id: ['Percentage'] }
        ]}
        onFilterChange={handleTimeSpentFilter}
        instancesFilter
        dateRangeFilter
        additionalFilterProps={{
          dateRange: {
            max: todayDate.toDate()
          }
        }}
        legendMap={
          new Map<string, string>([
            ['Time', ' Hours'],
            ['Percentage', '%']
          ])
        }
        graphDataSource={mapTimeSpentData(timeSpentData) ?? []}
        graphHeight='400px'
        GraphComponent={TimeSpentGraphWithCustomLegend}
      />
      <UsageMonitoringGraph
        header={t('dum:grip_count_header', 'Grip count')}
        chartType='grip-count'
        isLoading={totalGripCountDataLoading}
        gripCountText={legendMapForGripCountText}
        devices={devices ?? []}
        selectedDevice={selectedDevice}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.week,
          dateRange: initialDataRangeFilter,
          graphType: 'column'
        }}
        instancesOptions={[
          { text: 'Instance', id: ['Grip count'] },
          { text: 'Percentage', id: ['Percentage'] }
        ]}
        onFilterChange={handleTotalGripCountFilter}
        instancesFilter
        dateRangeFilter
        graphTypeFilter
        legendMap={legendMapCount}
        graphDataSource={mapTotalGripData(totalGripCountData ?? [])}
        renderGraph={(keys, data, yLabel, userTimezone, formState) => {
          if (formState.graphType.id === 'pie') {
            return (
              <GripCountGraphPie
                data={mapGripTimeData(totalGripCountData ?? [])}
                yLabel={yLabel}
                userTimezone={userTimezone}
                keys={keys}
              />
            );
          }
          if (formState.date)
            return (
              <GripCountGraph
                data={mapTotalGripData(totalGripCountData ?? [])}
                yLabel={yLabel}
                userTimezone={userTimezone}
                keys={keys}
              />
            );
        }}
        graphHeight='400px'
      />
      <UsageMonitoringGraph
        header='Grip count by hour'
        chartType='grip-count-hour'
        gripCountText={legendMapForGripCountText}
        devices={devices ?? []}
        isLoading={hourlyGripCountLoading}
        selectedDevice={selectedDevice}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.week,
          dateRange: initialDataRangeFilter,
          grip: { text: 'All', id: 'all' }
        }}
        instancesOptions={[{ text: 'Instance', id: ['Grip count'] }]}
        onFilterChange={handleHourlyGripCountFilter}
        gripsFilter
        dateRangeFilter
        legendMap={legendMapCount}
        graphHeight='400px'
        graphDataSource={mapHourlyGripData(hourlyGripCountData ?? [])}
        GraphComponent={GripCountHourlyGraph}
      />
      <UsageMonitoringGraph
        header='Grip switching count'
        chartType='grip-switching'
        gripCountText={
          new Map<string, string>([
            [
              'Instance',
              t('devices:device_usage.tab.gip_switching_count', 'Total grip switching count')
            ],
            [
              'Percentage',
              t('devices:device_usage.tab.gip_percentage_count', 'Percentage count [%]')
            ],
            [
              'Time',
              t('devices:device_usage.tab.gip_total_time', 'Total minutes spent in all grips')
            ]
          ])
        }
        devices={devices ?? []}
        selectedDevice={selectedDevice}
        isLoading={totalGripLoading}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.week,
          dateRange: initialDataRangeFilter
        }}
        {...(timeSpentGraphIsEnabled && {
          instancesOptions: [
            { text: 'Instance', id: ['Grip switches'] },
            { text: 'Percentage', id: ['Percentage'] }
          ]
        })}
        onFilterChange={handleTotalGripFilter}
        instancesFilter
        dateRangeFilter
        graphTypeFilter
        graphHeight='400px'
        legendMap={legendMap}
        graphDataSource={mapTotalGripData(totalGripData ?? [])}
        renderGraph={(keys, data, yLabel, userTimezone, formState) => {
          if (formState.graphType.id === 'pie') {
            return (
              <GripSwitchingGraphPie
                data={mapGripTimeData(totalGripData ?? [])}
                yLabel={yLabel}
                userTimezone={userTimezone}
                keys={keys}
              />
            );
          }
          return (
            <GripSwitchingGraph
              data={mapTotalGripData(totalGripData ?? [])}
              yLabel={yLabel}
              userTimezone={userTimezone}
              keys={keys}
            />
          );
        }}
      />
      <UsageMonitoringGraph
        header='Grip switching breakdown by hour'
        chartType='grip-switching-hour'
        gripCountText={
          new Map<string, string>([
            [
              'Instance',
              t('devices:device_usage.tab.gip_switching_count', 'Total grip switching count')
            ],
            [
              'Percentage',
              t('devices:device_usage.tab.gip_percentage_count', 'Percentage count [%]')
            ],
            [
              'Time',
              t('devices:device_usage.tab.gip_total_time', 'Total minutes spent in all grips')
            ]
          ])
        }
        devices={devices ?? []}
        selectedDevice={selectedDevice}
        isLoading={hourlyGripLoading}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.week,
          dateRange: initialDataRangeFilter,
          grip: { text: 'All', id: 'all' }
        }}
        instancesOptions={[{ text: 'Instance', id: ['Grip switches'] }]}
        onFilterChange={handleHourlyGripFilter}
        gripsFilter
        dateRangeFilter
        legendMap={legendMap}
        graphHeight='400px'
        graphDataSource={mapHourlyGripData(hourlyGripData ?? [])}
        GraphComponent={GripBreakdownGraphHour}
      />
      <FeatureToggle name={DUM_TIME_SPENT_PLOT_WEB}>
        <UsageMonitoringGraph
          header='Time spent in a grip'
          chartType='time-spent'
          gripCountText={
            new Map<string, string>([
              [
                'Instance',
                t('devices:device_usage.tab.gip_total_time', 'Total minutes spent in all grips')
              ],
              [
                'Percentage',
                t('devices:device_usage.tab.gip_total_time', 'Total minutes spent in all grips')
              ],
              [
                'Time',
                t('devices:device_usage.tab.gip_total_time', 'Total minutes spent in all grips')
              ]
            ])
          }
          devices={devices ?? []}
          selectedDevice={selectedDevice}
          isLoading={totalGripTimeLoading}
          initialFilters={{
            instance: 'Time',
            period: PeriodEnum.week,
            dateRange: initialDataRangeFilter
          }}
          onFilterChange={handleTotalGripTimeFilter}
          graphHeight='400px'
          dateRangeFilter
          graphTypeFilter
          legendMap={new Map<string, string>([['Time', 'Time [min]']])}
          graphDataSource={mapTotalGripData(totalGripTimeData ?? [])}
          renderGraph={(keys, data, yLabel, userTimezone, formState) => {
            if (formState.graphType.id === 'pie') {
              return (
                <TotalGripBreakdownGraphPie
                  data={mapGripTimeData(totalGripTimeData ?? [])}
                  yLabel={yLabel}
                  userTimezone={userTimezone}
                  keys={keys}
                />
              );
            }
            return (
              <TotalGripBreakdownGraph
                data={mapTotalGripData(totalGripTimeData ?? [])}
                yLabel={yLabel}
                userTimezone={userTimezone}
                keys={keys}
              />
            );
          }}
        />
      </FeatureToggle>
      <FeatureToggle name={DUM_EMG_PEAK_PLOT_WEB}>
        <UsageMonitoringGraph
          header='Activity density'
          chartType='activity-density'
          gripCountText={
            new Map<string, string>([
              ['Instance', t('devices:device_usage.usage_monitor_graph_grip_count', 'EMG peaks')]
            ])
          }
          legendMap={
            new Map<string, string>([
              ['EMG', t('devices:device_usage.usage_monitor_graph_legend_map', 'Peak value')]
            ])
          }
          devices={devices ?? []}
          selectedDevice={selectedDevice}
          isLoading={emgPeaksLoading}
          initialFilters={{
            instance: 'Instance',
            period: PeriodEnum.week,
            dateRange: initialDataRangeFilter,
            grip: { text: 'All', id: 'all' }
          }}
          instancesOptions={[{ text: 'Instance', id: ['count'] }]}
          dateRangeFilter
          graphHeight='400px'
          onFilterChange={handleEmgPeakFilter}
          graphDataSource={fillDataGaps(emgPeaksData ?? [])}
          GraphComponent={EmgPeakGraphNew}
        />
      </FeatureToggle>
      <FeatureToggle name={DUM_VELOCITY_LEVELS_PLOT_WEB}>
        <UsageMonitoringGraph
          header='Velocity'
          chartType='velocity'
          devices={devices ?? []}
          selectedDevice={selectedDevice}
          isLoading={velocityLevelByHoursLoading || velocityLevelLoading}
          totalCounter={false}
          graphHeight={'1100px'}
          initialFilters={{
            grip: { text: 'All', id: 'all' },
            instance: 'Velocity',
            period: PeriodEnum.week,
            dateRange: initialDataRangeFilter,
            group: VelocityLevelGroupEnum.hourly
          }}
          instancesOptions={[{ text: 'Velocity', id: ['Speed 1', 'Speed 2', 'Speed 3'] }]}
          onFilterChange={handleVelocityFilter}
          legendMap={legendMapCount}
          gripsFilter
          dateRangeFilter
          graphDataSource={velocityLevelMapperByHours(velocityLevelByHours ?? [])}
          renderGraph={(keys, data, yLabel, userTimezone) => {
            return (
              <>
                <Card>
                  <TabsContainer>
                    <Tabs value={selected} onChange={handleSelect}>
                      <Tab
                        label={t('common:component.graph.graph_tab.velocity_open', 'Open')}
                        id='velocity-tabp-0'
                        aria-controls='velocity-tabpanel-0'
                      />

                      <Tab
                        label={t('common:component.graph.graph_tab.velocity_close', 'Close')}
                        id='velocity-tabp-1'
                        aria-controls='velocity-tabpanel-1'
                      />
                    </Tabs>
                    <CustomTabPanel value={selected} index={0}>
                      <OneColumnGraphLayout>
                        <VelocityLevelByHourGraph
                          keys={keys}
                          yLabel={yLabel}
                          data={velocityLevelMapperByHours(velocityLevelByHours ?? []).open}
                          userTimezone={userTimezone}
                        />
                      </OneColumnGraphLayout>
                    </CustomTabPanel>
                    <CustomTabPanel value={selected} index={1}>
                      <OneColumnGraphLayout>
                        <VelocityLevelByHourGraph
                          keys={keys}
                          yLabel={yLabel}
                          userTimezone={userTimezone}
                          data={velocityLevelMapperByHours(velocityLevelByHours ?? []).close}
                        />
                      </OneColumnGraphLayout>
                    </CustomTabPanel>
                  </TabsContainer>
                </Card>
                <TwoColumnGraphLayout>
                  <VelocityLevelOpenGraph
                    yLabel={yLabel}
                    userTimezone={userTimezone}
                    keys={keys}
                    data={velocityLevelOpenMapper(velocityLevel ?? [])}
                  />
                  <VelocityLevelCloseGraph
                    keys={keys}
                    userTimezone={userTimezone}
                    data={velocityLevelCloseMapper(velocityLevel ?? [])}
                  />
                </TwoColumnGraphLayout>
              </>
            );
          }}
          GraphComponent={VelocityLevelByHourGraph}
        />
      </FeatureToggle>
    </ChartsWrapper>
  );
};

const DeviceUsageTab = ({ devices }: { devices: DeviceEntry[] }) => {
  return (
    <DeviceUsageProvider devices={devices}>
      <DeviceUsageTabContent devices={devices} />
    </DeviceUsageProvider>
  );
};

export default DeviceUsageTab;
