/* eslint-disable no-async-promise-executor */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
import { create } from 'zustand';
import { produce } from 'immer';
import { toast } from 'react-hot-toast';
import { isEmpty, uniqueId } from 'lodash';
import { devtools, persist } from 'zustand/middleware';
import { Grips } from 'bluetooth/bluetoothCommunication/Grips';
import {
  commonConfigProperties,
  defaultCommonConfig,
  defaultConfig,
  defaultModeConfig,
  modeConfigProperties
} from 'configurator/consts/deviceConfig/deviceConfig';
import {
  DeviceConfigTemplate,
  ModeConfigTemplate,
  CommonConfigTemplate
} from 'configurator/consts/deviceConfig/deviceConfig.types';
import { ProcedureTypes } from 'bluetooth/bluetoothCommunication/Procedures';
import {
  getDevicesInfo,
  postAppReceivedProcedure,
  runProcedure
} from 'bluetooth/bluetoothFunctions';
import { CalibrationEvents } from 'configurator/utils/LiveConfigurator/events';
import { timeoutCommandCustom } from 'configurator/utils/funcs';
import { listenAblyReply } from 'configurator/utils/LiveConfigurator/AblyHandlers';
import { ablyClient } from 'configurator/utils/LiveConfigurator/AblyClient';
import { Commands } from 'bluetooth/bluetoothCommunication/Defines';
import {
  CALIBRATION_PROCEDURE_TIMEOUT,
  FETCHING_STATES,
  HISTORY_EVENTS
} from 'configurator/consts/consts';
import { EMG_SPIKE_WARNING, SINGLE_ALTERNATING_TIMINGS } from 'configurator/consts/notifications';
import { useDeviceInfoStore } from './deviceInfoStore';
import { compareConfigs, testClosingProcedure } from './helpers/bluetoothHelpers';
import { useUiStore } from './uiStore';
import {
  controlConfigModifier,
  freezeModeEmgModifier,
  singleElectrodeModeModifier,
  singleElectrodeModeSettingsModifier,
  speedControlStrategyModifier,
  freezeModeValue,
  emergencyBatterySettingsAfterModifier,
  inputDeviceAfterModifier,
  singleElectrodeModeAfterModifier,
  batteryBeepAfterModifier,
  singleElectrodeSettingsAlternatingAfterModifier,
  emgThresholdsModifier,
  freezeModeEmgSettingsModifier
} from './helpers/configModifiers';
import { getCurrentConfigSelector, getFwVersionSelector } from './helpers/selectors';
import { EVENTS } from 'configurator/consts/events';
import i18next from 'i18next';
import { BLOCK_MODALS } from 'configurator/consts/blockModals';
import { NotificationFactory } from 'lib/NotificationFactory';

const undoChannel = new BroadcastChannel('undo');

export type SetConfigPropertyType = <T extends keyof DeviceConfigTemplate>(
  property: T,
  value: DeviceConfigTemplate[T]
) => void;

type ModeType = {
  config: ModeConfigTemplate;
  configAPI: ModeConfigTemplate | null;
  slot: number;
  name: string;
  id: number | null;
  active: 0 | 1 | 2 | undefined;
};

export type ConfigType = {
  common: {
    config: CommonConfigTemplate;
    configAPI: CommonConfigTemplate | null;
  };
  modes: ModeType[];
};

export type ConfigStoreState = {
  config: ConfigType;
  currentGrip: Grips;
  handMovementAllowed: boolean;
  procedureReply: number[] | null;
  procedureUsedType: ProcedureTypes | null;
  currentlyRunningProcedure: ProcedureTypes | null;
  configConflict: boolean;
  slotSelected: number;
  configHistory: HistoryEntryType[];
  configCopy: any;
  localConfigFetched: boolean;
  firstConnection: boolean | null;
  initialConfigState: FETCHING_STATES;
  commonPropertiesAPI: Array<keyof DeviceConfigTemplate> | null;
  modePropertiesAPI: Array<keyof DeviceConfigTemplate> | null;
  sendingQueue: Array<any> | [];
  setItemConfigStore: <T extends keyof ConfigStoreState>(
    property: T,
    value: ConfigStoreState[T]
  ) => void;
  addConfigHistory: (event: HISTORY_EVENTS, previousState) => void;
  setConfigCopy: () => void;
  setConfigProperty: SetConfigPropertyType;
  setControlConfig: (newControlConfig) => void;
  importConfig: ({
    common,
    modes,
    importToApiConfig
  }: {
    common: any;
    modes: { slot: number; config: any }[] | { id: number; config: any }[] | null;
    importToApiConfig?: boolean;
  }) => void;
  importBackupConfig: ({ config }: { config: any }) => void;
  handleProcedure: ({
    procedureNumber,
    liveSession,
    preventInput,
    showPreventInputMessage
  }: {
    procedureNumber: ProcedureTypes;
    liveSession?: liveSessionProps;
    preventInput?: boolean;
    showPreventInputMessage?: boolean;
  }) => Promise<any>;
  resetGripPositions: (grip: Grips) => void;
  consumeHistory: (idOrEvent: HISTORY_EVENTS | number) => void;
  clearConfigHistory: () => void;
  addSendingQueue: (sendObject: any) => number;
  removeSendingQueue: () => void;
};

type liveSessionProps = {
  clinicianUUID: string | undefined;
  channelName: string;
};

type HistoryEntryType = {
  event: HISTORY_EVENTS;
  id: any;
  timestamp: any;
  fromSlot: number;
  diffConfig: {
    common: { after: any; before: any };
    modes: { id: number; slot: number; name: string; after: any; before: any }[];
  };
};

const initialStateConfigStore = {
  config: {
    common: { config: defaultCommonConfig, configAPI: null },
    modes: [
      {
        config: defaultModeConfig,
        configAPI: null,
        slot: 0,
        name: 'Default',
        id: null,
        active: 1 as 0 | 1 | 2 | undefined
      }
    ]
  },
  commonPropertiesAPI: null,
  modePropertiesAPI: null,
  sendingQueue: [],
  currentGrip: Grips.kGripTypeUnknown,
  handMovementAllowed: false,
  procedureReply: null,
  procedureUsedType: null,
  currentlyRunningProcedure: null,
  configConflict: false,
  slotSelected: 0,
  configHistory: [],
  configCopy: {},
  localConfigFetched: false,
  firstConnection: null,
  initialConfigState: FETCHING_STATES.idle
};

export const store = (set, get): ConfigStoreState => ({
  ...initialStateConfigStore,
  setItemConfigStore: <T extends keyof ConfigStoreState>(property: T, value: ConfigStoreState[T]) =>
    set({ [`${property}`]: value }),
  setConfigCopy: () =>
    set(
      (state) => ({
        configCopy: state.config
      }),
      false,
      { type: 'configCopy' }
    ),
  addConfigHistory: (event: HISTORY_EVENTS, previousState) => {
    const state = { ...get().config };
    const { slotSelected } = get();

    const commonDifference = {
      before: compareConfigs(state.common.config, previousState.common.config),
      after: compareConfigs(previousState.common.config, state.common.config)
    };

    const modesDifferences: any = [];
    state.modes.forEach((mode) => {
      const previousMode = previousState.modes.find((_mode) => _mode.slot === mode.slot);
      const modeDifference = compareConfigs(mode.config, previousMode.config);
      if (!isEmpty(modeDifference))
        modesDifferences.push({
          id: mode.id,
          slot: mode.slot,
          name: mode.name,
          before: modeDifference,
          after: compareConfigs(previousMode.config, mode.config)
        });
    });

    if (modesDifferences.length === 0 && isEmpty(commonDifference.before)) return;

    const newHistoryEntry: HistoryEntryType = {
      event,
      id: Number(uniqueId()),
      timestamp: Date.now(),
      fromSlot: slotSelected,
      diffConfig: {
        common: commonDifference,
        modes: modesDifferences
      }
    };

    set((state: ConfigStoreState) => ({
      configHistory: [...state.configHistory, newHistoryEntry]
    }));
  },
  setConfigProperty: <T extends keyof DeviceConfigTemplate>(
    property: T,
    value: DeviceConfigTemplate[T]
  ) => {
    const prevState: ConfigStoreState = { ...get() };
    const { commonPropertiesAPI, modePropertiesAPI } = get();
    const currentConfig = getCurrentConfigSelector(prevState);
    let configsAffected = {};

    // @ts-ignore
    switch (property) {
      case 'emgThresholds': {
        const adjustedConfig = emgThresholdsModifier(
          { ...currentConfig, emgThresholds: value },
          configsAffected
        );
        configsAffected = adjustedConfig;
        break;
      }
      case 'freezeModeEmg': {
        const deviceInfoState = useDeviceInfoStore.getState();
        const fwVersion = getFwVersionSelector();
        const { adjustedConfig, triggered } = freezeModeEmgModifier(
          value as freezeModeValue,
          currentConfig as DeviceConfigTemplate,
          fwVersion,
          configsAffected
        );
        configsAffected = adjustedConfig;
        if (triggered)
          NotificationFactory.warningNotification(
            i18next.t(
              'configurator:config_store.notification.freeze_mode_emg_warning',
              'Freeze mode relaxation thresholds must be lower than EMG change signal, activation and freeze mode settings thresholds'
            ),
            '',
            {
              id: 'freezeModeEmgRelaxationWarning',
              duration: 10000
            }
          );
        break;
      }
      case 'freezeModeEmgSettings': {
        const { adjustedConfig, triggered } = freezeModeEmgSettingsModifier(
          value as freezeModeValue,
          currentConfig as DeviceConfigTemplate,
          configsAffected
        );
        configsAffected = adjustedConfig;
        if (triggered)
          NotificationFactory.warningNotification(
            i18next.t(
              'configurator:config_store.notification.freeze_mode_emg_settings_warning',
              'Freeze mode thresholds must be higher than relaxation thresholds'
            ),
            '',
            {
              id: 'freezeModeEmgRelaxationWarning',
              duration: 10000
            }
          );
        break;
      }
      case 'singleElectrodeMode': {
        const currentConfig = getCurrentConfigSelector(prevState);
        configsAffected = singleElectrodeModeModifier(value, currentConfig, configsAffected);
        break;
      }
      case 'singleElectrodeModeSettings': {
        const currentConfig = getCurrentConfigSelector(prevState);
        const fwVersion = getFwVersionSelector();
        const { adjustedConfig, triggered } = singleElectrodeModeSettingsModifier(
          value,
          currentConfig,
          configsAffected,
          fwVersion
        );
        configsAffected = adjustedConfig;
        if (triggered)
          NotificationFactory.warningNotification(
            i18next.t(
              'configurator:config_store.notification.single_electrode_mode_settings_warning',
              'Start point signal threshold must be lower than change signal and activation open thresholds'
            ),
            '',
            {
              id: 'startPointWarning',
              duration: 10000
            }
          );
        break;
      }
      case 'speedControlStrategy': {
        const currentConfig = getCurrentConfigSelector(prevState);
        configsAffected = speedControlStrategyModifier(value, currentConfig, configsAffected);
        break;
      }
      default:
        configsAffected = {
          [`${property}`]: value
        };
        break;
    }

    let newCommon = prevState.config.common;
    let newModes = prevState.config.modes;

    const commonProperties = commonPropertiesAPI || commonConfigProperties;
    const modeProperties = modePropertiesAPI || modeConfigProperties;

    Object.keys(configsAffected).forEach((_property) => {
      // @ts-ignore
      if (commonProperties.includes(_property)) {
        newCommon = {
          ...newCommon,
          config: {
            ...newCommon.config,
            ...configsAffected
          }
        };
      }
      // @ts-ignore
      if (modeProperties.includes(_property)) {
        newModes = newModes.map((mode) => {
          if (mode.slot !== prevState.slotSelected) return mode;
          return {
            ...mode,
            config: {
              ...mode.config,
              ...configsAffected
            }
          };
        });
      }
    });

    // Create config with initial changes
    const newState: ConfigStoreState = {
      ...prevState,
      config: { common: newCommon, modes: newModes }
    };

    // Check if global changes are needed based on new config
    let newModesModified = null;
    switch (property) {
      case 'batteryBeep': {
        const currentConfig = getCurrentConfigSelector(newState);
        const localConfigSupported = getFwVersionSelector() >= 20200;
        const { newConfigModesSettings, triggered } = batteryBeepAfterModifier(
          currentConfig as DeviceConfigTemplate,
          newState,
          localConfigSupported ? [newState.slotSelected] : null
        );
        newModesModified = newConfigModesSettings;

        if (triggered.find((element) => element.configName === 'batteryBeep')) {
          NotificationFactory.errorNotification(
            i18next.t(
              'configurator:config_store.notification.battery_beep_warning',
              'Battery beep needs to be at least 6% higher than Emergency mode value, Battery beep was automatically adjusted'
            ),
            '',
            {
              id: 'batteryBeepWarning'
            }
          );
        }
        break;
      }
      case 'emergencyBatterySettings': {
        const currentConfig = getCurrentConfigSelector(newState);
        const { newConfigModesSettings, triggered } = emergencyBatterySettingsAfterModifier(
          currentConfig as DeviceConfigTemplate,
          newState
        );
        newModesModified = newConfigModesSettings;

        if (triggered.find((element) => element.configName === 'batteryBeep')) {
          NotificationFactory.errorNotification(
            i18next.t(
              'configurator:config_store.notification.battery_beep_warning',
              'Battery beep needs to be at least 6% higher than Emergency mode value, Battery beep was automatically adjusted'
            ),
            '',
            {
              id: 'batteryBeepWarning'
            }
          );
        }
        break;
      }
      case 'inputDevice': {
        const currentConfig = getCurrentConfigSelector(newState);
        const localConfigSupported = getFwVersionSelector() >= 20200;
        const { newConfigModesSettings } = inputDeviceAfterModifier(
          currentConfig as DeviceConfigTemplate,
          newState,
          localConfigSupported ? [newState.slotSelected] : null
        );
        newModesModified = newConfigModesSettings;
        break;
      }
      case 'singleElectrodeMode': {
        const currentConfig = getCurrentConfigSelector(newState);
        const localConfigSupported = getFwVersionSelector() >= 20200;
        const { newConfigModesSettings, triggered } = singleElectrodeModeAfterModifier(
          currentConfig as DeviceConfigTemplate,
          newState,
          localConfigSupported ? [newState.slotSelected] : null
        );
        newModesModified = newConfigModesSettings;

        if (triggered.find((element) => element.configName === 'emgSpike')) {
          NotificationFactory.errorNotification(
            i18next.t(EMG_SPIKE_WARNING.translationKey, EMG_SPIKE_WARNING.translationOptions),
            '',
            EMG_SPIKE_WARNING.options
          );
        }
        break;
      }
      case 'singleElectrodeSettingsAlternating': {
        const { newConfigModesSettings, triggered } =
          singleElectrodeSettingsAlternatingAfterModifier(newState, [newState.slotSelected]);
        newModesModified = newConfigModesSettings;

        if (
          triggered.find((element) => element.configName === 'singleElectrodeSettingsAlternating')
        ) {
          NotificationFactory.errorNotification(
            i18next.t(
              SINGLE_ALTERNATING_TIMINGS.translationKey,
              SINGLE_ALTERNATING_TIMINGS.translationOptions
            ),
            '',
            SINGLE_ALTERNATING_TIMINGS.options
          );
        }
        break;
      }
      default:
        break;
    }

    set(
      {
        config: {
          common: newState.config.common,
          modes: newModesModified || newState.config.modes
        }
      },
      false,
      {
        type: 'setConfigProperty',
        configsAffected,
        property,
        value
      }
    );

    // Return state previous to changes done in setConfigProperty, to keep track of changes history
    return prevState.config;
  },
  setControlConfig: (newControlConfig) => {
    const prevState = { ...get() };

    const { firmware, versions } = useDeviceInfoStore.getState();
    const currentConfig = getCurrentConfigSelector(prevState);

    const newInputSite = newControlConfig[0];
    const newGripSwitchingMode = newControlConfig[3];
    const oldInputSite = currentConfig.inputSite![0];
    controlConfigModifier({
      newInputSite,
      newGripSwitchingMode,
      oldInputSite,
      prevState,
      firmware,
      versions,
      set
    });

    return prevState.config;
  },
  importBackupConfig: async ({ config }) => {
    const { common, modes } = config;

    set(
      produce((state: ConfigStoreState) => {
        state.config.common.config = common;
        state.config.modes = modes;
      }),
      false,
      { type: 'importBackupConfig', config }
    );
    NotificationFactory.successNotification(
      i18next.t(
        'notifications:config_store.notification.import_backup_config.success',
        'Successfully config imported'
      ),
      '',
      {
        id: 'importBackupConfig'
      }
    );
  },
  importConfig: ({
    common = null,
    modes = null,
    importToApiConfig = false
  }: {
    common: any;
    modes: { slot: number; config: any }[] | { id: number; config: any }[] | null;
    importToApiConfig?: boolean;
  }) => {
    set(
      produce((state: ConfigStoreState) => {
        if (common) {
          if (importToApiConfig) {
            state.config.common.configAPI = common;
          } else {
            state.config.common.config = common;
          }
        }
        if (modes) {
          modes.forEach((modeInstalled) => {
            const modeReceiving = state.config.modes.find((_mode) => {
              if (modeInstalled?.slot || modeInstalled?.slot === 0)
                return _mode.slot === modeInstalled.slot;
              if (modeInstalled?.id || modeInstalled?.id === 0)
                return _mode.id === modeInstalled.id;
            });
            if (modeReceiving) {
              if (importToApiConfig) {
                modeReceiving.configAPI = modeInstalled.config;
              } else {
                modeReceiving.config = modeInstalled.config;
              }
            }
          });
        }
      }),
      false,
      { type: 'importConfig', common, modes }
    );
  },
  handleProcedure: async ({
    procedureNumber,
    liveSession,
    preventInput = true,
    showPreventInputMessage = true
  }: {
    procedureNumber: ProcedureTypes;
    liveSession?: liveSessionProps;
    preventInput?: boolean;
    showPreventInputMessage?: boolean;
  }) => {
    const infoMessage = toast.loading(
      i18next.t('configurator:config_store.notification.running_procedure', 'Running procedure...')
    );
    let procedureReply;
    const { blockScreen, unblockScreen } = useUiStore.getState();
    try {
      useUiStore.setState({ procedureState: FETCHING_STATES.loading });
      if (preventInput) blockScreen(BLOCK_MODALS.PROCEDURE, showPreventInputMessage);
      set({ currentlyRunningProcedure: procedureNumber });
      const input = Array(120).fill(0);

      if (useDeviceInfoStore.getState().connected) {
        switch (procedureNumber) {
          case ProcedureTypes.i2cCommunicationCheck:
            procedureReply = await getDevicesInfo();
            break;
          case ProcedureTypes.testClosing: {
            procedureReply = await testClosingProcedure(EVENTS.stopProcedure);
            break;
          }
          case ProcedureTypes.checkMovementRangeTestClosingCombined: {
            const [generalTestClosingData, closingTimeFingers, currentsFingers] =
              await testClosingProcedure(EVENTS.stopProcedure);
            const checkMovementRangeData = await runProcedure(
              ProcedureTypes.checkMovementRange,
              input,
              EVENTS.stopProcedure
            );
            await postAppReceivedProcedure(Commands.kFrameTypeProcedureReply);

            procedureReply = [
              generalTestClosingData,
              closingTimeFingers,
              currentsFingers,
              checkMovementRangeData
            ];
            break;
          }
          default:
            procedureReply = await runProcedure(procedureNumber, input, EVENTS.stopProcedure);
            await postAppReceivedProcedure(Commands.kFrameTypeProcedureReply);
            break;
        }
      }
      if (liveSession?.clinicianUUID) {
        const channelAbly = ablyClient(liveSession?.clinicianUUID).channels.get(
          liveSession!.channelName
        );
        await channelAbly.publish(CalibrationEvents.start, [procedureNumber, ...input]);
        procedureReply = await timeoutCommandCustom(
          () => listenAblyReply(channelAbly, CalibrationEvents.finished),
          CALIBRATION_PROCEDURE_TIMEOUT
        );
      }
      toast.dismiss(infoMessage);
      if (procedureReply) {
        set({ procedureReply, procedureUsedType: procedureNumber });
        NotificationFactory.successNotification(
          i18next.t(
            'notifications:config_store.notification.procedure_successful',
            'Procedure successful'
          )
        );

        useUiStore.setState({ procedureState: FETCHING_STATES.successful });
        return {
          procedureReply,
          type: procedureNumber
        };
      }
      useUiStore.setState({ procedureState: FETCHING_STATES.failed });
      NotificationFactory.errorNotification(
        i18next.t('notifications:config_store.notification.procedure_failed', 'Procedure failed')
      );
      return false;
    } catch (err) {
      toast.dismiss(infoMessage);
      useUiStore.setState({ procedureState: FETCHING_STATES.failed });
      return err;
    } finally {
      if (preventInput) unblockScreen(BLOCK_MODALS.PROCEDURE);
      set({ currentlyRunningProcedure: null });
    }
  },
  resetGripPositions: (grip: Grips) =>
    set(
      produce((state: any) => {
        state.config.common.config.gripsPositions[grip] = defaultConfig.gripsPositions[grip];
      }),
      false,
      { type: 'resetGripPositions', grip, value: defaultConfig.gripsPositions[grip] }
    ),
  consumeHistory: (idOrEvent: keyof typeof HISTORY_EVENTS | number) => {
    const previousState: ConfigStoreState = { ...get() };
    // @ts-ignore
    const change: HistoryEntryType = previousState.configHistory.findLast(
      (historyEntry: HistoryEntryType) =>
        typeof idOrEvent === 'number'
          ? historyEntry.id === idOrEvent
          : historyEntry.event === idOrEvent && historyEntry.fromSlot === previousState.slotSelected
    );

    if (!change) return;

    const { common } = previousState.config;

    let newCommon = common;
    if (!isEmpty(change.diffConfig.common.before)) {
      if (Object.keys(change.diffConfig.common.before).includes('gripsPositions')) {
        change.diffConfig.common.before.gripsPositions = {
          ...common.config.gripsPositions,
          ...change.diffConfig.common.before.gripsPositions
        };
      }
      newCommon = {
        ...common,
        config: { ...common.config, ...change.diffConfig.common.before }
      };
    }

    let newModes = previousState.config.modes;
    if (change.diffConfig.modes.length > 0) {
      newModes = previousState.config.modes.map((mode) => {
        const modeChange = change.diffConfig.modes.find((_mode) => _mode.slot === mode.slot);

        if (!modeChange) return mode;

        return { ...mode, config: { ...mode.config, ...modeChange.before } };
      });
    }
    set((state) => ({
      config: {
        common: newCommon,
        modes: newModes
      },
      configHistory: state.configHistory.filter((historyEntry) => historyEntry.id !== change.id)
    }));
    undoChannel.postMessage({ event: 'undo', diff: change.diffConfig });
  },
  clearConfigHistory: () => set({ configHistory: initialStateConfigStore.configHistory }),
  addSendingQueue: (sendObject) => {
    let newSendingQueue;
    set((state: ConfigStoreState) => {
      newSendingQueue = [...state.sendingQueue, { id: state.sendingQueue.length + 1, sendObject }];
      return { sendingQueue: newSendingQueue };
    });
    return newSendingQueue;
  },
  removeSendingQueue: () => {
    let newSendingQueue;
    set((state: ConfigStoreState) => {
      newSendingQueue = state.sendingQueue.slice(0, -1);
      return { sendingQueue: newSendingQueue };
    });
    return newSendingQueue;
  }
});

export const useConfigStore = create<ConfigStoreState>()(
  // @ts-ignore
  devtools(
    persist(store, {
      name: 'bluetooth',
      partialize: (state: any) => ({
        firstConnection: state.firstConnection
      })
    }),
    { name: 'Config' }
  )
);
