import { ProcedureTypes } from 'bluetooth/bluetoothCommunication/Procedures';
import {
  handleFingersSpeedProcedure,
  handleSoftGripProcedure
} from 'configurator/hooks/useProcedure';

export enum UPDATE_STATES {
  notInitialized,
  failedToFetch,
  inProgress,
  successful,
  failed,
  inProgressFix,
  fixSuccessful,
  fixFailed,
  reconnectionUnsuccessful
}

export enum PROCEDURE_STATUS {
  UNDEFINED,
  FAILED,
  SUCCESS,
  RUNNING
}

export const procedureMapping = {
  [ProcedureTypes.calibrateSoftGrip]: handleSoftGripProcedure,
  [ProcedureTypes.calibrateFingersSpeed]: handleFingersSpeedProcedure
};

export const procedureNamesMapping = {
  [ProcedureTypes.calibrateSoftGrip]: 'softGrip',
  [ProcedureTypes.calibrateFingersSpeed]: 'fingerSpeed'
};

export const procedureUserNameMapping = {
  [ProcedureTypes.calibrateSoftGrip]: 'soft grip',
  [ProcedureTypes.calibrateFingersSpeed]: 'finger speed'
};
