import { SingleElectrodeMode } from 'bluetooth/bluetoothCommunication/Control';
import { configSettingsNamesMap, singleElectrodeModeMap } from 'configurator/utils/definesLocal';
import { singleElectrodeSettingsAlternatingNamingsMap } from './deviceConfig/configPropertiesNamings';
import { singleElectrodeSettingsAlternatingPositions } from './deviceConfig/configPropertiesPositions';
import i18next from 'i18next';

export const EMERGENCY_MODE_NOTIFICATION = {
  translationKey: 'configurator:config_store.notification.emergency_mode',
  translationOptions: {
    defaultValue:
      'The battery in this device is low and it was disconnected. Emergency mode has been activated. Only opening the hand will be possible. The patient should connect the device to the power supply.'
  },
  message: `The battery in this device is low and it was disconnected. Emergency mode has been activated. Only opening the hand will be possible. The patient should connect the device to the power supply.`,
  options: { duration: 180000, icon: '⚠️', id: 'isEmergencyMode' }
};

export const EMG_SPIKE_WARNING = {
  translationKey: 'configurator:config_store.notification.emg_spike_conflict',
  translationOptions: {
    emgSpike: i18next.t(configSettingsNamesMap.get('emgSpike') || ''),
    singleElectrodeMode: i18next.t(configSettingsNamesMap.get('singleElectrodeMode') || ''),
    fastRisingMode: i18next.t(singleElectrodeModeMap.get(SingleElectrodeMode.kFastRising)),
    defaultValue:
      '{{emgSpike}} cannot be used together with {{singleElectrodeMode}}: {{fastRisingMode}}. It was automatically turned off.'
  },
  message: `${configSettingsNamesMap.get(
    'emgSpike'
  )} cannot be used together with ${configSettingsNamesMap.get(
    'singleElectrodeMode'
  )}: ${singleElectrodeModeMap.get(
    SingleElectrodeMode.kFastRising
  )}. It was automatically turned off.`,
  options: {
    id: 'emgSpikeWarning',
    duration: 10000
  }
};

export const SINGLE_ALTERNATING_TIMINGS = {
  translationKey: 'configurator:config_store.notification.minimum_time_conflict',
  translationOptions: {
    minTimeBetweenPulses: i18next.t(
      singleElectrodeSettingsAlternatingNamingsMap.get(
        singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
      ) || ''
    ),
    stateSwitchDelay: i18next.t(
      singleElectrodeSettingsAlternatingNamingsMap.get(
        singleElectrodeSettingsAlternatingPositions.stateSwitchDelay
      )
    ),
    defaultValue:
      '{{minTimeBetweenPulses}} cannot be more than: {{stateSwitchDelay}}. It was automatically adjusted.'
  },
  message: `${singleElectrodeSettingsAlternatingNamingsMap.get(
    singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
  )} cannot be more than: ${singleElectrodeSettingsAlternatingNamingsMap.get(
    singleElectrodeSettingsAlternatingPositions.stateSwitchDelay
  )}. It was automatically adjusted.`,
  options: {
    id: 'singleAlternatingTimingsWarning',
    duration: 10000
  }
};

export const FIRMWARE_COMPLETE_NOTIFICATION = {
  translationKey: 'configurator:config_store.notification.firmware_complete',
  translationOptions: {
    defaultValue: 'After update perform Soft-grip and Fingers speed calibrations'
  },
  message: 'After update perform Soft-grip and Fingers speed calibrations',
  options: { id: 'firmwareComplete', duration: 90000, icon: '⚠️' }
};
