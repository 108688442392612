import {
  emgThresholdsEntry,
  DeviceConfigTemplate,
  freezeModeEmgEntry
} from 'configurator/consts/deviceConfig/deviceConfig.types';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import {
  ControlModes,
  GripSwitchingModes,
  InputDevices,
  InputSites,
  SingleElectrodeMode
} from 'bluetooth/bluetoothCommunication/Control';
import { aetherBatteryVoltageFunctions } from 'configurator/utils/definesLocal';
import toast from 'react-hot-toast';
import { ConfigStoreState } from 'configurator/reducers/configStore';
import { EMG_SPIKE_WARNING } from 'configurator/consts/notifications';
import {
  FREEZE_MODE_OFF,
  FREEZE_MODE_ON_DUAL,
  FREEZE_MODE_ON_SINGLE
} from 'configurator/consts/deviceConfig/freezeMode';
import { singleElectrodeSettingsAlternatingPositions } from 'configurator/consts/deviceConfig/configPropertiesPositions';
import { getCurrentConfigSelector, getFwVersionSelector, getModeBySlotSelector } from './selectors';
import { checkBatteryBeepEmergency, checkEmgValidity } from './modifierFunctions';
import { NotificationFactory } from 'lib/NotificationFactory';
import i18next from 'i18next';

export const freezeModeEmgModifierControlConfig = ({
  newInputSite,
  versions,
  firmware,
  oldFreezeModeEmg
}) => {
  let newFreezeModeEmg = oldFreezeModeEmg;
  const canApplyFreezeModeFix =
    Number(versions?.current?.[1]) >= 2 || Number(firmware?.name[0]) >= 2;

  if (
    canApplyFreezeModeFix &&
    newInputSite === InputSites.kSingleElectrode &&
    oldFreezeModeEmg[2] === 1
  ) {
    newFreezeModeEmg = [oldFreezeModeEmg[0], oldFreezeModeEmg[1], 3, 3];
  }

  if (newInputSite !== InputSites.kSingleElectrode && oldFreezeModeEmg[2] === 3)
    newFreezeModeEmg = [oldFreezeModeEmg[0], oldFreezeModeEmg[1], 1, 2];

  return newFreezeModeEmg;
};

export type freezeModeValue = [0 | 1, number, number];

export const freezeModeEmgModifier = (
  freezeModeEmgValue: freezeModeValue,
  currentConfig: DeviceConfigTemplate,
  fwVersion,
  newConfig
) => {
  let value: freezeModeValue | freezeModeEmgEntry = freezeModeEmgValue;
  let triggered = false;
  const singleElectrodeModeSettings = currentConfig.singleElectrodeModeSettings;
  const inputSite = currentConfig.inputSite?.[0];
  const inputDevice = currentConfig.inputDevice?.[0];
  const emgThresholds = currentConfig.emgThresholds;
  const freezeModeSettings = currentConfig.freezeModeEmgSettings;
  const longDoubleOpening = freezeModeSettings[0];
  const longDoubleClosing = freezeModeSettings[1];
  const changeOpening = emgThresholds[0];
  const changeClosing = emgThresholds[1];
  const speed1Opening = emgThresholds[2];
  const Speed1Closing = emgThresholds[5];
  const [status, closing, opening] = value;
  value = [
    closing,
    opening,
    status ? FREEZE_MODE_ON_DUAL[0] : FREEZE_MODE_OFF[0],
    status ? FREEZE_MODE_ON_DUAL[1] : FREEZE_MODE_OFF[1]
  ] as freezeModeEmgEntry;

  const canApplyFreezeModeFix = fwVersion >= 20000 && inputSite === InputSites.kSingleElectrode;
  const minimalCloseSignal = Math.min(changeClosing, Speed1Closing, longDoubleClosing);
  const minimalOpenSignal = Math.min(changeOpening, speed1Opening, longDoubleOpening);

  // Dont touch or hands below 2.0 will crash, as Szymon stated, 'na mokro'
  if (canApplyFreezeModeFix) {
    value = [
      closing,
      opening,
      status ? FREEZE_MODE_ON_SINGLE[0] : FREEZE_MODE_OFF[0],
      status ? FREEZE_MODE_ON_SINGLE[1] : FREEZE_MODE_OFF[1]
    ] as freezeModeEmgEntry;
  }

  // Send FREEZE_MODE_ON_DUAL value to hand with COAPT
  if (inputDevice && inputDevice === InputDevices.kInputOptionPatRec) {
    value = [
      closing,
      opening,
      status ? FREEZE_MODE_ON_DUAL[0] : FREEZE_MODE_OFF[0],
      status ? FREEZE_MODE_ON_DUAL[1] : FREEZE_MODE_OFF[0]
    ] as freezeModeEmgEntry;
  }

  if (closing >= minimalCloseSignal) {
    triggered = true;
    value = [minimalCloseSignal - 1, value[1], value[2], value[3]] as freezeModeEmgEntry;
  }

  if (opening >= minimalOpenSignal) {
    triggered = true;
    value = [value[0], minimalOpenSignal - 1, value[2], value[3]] as freezeModeEmgEntry;
  }

  let adjustedConfig = {
    ...newConfig,
    freezeModeEmg: value
  };

  if (fwVersion >= 30000) {
    adjustedConfig = {
      ...adjustedConfig,
      singleElectrodeModeSettings: [
        value[1],
        singleElectrodeModeSettings[1],
        singleElectrodeModeSettings[2],
        singleElectrodeModeSettings[3]
      ]
    };
  }

  return { adjustedConfig, triggered };
};

export const singleElectrodeModeModifier = (singleElectrodeMode, currentConfig, newConfig) => {
  let adjustedConfig = {};
  const result = checkEmgValidity(
    { ...currentConfig, singleElectrodeMode },
    currentConfig.emgThresholds
  );
  const adjustedEmg = result?.emgThresholds;

  adjustedConfig = {
    ...newConfig,
    singleElectrodeMode,
    emgThresholds: adjustedEmg
  };

  return adjustedConfig;
};

export const singleElectrodeModeSettingsModifier = (
  singleElectrodeModeSettingsValue,
  currentConfig,
  newConfig,
  fwVersion
) => {
  let [startPointSignalThresholds] = singleElectrodeModeSettingsValue as any;
  const csOpening = currentConfig.emgThresholds[0];
  const thresholdOpening = currentConfig.emgThresholds[2];
  const freezeModeEmg = currentConfig.freezeModeEmg;
  const freezeModeSettings = currentConfig.freezeModeEmgSettings;
  let longDoubleOpening = freezeModeSettings?.[0];
  let adjustedConfig = {};
  let triggered = false;

  adjustedConfig = {
    ...newConfig,
    singleElectrodeModeSettings: singleElectrodeModeSettingsValue
  };

  const thresholdsMinValue = Math.min(thresholdOpening, csOpening);

  if (startPointSignalThresholds >= thresholdsMinValue) {
    startPointSignalThresholds = thresholdsMinValue - 1;
    adjustedConfig = {
      ...newConfig,
      singleElectrodeModeSettings: [
        startPointSignalThresholds,
        singleElectrodeModeSettingsValue[1],
        singleElectrodeModeSettingsValue[2],
        singleElectrodeModeSettingsValue[3]
      ]
    };
    triggered = true;
  }

  if (longDoubleOpening <= startPointSignalThresholds) {
    longDoubleOpening = startPointSignalThresholds + 1;
  }

  if (fwVersion >= 30000) {
    adjustedConfig = {
      ...adjustedConfig,
      freezeModeEmg: [
        freezeModeEmg[0],
        startPointSignalThresholds,
        freezeModeEmg[2],
        freezeModeEmg[3]
      ],
      freezeModeEmgSettings: [
        longDoubleOpening,
        freezeModeSettings?.[1],
        freezeModeSettings?.[2],
        freezeModeSettings?.[3]
      ]
    };
  }

  return { adjustedConfig, triggered };
};

export const speedControlStrategyModifier = (speedControlStrategy, currentConfig, newConfig) => {
  let adjustedConfig = {};
  const result = checkEmgValidity(
    { ...currentConfig, speedControlStrategy },
    currentConfig.emgThresholds
  );
  const validatedEmg = result?.emgThresholds;

  adjustedConfig = {
    ...newConfig,
    speedControlStrategy,
    emgThresholds: validatedEmg
  };

  return adjustedConfig;
};

export const emgThresholdsModifier = (currentConfig, newConfig) => {
  let adjustedConfig = {};
  const result = checkEmgValidity({ ...currentConfig }, currentConfig.emgThresholds) || {};

  adjustedConfig = {
    ...newConfig,
    ...result
  };

  return adjustedConfig;
};

export const freezeModeEmgSettingsModifier = (freezeModeEmgSettings, currentConfig, newConfig) => {
  let adjustedConfig = {};
  let longDoubleStageOpening = freezeModeEmgSettings[0];
  let longDoubleStageClosing = freezeModeEmgSettings[1];
  const relaxationOpening = currentConfig.freezeModeEmg[1];
  const relaxationClosing = currentConfig.freezeModeEmg[0];
  let triggered = false;

  if (longDoubleStageOpening <= relaxationOpening) {
    longDoubleStageOpening = relaxationOpening + 1;
    triggered = true;
  }

  if (longDoubleStageClosing <= relaxationClosing) {
    longDoubleStageClosing = relaxationClosing + 1;
    triggered = true;
  }

  adjustedConfig = {
    ...newConfig,
    freezeModeEmgSettings: [
      longDoubleStageOpening,
      longDoubleStageClosing,
      freezeModeEmgSettings[2],
      freezeModeEmgSettings[3]
    ]
  };

  return { adjustedConfig, triggered };
};

export const controlConfigModifier = ({
  newInputSite,
  newGripSwitchingMode,
  oldInputSite,
  prevState,
  versions,
  firmware,
  set
}) => {
  let applyGlobally = false;
  if (newInputSite === InputSites.kSingleElectrode) {
    newGripSwitchingMode = GripSwitchingModes.kSingleGripSwitching;
    applyGlobally = true;
  } else if (oldInputSite === InputSites.kSingleElectrode) {
    newGripSwitchingMode = GripSwitchingModes.kCoContraction;
    applyGlobally = true;
  }
  if (newGripSwitchingMode === GripSwitchingModes.kSingleGripSwitching) {
    newInputSite = InputSites.kSingleElectrode;
    applyGlobally = true;
  }
  // Set modes configs

  let modesApplied;
  const globalInputSite = getFwVersionSelector() < 20200;

  if (!globalInputSite) applyGlobally = false;

  if (applyGlobally) {
    modesApplied = prevState.config.modes;
  } else {
    modesApplied = [getModeBySlotSelector(prevState, prevState.slotSelected)];
  }

  // Dont touch or hands below 2.0 will crash, as Szymon stated, 'na mokro'
  const oldFreezeModeEmg = prevState.config.modes[0].config?.freezeModeEmg;

  // Set common config
  const newCommon = {
    ...prevState.config.common,
    config: {
      ...prevState.config.common.config,
      ...(globalInputSite && { inputSite: [newInputSite] })
    }
  };

  const newModes = prevState.config.modes.map((mode) => {
    if (modesApplied.find((_mode) => _mode.slot === mode.slot)) {
      return {
        ...mode,
        config: {
          ...mode.config,
          gripSwitchingMode: [newGripSwitchingMode],
          ...(!globalInputSite && { inputSite: [newInputSite] }),
          ...(oldFreezeModeEmg && {
            freezeModeEmg: freezeModeEmgModifierControlConfig({
              newInputSite,
              versions,
              firmware,
              oldFreezeModeEmg: mode.config.freezeModeEmg
            })
          })
        }
      };
    }
    return mode;
  });

  const newState = { config: { common: newCommon, modes: newModes } } as ConfigStoreState;

  // Apply all modifiers for new state
  const currentConfig = getCurrentConfigSelector(newState);
  const { newConfigModesSettings, triggered } = inputSiteModifier(
    currentConfig as DeviceConfigTemplate,
    newState,
    globalInputSite ? null : [newState.slotSelected]
  );

  if (triggered.find((element) => element.configName === 'emgSpike')) {
    NotificationFactory.errorNotification(EMG_SPIKE_WARNING.message, '', EMG_SPIKE_WARNING.options);
  }

  set(
    {
      config: { common: newCommon, modes: newConfigModesSettings }
    },
    false,
    {
      type: 'setControlConfig',
      common: newCommon,
      modes: newModes
    }
  );
};

export const applyModifierModes = (
  modifierFunctions: Array<{
    configName: keyof DeviceConfigTemplate;
    modifierFunction: (...args: any) => { adjustedSetting: any; triggered: boolean };
  }>,
  prevState,
  type,
  modesApplied: [number] | null = null
) => {
  const conflict: Array<{ configName: keyof DeviceConfigTemplate; mode: number }> = [];

  const newConfigModesSettings = prevState.config.modes.map((mode) => {
    if (modesApplied && !modesApplied.includes(mode.slot)) return mode;
    const currentConfig = getCurrentConfigSelector(prevState, mode.slot);
    const configObject = {};
    modifierFunctions.forEach((element) => {
      const { adjustedSetting, triggered } = element.modifierFunction(
        currentConfig as DeviceConfigTemplate
      );
      configObject[`${element.configName}`] = adjustedSetting;
      if (triggered) conflict.push({ configName: element.configName, mode: mode.slot });
    });
    return {
      ...mode,
      config: {
        ...mode.config,
        ...configObject
      }
    };
  });

  return { newConfigModesSettings, triggered: conflict };
};

export const inputDeviceAfterModifier = (
  currentConfig: DeviceConfigTemplate,
  newState: ConfigStoreState,
  currentMode: [number] | null
) =>
  applyModifierModes(
    [
      {
        configName: 'gripSwitchingMode',
        modifierFunction: (currentSlotConfig) => {
          if (currentSlotConfig.inputDevice?.[0] === InputDevices.kInputOptionElectrodes)
            return {
              adjustedSetting:
                currentSlotConfig.inputSite?.[0] === InputSites.kSingleElectrode
                  ? [GripSwitchingModes.kSingleGripSwitching]
                  : [GripSwitchingModes.kCoContraction],
              triggered: false
            };
          return { adjustedSetting: [GripSwitchingModes.kCoapt], triggered: false };
        }
      },
      {
        configName: 'controlMode',
        modifierFunction: (currentSlotConfig) => {
          if (currentSlotConfig.inputDevice?.[0] === InputDevices.kInputOptionElectrodes)
            return { adjustedSetting: [ControlModes.kGripPairs], triggered: false };

          if (currentSlotConfig.inputDevice?.[0] === InputDevices.kInputOptionPatRec) {
            NotificationFactory.warningNotification(
              i18next.t(
                'configurator:coapt_change_warning',
                'When switching from EMG to Pattern recognition you need to turn the hand off and on again in order for it to fully work.'
              ),
              '',
              { duration: 10000 }
            );

            return { adjustedSetting: [ControlModes.kCoapt], triggered: false };
          }

          return { adjustedSetting: currentSlotConfig.controlMode, triggered: false };
        }
      },
      {
        configName: 'freezeModeEmg',
        modifierFunction: (currentSlotConfig) => {
          if (
            currentSlotConfig.inputDevice?.[0] === InputDevices.kInputOptionPatRec &&
            currentSlotConfig.freezeModeEmg[3] !== FREEZE_MODE_OFF[0]
          )
            return {
              adjustedSetting: [
                currentSlotConfig.freezeModeEmg[0],
                currentSlotConfig.freezeModeEmg[1],
                FREEZE_MODE_ON_DUAL[0],
                FREEZE_MODE_ON_DUAL[1]
              ],
              triggered: false
            };

          if (
            currentSlotConfig.inputDevice?.[0] === InputDevices.kInputOptionElectrodes &&
            currentSlotConfig.freezeModeEmg[3] !== FREEZE_MODE_OFF[0]
          ) {
            if (currentSlotConfig.inputSite?.[0] === InputSites.kSingleElectrode) {
              return {
                adjustedSetting: [
                  currentSlotConfig.freezeModeEmg[0],
                  currentSlotConfig.freezeModeEmg[1],
                  FREEZE_MODE_ON_SINGLE[0],
                  FREEZE_MODE_ON_SINGLE[1]
                ],
                triggered: false
              };
            }
            return {
              adjustedSetting: [
                currentSlotConfig.freezeModeEmg[0],
                currentSlotConfig.freezeModeEmg[1],
                FREEZE_MODE_ON_DUAL[0],
                FREEZE_MODE_ON_DUAL[1]
              ],
              triggered: false
            };
          }
          return {
            adjustedSetting: [
              currentSlotConfig.freezeModeEmg[0],
              currentSlotConfig.freezeModeEmg[1],
              currentSlotConfig.freezeModeEmg[2],
              currentSlotConfig.freezeModeEmg[3]
            ],
            triggered: false
          };
        }
      }
    ],
    newState,
    'inputDeviceAdjustment',
    currentMode
  );

export const singleElectrodeModeAfterModifier = (
  currentConfig: DeviceConfigTemplate,
  newState: ConfigStoreState,
  currentMode: [number] | null
) =>
  applyModifierModes(
    [
      {
        configName: 'emgSpike',
        modifierFunction: (currentSlotConfig) => {
          if (
            currentSlotConfig.singleElectrodeMode?.[0] === SingleElectrodeMode.kFastRising &&
            currentSlotConfig.emgSpike?.[0] === 1
          )
            return {
              adjustedSetting: [0, currentSlotConfig.emgSpike[1]],
              triggered: true
            };
          return { adjustedSetting: currentSlotConfig.emgSpike, triggered: false };
        }
      }
    ],
    newState,
    'singleElectrodeModeAdjustments',
    currentMode
  );

export const inputSiteModifier = (
  currentConfig: DeviceConfigTemplate,
  newState: ConfigStoreState,
  currentMode: [number] | null
) => {
  const { newConfigModesSettings, triggered } = applyModifierModes(
    [
      {
        configName: 'emgSpike',
        modifierFunction: (currentSlotConfig) => {
          if (
            currentSlotConfig.inputSite?.[0] === InputSites.kSingleElectrode &&
            currentSlotConfig.singleElectrodeMode?.[0] === SingleElectrodeMode.kFastRising &&
            currentSlotConfig.emgSpike?.[0] === 1
          )
            return {
              adjustedSetting: [0, currentSlotConfig.emgSpike[1]],
              triggered: true
            };
          return { adjustedSetting: currentSlotConfig.emgSpike, triggered: false };
        }
      },
      {
        configName: 'emgThresholds',
        modifierFunction: (currentSlotConfig) => {
          const result = checkEmgValidity(
            currentSlotConfig as DeviceConfigTemplate,
            currentSlotConfig.emgThresholds as emgThresholdsEntry
          );
          const newEmg = result?.emgThresholds;
          return { adjustedSetting: newEmg, triggered: false };
        }
      }
    ],
    newState,
    'inputSiteAdjustments',
    currentMode
  );
  return { newConfigModesSettings, triggered };
};

export const emergencyBatterySettingsAfterModifier = (
  currentConfig: DeviceConfigTemplate,
  prevState: ConfigStoreState
) =>
  applyModifierModes(
    [
      {
        configName: 'batteryBeep',
        modifierFunction: (currentSlotConfig) => {
          if (currentSlotConfig.batteryBeep && currentSlotConfig.batteryBeep?.[1] !== 0) {
            return checkBatteryBeepEmergency(
              currentSlotConfig,
              6,
              aetherBatteryVoltageFunctions.voltageToPercent,
              aetherBatteryVoltageFunctions.percentToVoltage
            );
          }
          return { adjustedSetting: currentSlotConfig.batteryBeep, triggered: false };
        }
      }
    ],
    prevState,
    'checkBatteryBeepValidity'
  );

export const batteryBeepAfterModifier = (
  currentConfig: DeviceConfigTemplate,
  prevState: ConfigStoreState,
  currentMode: [number] | null
) => {
  // Dont make adjustments when battery beep is not in mode
  if (!currentMode) return { newConfigModesSettings: null, triggered: [] };

  return applyModifierModes(
    [
      {
        configName: 'batteryBeep',
        modifierFunction: (currentSlotConfig) => {
          if (
            currentSlotConfig.emergencyBatterySettings &&
            currentSlotConfig.emergencyBatterySettings?.[0] !== 0
          ) {
            return checkBatteryBeepEmergency(
              currentSlotConfig,
              6,
              aetherBatteryVoltageFunctions.voltageToPercent,
              aetherBatteryVoltageFunctions.percentToVoltage
            );
          }
          return { adjustedSetting: currentSlotConfig.batteryBeep, triggered: false };
        }
      }
    ],
    prevState,
    'checkBatteryBeepValidity',
    currentMode
  );
};

export const singleElectrodeSettingsAlternatingAfterModifier = (
  newState: ConfigStoreState,
  currentMode: [number] | null
) =>
  applyModifierModes(
    [
      {
        configName: 'singleElectrodeSettingsAlternating',
        modifierFunction: (currentSlotConfig: DeviceConfigTemplate) => {
          const minimumTimeBetweenPulses =
            currentSlotConfig.singleElectrodeSettingsAlternating?.[
              singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
            ];
          const stateSwitchDelay =
            currentSlotConfig.singleElectrodeSettingsAlternating?.[
              singleElectrodeSettingsAlternatingPositions.stateSwitchDelay
            ];

          if (minimumTimeBetweenPulses > stateSwitchDelay) {
            const adjustedSingleElectrodeSettingsAlternating =
              currentSlotConfig.singleElectrodeSettingsAlternating;

            adjustedSingleElectrodeSettingsAlternating![
              singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
            ] = stateSwitchDelay - 10;

            return {
              adjustedSetting: adjustedSingleElectrodeSettingsAlternating,
              triggered: true
            };
          }

          return {
            adjustedSetting: currentSlotConfig.singleElectrodeSettingsAlternating,
            triggered: false
          };
        }
      }
    ],
    newState,
    'singleElectrodeSettingsAlternatingAdjustmends',
    currentMode
  );
