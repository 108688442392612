import React, { useEffect, useState } from 'react';
import { Types } from 'ably';
import useRemoteSession from 'configurator/hooks/useRemoteSession';
import useUnsaved from 'configurator/hooks/useUnsaved';
import { ablyClient } from 'configurator/utils/LiveConfigurator/AblyClient';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useReplayStore } from 'configurator/reducers/replayStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import useTelemetry from 'configurator/hooks/bluetooth/useTelemetry';
import { useUiStore } from 'configurator/reducers/uiStore';
import useUserData from 'hooks/useUserData';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getUser } from 'adp-panel/api/users/users';
import { NotificationFactory } from 'lib/NotificationFactory';
import DeviceConfigurationService from 'bluetooth/configController/configController';

const BaseLogic = ({ children }: any) => {
  const { me } = useUserData();
  const { t } = useTranslation();
  const { enabled: liveConfiguratorEnabled, mobileNotifiedReady } = useLiveConfiguratorStore(
    (state) => ({
      enabled: state.enabled,
      getLiveSessionApi: state.getLiveSessionApi,
      mobileNotifiedReady: state.mobileNotifiedReady
    })
  );
  const { config, clearConfigHistory, configCopy } = useConfigStore((state: any) => ({
    config: state.config,
    clearConfigHistory: state.clearConfigHistory,
    configCopy: state.configCopy
  }));
  const { amputeeId, deviceId, deviceConnected } = useDeviceInfoStore((state: any) => ({
    amputeeId: state.amputeeId,
    deviceId: state.deviceId,
    firmware: state.firmware,
    deviceConnected: state.connected
  }));
  const { mainViewLoaded, setItemUiStore } = useUiStore((state: any) => ({
    mainViewLoaded: state.mainViewLoaded,
    setItemUiStore: state.setItemUiStore
  }));
  const [notificationsChannel, setNotificationsChannel] =
    useState<Types.RealtimeChannelPromise | null>();
  const { sendDifferencesDemo } = useUnsaved();
  const { sendConfig } = useRemoteSession();
  useTelemetry(true);

  // Send changes during demo mode and session

  useEffect(() => {
    sendDifferencesDemo();
    if (liveConfiguratorEnabled && mobileNotifiedReady) {
      sendConfig();
    }
  }, [
    JSON.stringify(config),
    JSON.stringify(configCopy),
    liveConfiguratorEnabled,
    mobileNotifiedReady
  ]);

  // Update config after receiving event from mobile

  useEffect(() => {
    if (amputeeId && me?.id) {
      if (notificationsChannel) {
        notificationsChannel.unsubscribe();
      }
      const channel = `notificationsConfig:${amputeeId}`;
      const notifications = ablyClient(`${me?.id}`).channels.get(channel);

      setNotificationsChannel(notifications);

      notifications?.subscribe('updateConfig', async () => {
        const amputee = await getUser(amputeeId, {});
        await DeviceConfigurationService.getInitialConfigAPI();
        clearConfigHistory();
        NotificationFactory.successNotification(
          t('notifications:notification.config_changed', {
            patient: amputee.name,
            defaultValue:
              'Patient {{patient}}, has changed configuration of one of their devices. Most recent configuration was retrieved.'
          }),
          '',
          { duration: 10000 }
        );
      });
    }

    return () => {
      if (notificationsChannel) {
        notificationsChannel.unsubscribe();
      }
    };
  }, [amputeeId, me?.id]);

  // Show warning before leaving in session

  useEffect(() => {
    if (!liveConfiguratorEnabled) return;

    function handleLeave(event: any) {
      event.returnValue = t(
        'configurator:notification.session_in_progress',
        'Session is in progress. Are you sure you want to leave?'
      );
    }

    window.addEventListener('beforeunload', handleLeave);

    return () => window.removeEventListener('beforeunload', handleLeave);
  }, [liveConfiguratorEnabled]);

  // Fetch device config from API at startup

  useEffect(() => {
    if (!liveConfiguratorEnabled && deviceId && !deviceConnected) {
      DeviceConfigurationService.getInitialConfigAPI();
    }
  }, [deviceId]);

  useEffect(() => {
    if (!mainViewLoaded) setItemUiStore('mainViewLoaded', true);
  }, []);

  return children;
};

const ReplayLogic = ({ children }: any) => {
  const configUrl = useReplayStore((state: any) => state.configUrl);
  const getTicketConfigApi = useConfigStore((state: any) => state.geTicketConfigApi);

  useEffect(() => {
    const fetchTicket = async () => {
      await getTicketConfigApi();
    };
    if (configUrl) {
      fetchTicket().catch(console.error);
    }
  }, [configUrl]);

  return children;
};

const MainViews = ({ children }: any) => {
  const { enabled: isRecordReplay } = useReplayStore();
  const location = useLocation();
  console.log(location.pathname);
  return isRecordReplay ? <ReplayLogic>{children}</ReplayLogic> : <BaseLogic>{children}</BaseLogic>;
};

export default MainViews;
