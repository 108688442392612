import {
  ControlModes,
  InputSites,
  SpeedControlStrategies,
  GripSwitchingModes,
  StartOnStartup,
  InputDevices
} from 'bluetooth/bluetoothCommunication/Control';
import { HandSide, ThumbLength } from 'bluetooth/bluetoothCommunication/Defines';
import { Grips } from '../../../bluetooth/bluetoothCommunication/Grips';
import { CommonConfigTemplate, ModeConfigTemplate } from './deviceConfig.types';

const handOpenPosition = 50;
const handClosePosition = 950;

export const defaultCommonConfig: CommonConfigTemplate = {
  gripsPositions: {
    [Grips.kGripRestOpp]: {
      initial: [700, handOpenPosition, handOpenPosition, handOpenPosition, handOpenPosition],
      limit: [
        handClosePosition,
        handClosePosition,
        handClosePosition,
        handClosePosition,
        handClosePosition
      ]
    },
    [Grips.kGripPower]: {
      initial: [
        handOpenPosition,
        handOpenPosition,
        handOpenPosition,
        handOpenPosition,
        handOpenPosition
      ],
      limit: [750, handClosePosition, handClosePosition, handClosePosition, handClosePosition]
    },
    [Grips.kGripTripodClosed]: {
      initial: [570, handOpenPosition, handOpenPosition, handClosePosition, handClosePosition],
      limit: [handClosePosition, 500, 450, handClosePosition, handClosePosition]
    },
    [Grips.kGripTrigger]: {
      initial: [
        handOpenPosition,
        handOpenPosition,
        handOpenPosition,
        handOpenPosition,
        handOpenPosition
      ],
      limit: [850, handClosePosition, handClosePosition, handClosePosition, handClosePosition]
    },
    [Grips.kGripPrecisionOpen]: {
      initial: [570, handOpenPosition, handOpenPosition, handOpenPosition, handOpenPosition],
      limit: [handClosePosition, 500, handClosePosition, handClosePosition, handClosePosition]
    },
    [Grips.kGripRestNopp]: {
      initial: [680, handOpenPosition, handOpenPosition, handOpenPosition, handOpenPosition],
      limit: [
        handClosePosition,
        handClosePosition,
        handClosePosition,
        handClosePosition,
        handClosePosition
      ]
    },
    [Grips.kGripHook]: {
      initial: [600, handOpenPosition, handOpenPosition, handOpenPosition, handOpenPosition],
      limit: [790, handClosePosition, handClosePosition, handClosePosition, handClosePosition]
    },
    [Grips.kGripKey]: {
      initial: [400, 760, 780, 800, 820],
      limit: [
        handClosePosition,
        handClosePosition,
        handClosePosition,
        handClosePosition,
        handClosePosition
      ]
    },
    [Grips.kGripMouse]: {
      initial: [500, 0, 0, 0, 700],
      limit: [800, 175, handClosePosition, 175, handClosePosition]
    },
    [Grips.kGripFingerPoint]: {
      initial: [650, handOpenPosition, handClosePosition, handClosePosition, handClosePosition],
      limit: [
        handClosePosition,
        handClosePosition,
        handClosePosition,
        handClosePosition,
        handClosePosition
      ]
    },
    [Grips.kGripCamera]: {
      initial: [350, 200, 200, handOpenPosition, handOpenPosition],
      limit: [
        handClosePosition,
        handClosePosition,
        handClosePosition,
        handClosePosition,
        handClosePosition
      ]
    },
    [Grips.kGripTripodOpen]: {
      initial: [570, handOpenPosition, handOpenPosition, handOpenPosition, handOpenPosition],
      limit: [handClosePosition, 500, 450, handClosePosition, handClosePosition]
    },
    [Grips.kGripPrecisionClosed]: {
      initial: [570, handOpenPosition, handClosePosition, handClosePosition, handClosePosition],
      limit: [handClosePosition, 500, handClosePosition, handClosePosition, handClosePosition]
    },
    [Grips.kGripFingerPointOpen]: {
      initial: [650, handOpenPosition, handOpenPosition, handOpenPosition, handOpenPosition],
      limit: [
        handClosePosition,
        handClosePosition,
        handClosePosition,
        handClosePosition,
        handClosePosition
      ]
    },
    [Grips.kGripCounting]: {
      initial: [790, 800, 800, 800, 800],
      limit: [790, 800, 800, 800, 800]
    },
    [Grips.kGripCustom1]: {
      initial: [50, 50, 50, 50, 50],
      limit: [900, 900, 900, 900, 900],
      activeFingers: [1, 1, 1, 1, 1]
    },
    [Grips.kGripCustom2]: {
      initial: [50, 50, 50, 50, 50],
      limit: [900, 900, 900, 900, 900],
      activeFingers: [1, 1, 1, 1, 1]
    },
    [Grips.kGripCustom3]: {
      initial: [50, 50, 50, 50, 50],
      limit: [900, 900, 900, 900, 900],
      activeFingers: [1, 1, 1, 1, 1]
    }
  },
  generalHandSettings: [
    HandSide.kHandLeft,
    ThumbLength.kThumbNotSet,
    StartOnStartup.kNotWaitForFirstCoContraction
  ],
  emergencyBatterySettings: [1, 1, 6500]
};

export const defaultModeConfig: ModeConfigTemplate = {
  gripPairsConfig: [1, 4, 2, 3, 6, 7, 9, 8],
  controlMode: [ControlModes.kGripPairs],
  speedControlStrategy: [SpeedControlStrategies.kThreshold],
  gripSwitchingMode: [GripSwitchingModes.kCoContraction],
  gripSequentialConfig: [1, 2, 4, 3, 0, 255, 6, 7, 9, 8, 255, 255],
  emgThresholds: [50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
  fingerStrength: [1, 100],
  autoGrasp: [0, 100],
  emgSpike: [0, 300],
  holdOpen: [2000, 2000],
  softGrip: [0],
  pulseTimings: [500, 1000, 500, 1000],
  coContractionTimings: [500, 200],
  singleElectrodeMode: [0],
  singleElectrodeModeSettings: [5, 100, 1500, 2500],
  freezeModeEmg: [0, 0, 0, 0],
  buzzingVolumeSettings: [0],
  userFeedbackType: [0],
  freezeModeEmgSettings: [50, 15, 2000, 4000],
  followingGrip: [1],
  emgGains: [100, 100],
  batteryBeep: [6800, 0],
  inputSite: [InputSites.kDualDirectElectrodes],
  inputDevice: [InputDevices.kInputOptionElectrodes],
  oneSpeed: [100],
  singleElectrodeSettingsAlternating: [1000, 1, 500, 150, 10, 300, 100]
};

export const defaultConfig = {
  ...defaultCommonConfig,
  ...defaultModeConfig
};

export const commonConfigProperties: Array<keyof CommonConfigTemplate> = [
  'gripsPositions',
  'generalHandSettings',
  'emergencyBatterySettings'
];

export const modeConfigProperties: Array<keyof ModeConfigTemplate> = [
  'autoGrasp',
  'buzzingVolumeSettings',
  'coContractionTimings',
  'controlMode',
  'emgSpike',
  'emgThresholds',
  'fingerStrength',
  'followingGrip',
  'freezeModeEmg',
  'freezeModeEmgSettings',
  'gripPairsConfig',
  'gripSequentialConfig',
  'gripSwitchingMode',
  'holdOpen',
  'pulseTimings',
  'singleElectrodeMode',
  'singleElectrodeModeSettings',
  'softGrip',
  'speedControlStrategy',
  'userFeedbackType',
  'batteryBeep',
  'emgGains',
  'inputSite',
  'inputDevice',
  'oneSpeed',
  'singleElectrodeSettingsAlternating'
];
