/* eslint-disable no-unused-vars */
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { getFirmwareVersion, getDevicesInfo, getSerialNumber } from 'bluetooth/bluetoothFunctions';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { numericalFirmwareVersion } from 'configurator/utils/funcs';
import { getDevice } from 'configurator/api/device/device';
import { DeviceQueryParams, DeviceExtendOptions } from 'configurator/api/device/device.types';
import { useUiStore } from './uiStore';
import i18next from 'i18next';
import { NotificationFactory } from 'lib/NotificationFactory';

interface setDeviceInfoType {
  serial: string | null;
  bluetoothId: string | null;
  deviceId: number | null;
  amputeeId: number | null;
}

export type DeviceInfoState = {
  serial: string | null;
  bluetoothId: string | null;
  deviceId: number | null;
  amputeeId: number | null;
  modelId: number | null;
  pcbVersion: string | null;
  pcb: { name: string } | null;
  firmware: any | null;
  firmwareConflict: boolean;
  connected: boolean;
  versions: {
    current: string | null;
    minimal: string | null;
    recent: string;
    numerical: number | null;
  } | null;
  supported: boolean;
  paired: {
    deviceId: number | null;
    paired: boolean;
  };
  deviceCode: {
    claimed: boolean;
    code: string | null;
  };
  setDeviceInfo: (deviceInfo: setDeviceInfoType) => void;
  setItemDeviceInfo: <T extends keyof DeviceInfoState>(
    property: T,
    value: DeviceInfoState[T]
  ) => void;
  getPcbVersionDevice: () => Promise<
    | false
    | {
        pcbVersion: number | null;
        serialNumber: string | null;
      }
  >;
  getFirmwareDevice: () => Promise<any>;
  getDeviceInfoAPI: (_deviceId?: string | null) => Promise<any>;
  setDeviceCode: (code: string | null) => void;
  setDeviceCodeClaimed: (claimed: boolean) => void;
  setConnectedStatus: (status: boolean) => void;
};

export const initialState = {
  serial: null,
  bluetoothId: null,
  deviceId: null,
  amputeeId: null,
  modelId: null,
  pcbVersion: null,
  pcb: {
    name: null
  },
  firmware: null,
  firmwareConflict: false,
  connected: false,
  versions: null,
  supported: true,
  deviceCode: {
    claimed: false,
    code: null
  }
};

const store = (set, get) => ({
  ...initialState,
  setDeviceInfo: (deviceInfo: setDeviceInfoType) =>
    set({
      serial: deviceInfo.serial,
      bluetoothId: deviceInfo.bluetoothId,
      deviceId: deviceInfo.deviceId,
      amputeeId: deviceInfo.amputeeId
    }),
  setDeviceCode: (code) =>
    set((state) => ({
      deviceCode: {
        ...state.deviceCode,
        code
      }
    })),
  setDeviceCodeClaimed: (claimed) =>
    set((state) => ({
      deviceCode: {
        ...state.deviceCode,
        claimed
      }
    })),
  setItemDeviceInfo: <T extends keyof DeviceInfoState>(property: T, value: DeviceInfoState[T]) =>
    set({ [`${property}`]: value }),
  getPcbVersionDevice: async () => {
    const { connected } = get();
    try {
      useUiStore.setState({ getPcbState: FETCHING_STATES.loading });
      if (connected) {
        let pcbVersion: number | null = null;
        const devicesInfo = await getDevicesInfo();
        const serialNumber = await getSerialNumber();
        let serialNumberString: string | null = null;
        if (devicesInfo) pcbVersion = devicesInfo[0] + 1;

        if (typeof serialNumber === 'string') serialNumberString = serialNumber;
        if (serialNumber && typeof serialNumber !== 'string')
          serialNumberString = serialNumber
            .map((item) => String.fromCharCode(item))
            .join('')
            .replace('\0', '')
            .replace(/\0/g, '')
            .trim();

        set({ pcbVersion, serial: serialNumberString });

        useUiStore.setState({ getPcbState: FETCHING_STATES.successful });
        return { pcbVersion, serialNumber: serialNumberString };
      }

      useUiStore.setState({ getPcbState: FETCHING_STATES.failed });
      return false;
    } catch (err) {
      console.log(err);
      useUiStore.setState({ getPcbState: FETCHING_STATES.failed });
      return false;
    }
  },
  getFirmwareDevice: async () => {
    const { connected } = get();
    const infoMessage = toast.loading(
      i18next.t('configurator:device_store.notification.checking_firmware', 'Checking firmware...')
    );
    try {
      useUiStore.setState({ firmwareState: FETCHING_STATES.loading });
      if (connected) {
        console.log('FIRMWARE');
        const firmwareVersion = await getFirmwareVersion();
        toast.dismiss(infoMessage);

        if (firmwareVersion) {
          const currentVersion = firmwareVersion
            .slice(0, 8)
            .map((item) => String.fromCharCode(item))
            .join('');
          const minimalVersion = firmwareVersion
            .slice(8, 16)
            .map((item) => String.fromCharCode(item))
            .join('');
          const firmwareVersionCurrentParsed = `${currentVersion[0]}${currentVersion[1]}.${currentVersion[3]}${currentVersion[4]}.${currentVersion[6]}${currentVersion[7]}`;
          set({
            versions: {
              current: currentVersion,
              minimal: minimalVersion,
              numerical: numericalFirmwareVersion(firmwareVersionCurrentParsed)
            }
          });

          useUiStore.setState({ firmwareState: FETCHING_STATES.successful });
          return { firmwareVersion };
        }

        NotificationFactory.errorNotification(
          i18next.t(
            'configurator:device_store.notification.fail_download_firmware',
            "Couldn't download firmware version"
          )
        );
        useUiStore.setState({ firmwareState: FETCHING_STATES.failed });
      }
      toast.dismiss(infoMessage);
      useUiStore.setState({ firmwareState: FETCHING_STATES.failed });
      return false;
    } catch (err) {
      toast.dismiss(infoMessage);
      useUiStore.setState({ firmwareState: FETCHING_STATES.failed });
      console.log(err);
      return false;
    }
  },
  getDeviceInfoAPI: async (_deviceId = null) => {
    try {
      let newDeviceId;

      if (_deviceId) {
        newDeviceId = _deviceId;
      } else {
        const { deviceId } = get();
        newDeviceId = deviceId;
      }

      if (newDeviceId === null || newDeviceId === undefined) return;
      const deviceQueryParams: DeviceQueryParams = {
        extend: [
          DeviceExtendOptions.firmwareVersion,
          DeviceExtendOptions.pcbVersion,
          DeviceExtendOptions.amputee
        ]
      };
      const deviceInfo = await getDevice(newDeviceId, deviceQueryParams);
      set({
        firmware: deviceInfo.firmware_version,
        serial: deviceInfo.serial,
        bluetoothId: deviceInfo.bluetooth_id,
        pcb: deviceInfo.pcb_version,
        modelId: Number(deviceInfo.model_id),
        amputeeId: Number(deviceInfo.amputee_id) || null
      });
      return deviceInfo;
    } catch (e) {
      console.log(e);
    }
  },
  setConnectedStatus: (status: boolean) => {
    set({ connected: status });
  }
});

export const useDeviceInfoStore = create<DeviceInfoState>()(
  // @ts-ignore
  persist(devtools(store, { name: 'Device info' }), {
    name: 'deviceInfo',
    // @ts-ignore
    partialize: (state: DeviceInfoState) => ({
      deviceId: state.deviceId,
      amputeeId: state.amputeeId,
      serial: state.serial,
      bluetoothId: state.bluetoothId
    })
  })
);
