/* eslint-disable react/jsx-key */
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { Tooltip } from '@mui/material';
import { ProcedureTypes } from 'bluetooth/bluetoothCommunication/Procedures';
import { FETCHING_STATES } from 'configurator/consts/consts';
import useProcedureReply from 'configurator/hooks/useProcedure';
import TelemetryController from 'bluetooth/telemetryController';
import { useUiStore } from 'configurator/reducers/uiStore';
import { OptionsWithSlider } from 'configurator/components/molecules/Sliders/Sliders';
import {
  CalibrationWrapper,
  ProcedureReplyWrapper,
  ProcedureRow,
  ProcedureStatusBox,
  ProcedureTable,
  ProcedureTableWrapper,
  Header2
} from 'configurator/components/molecules/CalibrationProcedure/styled';
import { useTranslation } from 'react-i18next';
import { NotificationFactory } from 'lib/NotificationFactory';

const FingersSpeed = ({
  sessionEnabled,
  sessionApi,
  channel,
  handleProcedure,
  disableCalibration
}: any) => {
  const { t } = useTranslation();
  const procedureFingersSpeed = useProcedureReply(ProcedureTypes.calibrateFingersSpeed);
  const procedureState = useUiStore((state: any) => state.procedureState);

  const handleFingerSpeedCalibration = async () => {
    const voltage = await TelemetryController.checkVoltage();

    if (!voltage) {
      NotificationFactory.errorNotification(
        t(
          'configurator:component.fingers_speed.voltage_error',
          `Couldn't read voltage value, please reconnect the device`
        )
      );
      return;
    }

    if (Number(voltage) < 750) {
      NotificationFactory.errorNotification(
        t('configurator:component.fingers_speed.low_voltage_error', {
          voltage: (voltage / 100).toFixed(2),
          defaultValue:
            'Current battery voltage ({{voltage}}V) is too low for fingers speed calibration, please charge the battery to 7.50V and repeat the procedure'
        })
      );
      return;
    }

    handleProcedure({
      procedureNumber: ProcedureTypes.calibrateFingersSpeed,
      ...(sessionEnabled && {
        liveSession: {
          clinicianUUID: sessionApi?.clinician_uuid,
          channelName: channel?.name
        }
      })
    });
  };

  return (
    <>
      <OptionsWithSlider
        header={t('configurator:component.fingers_speed.header', 'Fingers speed calibration')}
        toggleOptions={[
          'configurator:constants.generic_switching.on',
          'configurator:constants.generic_switching.off'
        ]}
        disabled
        toggleValue={0}
        sliderValue={0}
        handleSliderChange={() => false}
        sliderLimits={{ min: 0, max: 0 }}
        sliderLabel={<></>}
        handleToggleChange={() => false}
        handleSliderOnAfterChange={() => false}
        description={t(
          'configurator:component.fingers_speed.description',
          'Used to set correct fingers speed after repair and firmware update'
        )}
        hideInput
        tooltip={t('configurator:component.fingers_speed.tooltip', 'Hello')}
      />
      <CalibrationWrapper>
        {procedureFingersSpeed && (
          <ProcedureReplyWrapper>
            <Header2 as='h4'>
              {t('configurator:component.fingers_speed.procedure_results', 'Procedure results')}
            </Header2>
            <ProcedureTableWrapper>
              <ProcedureTable>
                <ProcedureRow>
                  <td
                    aria-label={t(
                      'configurator:component.fingers_speed.procedure_information',
                      'Procedure information'
                    )}>
                    &nbsp;
                  </td>
                  <td>{t('configurator:component.fingers_speed.thumb', 'Thumb')}</td>
                  <td>{t('configurator:component.fingers_speed.index', 'Index')}</td>
                  <td>{t('configurator:component.fingers_speed.middle', 'Middle')}</td>
                  <td>{t('configurator:component.fingers_speed.ring', 'Ring')}</td>
                  <td>{t('configurator:component.fingers_speed.pinky', 'Pinky')}</td>
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}> {t('configurator:calibration.status', 'Status')}</td>
                  {procedureFingersSpeed.status!.map((status, index) => (
                    // @ts-ignore
                    <ProcedureStatusBox colSpan={1} status={procedureFingersSpeed.status![index]}>
                      {status === 1
                        ? t('configurator:component.fingers_speed.ok', 'OK')
                        : t('configurator:component.fingers_speed.not_ok', 'NOT OK')}
                    </ProcedureStatusBox>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>{t('configurator:component.fingers_speed.speed', 'Speed')}</td>
                  {procedureFingersSpeed.speeds!.map((current) => (
                    <td colSpan={1}>{current}</td>
                  ))}
                </ProcedureRow>
              </ProcedureTable>
            </ProcedureTableWrapper>
          </ProcedureReplyWrapper>
        )}
        <Tooltip
          title={
            disableCalibration
              ? t(
                  'configurator:component.fingers_speed.device_not_connected',
                  'Device not connected'
                )
              : null
          }>
          <span>
            <LoadingButton
              sx={{ width: 250 }}
              disabled={disableCalibration}
              loading={procedureState === FETCHING_STATES.loading}
              onClick={handleFingerSpeedCalibration}>
              <span>
                {t(
                  'configurator:component.fingers_speed.calibration_procedure',
                  'Calibration procedure'
                )}
              </span>
            </LoadingButton>
          </span>
        </Tooltip>
      </CalibrationWrapper>
    </>
  );
};

export default FingersSpeed;
