import { Chip, Tooltip } from '@mui/material';
import {
  ControlModes,
  GripSwitchingModes,
  SingleElectrodeMode
} from 'bluetooth/bluetoothCommunication/Control';
import { useConfigStore } from 'configurator/reducers/configStore';
import { getCurrentConfigSelector } from 'configurator/reducers/helpers/selectors';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const GripSwitchingModeIndicator = ({
  primary = true,
  style
}: {
  primary?: boolean;
  style?: any;
}) => {
  const { gripSwitchingMode, singleElectrodeMode, controlMode } =
    useConfigStore(getCurrentConfigSelector);
  const { t } = useTranslation();

  const primaryGripSwitching = (gripSwitchingMode, singleElectrodeMode) => {
    if (gripSwitchingMode === GripSwitchingModes.kSingleGripSwitching) {
      if (singleElectrodeMode === SingleElectrodeMode.kAlternating)
        return t('configurator:grip_switching.alternating.primary', 'Single alternating primary');
      if (singleElectrodeMode === SingleElectrodeMode.kFastRising)
        return t('configurator:grip_switching.slope.primary', 'Single slope primary');
    }
    const primaryGripSwitchingMap = {
      [GripSwitchingModes.kCoContraction]: t(
        'configurator:grip_switching.short_co_contraction',
        'Short co-contraction'
      ),
      [GripSwitchingModes.kOpenOpen]: t(
        'configurator:grip_switching.two_open_pulses',
        '2 open pulses'
      ),
      [GripSwitchingModes.kHoldOpen]: t(
        'configurator:grip_switching.short_hold_open',
        'Short hold-open'
      )
    };
    return primaryGripSwitchingMap[gripSwitchingMode];
  };

  const secondaryGripSwitching = (gripSwitchingMode, singleElectrodeMode) => {
    if (gripSwitchingMode === GripSwitchingModes.kSingleGripSwitching) {
      if (singleElectrodeMode === SingleElectrodeMode.kAlternating)
        return t(
          'configurator:grip_switching.alternating.secondary',
          'Single alternating secondary'
        );
      if (singleElectrodeMode === SingleElectrodeMode.kFastRising)
        return t('configurator:grip_switching.slope.secondary', 'Single slope secondary');
    }
    const secondaryGripSwitchingMap = {
      [GripSwitchingModes.kCoContraction]: t(
        'configurator:grip_switching.long_co_contraction',
        'Long co-contraction'
      ),
      [GripSwitchingModes.kOpenOpen]: t(
        'configurator:grip_switching.three_open_pulses',
        '3 open pulses'
      ),
      [GripSwitchingModes.kHoldOpen]: t(
        'configurator:grip_switching.long_hold_open',
        'Long hold-open'
      )
    };
    return secondaryGripSwitchingMap[gripSwitchingMode];
  };

  const primaryGripSwitchingTooltip = (gripSwitchingMode, singleElectrodeMode, controlMode) => {
    if (gripSwitchingMode === GripSwitchingModes.kSingleGripSwitching) {
      if (singleElectrodeMode === SingleElectrodeMode.kAlternating)
        return t(
          'configurator:grip_switching.alternating.primary.tooltip',
          'Single alternating primary tooltip'
        );
      if (singleElectrodeMode === SingleElectrodeMode.kFastRising)
        return t(
          'configurator:grip_switching.slope.primary.tooltip',
          'Single slope primary tooltip'
        );
    }
    if (gripSwitchingMode === GripSwitchingModes.kCoContraction) {
      if (controlMode === ControlModes.kGripPairs)
        return t('configurator:grip_switching.short_co_contraction.tooltip', 'Long co-contraction');
      if (controlMode === ControlModes.kGripSequence)
        return t(
          'configurator:grip_switching.short_co_contraction_sequence.tooltip',
          'Long co-contraction'
        );
    }
    if (gripSwitchingMode === GripSwitchingModes.kOpenOpen) {
      if (controlMode === ControlModes.kGripPairs)
        return t('configurator:grip_switching.two_open_pulses.tooltip', 'Long co-contraction');
      if (controlMode === ControlModes.kGripSequence)
        return t(
          'configurator:grip_switching.two_open_pulses_sequence.tooltip',
          'Long co-contraction'
        );
    }
    if (gripSwitchingMode === GripSwitchingModes.kHoldOpen) {
      if (controlMode === ControlModes.kGripPairs)
        return t('configurator:grip_switching.short_hold_open.tooltip', 'Long co-contraction');
      if (controlMode === ControlModes.kGripSequence)
        return t(
          'configurator:grip_switching.short_hold_open_sequence.tooltip',
          'Long co-contraction'
        );
    }
  };

  const secondaryGripSwitchingTooltip = (gripSwitchingMode, singleElectrodeMode, controlMode) => {
    if (gripSwitchingMode === GripSwitchingModes.kSingleGripSwitching) {
      if (singleElectrodeMode === SingleElectrodeMode.kAlternating)
        return t(
          'configurator:grip_switching.alternating.secondary.tooltip',
          'Single alternating secondary tooltip'
        );
      if (singleElectrodeMode === SingleElectrodeMode.kFastRising)
        return t(
          'configurator:grip_switching.slope.secondary.tooltip',
          'Single slope secondary tooltip'
        );
    }
    if (gripSwitchingMode === GripSwitchingModes.kCoContraction) {
      if (controlMode === ControlModes.kGripPairs)
        return t('configurator:grip_switching.long_co_contraction.tooltip', 'Long co-contraction');
      if (controlMode === ControlModes.kGripSequence)
        return t(
          'configurator:grip_switching.long_co_contraction_sequence.tooltip',
          'Long co-contraction'
        );
    }
    if (gripSwitchingMode === GripSwitchingModes.kOpenOpen) {
      if (controlMode === ControlModes.kGripPairs)
        return t('configurator:grip_switching.three_open_pulses.tooltip', 'Long co-contraction');
      if (controlMode === ControlModes.kGripSequence)
        return t(
          'configurator:grip_switching.three_open_pulses_sequence.tooltip',
          'Long co-contraction'
        );
    }
    if (gripSwitchingMode === GripSwitchingModes.kHoldOpen) {
      if (controlMode === ControlModes.kGripPairs)
        return t('configurator:grip_switching.long_hold_open.tooltip', 'Long co-contraction');
      if (controlMode === ControlModes.kGripSequence)
        return t(
          'configurator:grip_switching.long_hold_open_sequence.tooltip',
          'Long co-contraction'
        );
    }
  };

  if (gripSwitchingMode?.[0] === GripSwitchingModes.kCoapt) return null;

  return (
    <div style={style}>
      <Chip
        size='small'
        label={
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {primary
              ? primaryGripSwitching(gripSwitchingMode?.[0], singleElectrodeMode?.[0])
              : secondaryGripSwitching(gripSwitchingMode?.[0], singleElectrodeMode?.[0]) + ' '}
            <Tooltip
              title={
                primary
                  ? primaryGripSwitchingTooltip(
                      gripSwitchingMode?.[0],
                      singleElectrodeMode?.[0],
                      controlMode?.[0]
                    )
                  : secondaryGripSwitchingTooltip(
                      gripSwitchingMode?.[0],
                      singleElectrodeMode?.[0],
                      controlMode?.[0]
                    )
              }
              sx={{ cursor: 'pointer' }}>
              <InfoOutlinedIcon sx={{ fontSize: '17px' }} />
            </Tooltip>
          </div>
        }
        sx={{ backgroundColor: primary ? '#869DE1' : '#253786', color: '#FFF' }}
      />
    </div>
  );
};
