import i18next from 'i18next';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { NotificationFactory } from 'lib/NotificationFactory';
import { delay } from 'bluetooth/bluetoothCommunication/Utilities';
import { produce } from 'immer';
import {
  getDeviceConfigurations,
  postCommunicateMode,
  postSaveSettings
} from '../../bluetooth/bluetoothFunctions';
import { getFirmwareKeys, getDeviceConfig } from 'configurator/api/device/device';
import { getModesConfigForDevice } from 'configurator/api/modes/modes';
import { compareConfigs } from 'configurator/reducers/helpers/bluetoothHelpers';
import { useConfigStore, ConfigType } from 'configurator/reducers/configStore';
import { isEmpty } from 'lodash';
import { DeviceConfigTemplate } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { ConfigParams } from './types';
import { BLOCK_MODALS } from 'configurator/consts/blockModals';
import { useReplayStore } from 'configurator/reducers/replayStore';
import { getTicketConfig } from 'configurator/api/tickets/tickets';
import {
  afterConfigurationConflictCheck,
  applyConfigurationUpdates,
  applyConfigurationUpdatesApi,
  fetchConfigs,
  getHashStatus,
  sendWholeConfigDevice
} from './helpers';
import { configurationType } from '../../bluetooth/bluetoothFunctions';

function mapDiffToDeviceRequest(diff) {
  return Object.keys(diff).map((property) => ({
    name: property,
    arguments: []
  })) as configurationType[];
}

class DeviceConfigurationService {
  static _instance: any;

  constructor() {
    if (DeviceConfigurationService._instance) {
      return DeviceConfigurationService._instance;
    }
    DeviceConfigurationService._instance = this;
  }

  private static readonly RECURSION_LIMIT = 5;

  static async getInitialConfiguration({
    deviceId,
    connected,
    commonPropertiesAPI,
    modePropertiesAPI,
    hashStatus,
    set,
    setConfigCopy
  }: ConfigParams) {
    const { hashes, globalHash, modeHashes, hashSupported, isHashToggleEnabled } = hashStatus;

    const result = await fetchConfigs({
      deviceId,
      connected,
      commonPropertiesAPI,
      modePropertiesAPI,
      hashes
    });

    if (!result) return;

    const hashIsEnabled =
      hashes.global.mismatch && hashSupported && isHashToggleEnabled && deviceId;

    await applyConfigurationUpdates({
      deviceId,
      globalHash,
      modeHashes,
      hashIsEnabled,
      result,
      set,
      setConfigCopy
    });

    return result;
  }

  static async getInitialConfigurationWrapped() {
    const { blockScreen, unblockScreen } = useUiStore.getState();
    try {
      blockScreen(BLOCK_MODALS.FETCHING_CONFIG);
      const { deviceId, connected } = useDeviceInfoStore.getState();
      const set = useConfigStore.setState;
      const { setConfigCopy, commonPropertiesAPI, modePropertiesAPI } = useConfigStore.getState();

      if (!deviceId) throw 'No device id';

      const hashStatus = await getHashStatus(deviceId);

      const result = await this.getInitialConfiguration({
        deviceId,
        connected,
        commonPropertiesAPI,
        modePropertiesAPI,
        hashStatus,
        set,
        setConfigCopy
      });

      if (!result) throw 'Failed to download configuration';

      return { common: result.common, modes: result.modes };
    } catch (err) {
      console.error(err, 'INITIAL CONFIGURATION FAILED');
      return false;
    } finally {
      unblockScreen(BLOCK_MODALS.FETCHING_CONFIG);
    }
  }

  static async getInitialConfigAPI() {
    const { blockScreen, unblockScreen } = useUiStore.getState();
    const { deviceId, getDeviceInfoAPI } = useDeviceInfoStore.getState();
    if (!deviceId) {
      NotificationFactory.errorNotification(
        i18next.t('configurator:config_store.notification.device_id_missing', 'Device id missing')
      );
      return;
    }
    try {
      blockScreen(BLOCK_MODALS.FETCHING_CONFIG_API);
      const deviceInfo = await getDeviceInfoAPI();
      const configKeys = await getFirmwareKeys({
        firmwareId: Number(deviceInfo?.firmware_version_id)
      });

      let commonKeys: null | Array<keyof DeviceConfigTemplate> = null;
      let modesKeys: null | Array<keyof DeviceConfigTemplate> = null;

      if (configKeys) {
        modesKeys = configKeys.filter((item) => !item.is_common).map((item) => item.key);
        const commonKeysUnadjusted = configKeys
          .filter((item) => item.is_common)
          .map((item) => item.key);

        // Groups gripsPositions into one property
        const uniqueCommonKeys = Array.from(
          new Set(commonKeysUnadjusted.map((key) => key.split('.')[0]))
        ) as any;

        commonKeys = uniqueCommonKeys?.length > 0 ? uniqueCommonKeys : null;
      }
      const configAPI = await getDeviceConfig(Number(deviceId));
      const modesData = await getModesConfigForDevice({ deviceId });
      const modesActivity = modesData
        ? modesData.map((modeData) => ({ active: modeData.active, slot: modeData.slot }))
        : null;

      applyConfigurationUpdatesApi({
        configAPI,
        commonKeys,
        modesKeys,
        modesData: modesActivity
      });
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      unblockScreen(BLOCK_MODALS.FETCHING_CONFIG_API);
    }
  }

  static async geTicketConfigApi() {
    try {
      const { configUrl } = useReplayStore.getState();
      const { deviceId } = useDeviceInfoStore.getState();

      if (configUrl === null || !deviceId) {
        return null;
      }

      const response = await getTicketConfig(configUrl);

      applyConfigurationUpdatesApi({
        configAPI: response?.data?.config ? response.data.config : null
      });
    } catch (err: any) {
      console.log(err);
      return false;
    }
  }

  static async sendAllConfig(
    config: { commonDifference: any; modes: { difference: any; slot: number }[] },
    permanently = true
  ) {
    const { addSendingQueue, removeSendingQueue } = useConfigStore.getState();
    const { blockScreen, unblockScreen } = useUiStore.getState();
    let sendingQueue;
    try {
      console.log(config, 'SENDING');
      sendingQueue = addSendingQueue(config);

      if (sendingQueue.length > 1) {
        blockScreen(BLOCK_MODALS.SEND_ALL_CONFIG);
        await delay(2000);
      }

      await sendWholeConfigDevice({
        configToSend: config.commonDifference
      });

      for (let index = 0; index < config.modes.length; index += 1) {
        const element = config.modes[index];
        await postCommunicateMode(element.slot);
        await delay(100);
        await sendWholeConfigDevice({
          configToSend: element.difference
        });
      }
    } finally {
      sendingQueue = removeSendingQueue();
      if (sendingQueue.length === 0) unblockScreen(BLOCK_MODALS.SEND_ALL_CONFIG);
      if (permanently) {
        await postSaveSettings();
      }
    }
  }

  static async synchronizeConfig(config: ConfigType, fromConfigToApi: boolean = false) {
    const { blockScreen, unblockScreen } = useUiStore.getState();
    const { setConfigCopy, setItemConfigStore, slotSelected } = useConfigStore.getState();
    const { deviceId } = useDeviceInfoStore.getState();
    let attempts = 0;
    let isConflict = false;

    try {
      blockScreen(BLOCK_MODALS.SYNCHRONIZING_CONFIG);
      await delay(100);

      do {
        // --- Compare and Map Differences ---
        const [commonSource, commonTarget] = fromConfigToApi
          ? [config.common.configAPI, config.common.config]
          : [config.common.config, config.common.configAPI];

        const configDifferences = compareConfigs(commonSource, commonTarget);
        const modesDifferences = config.modes
          .map((mode) => {
            const [modeSource, modeTarget] = fromConfigToApi
              ? [mode.configAPI, mode.config]
              : [mode.config, mode.configAPI];
            const difference = compareConfigs(modeSource, modeTarget);
            return !isEmpty(difference) ? { ...mode, difference } : null;
          })
          .filter((diff) => diff !== null);

        // --- Send Config Differences to the Device ---
        // @ts-ignore
        await this.sendAllConfig({ commonDifference: configDifferences, modes: modesDifferences });

        const commonRequest = mapDiffToDeviceRequest(configDifferences);
        const commonDiff = await getDeviceConfigurations(commonRequest);

        const desynchronizedConfigsModes = modesDifferences.map((modeDiff) => ({
          slot: modeDiff?.slot,
          request: mapDiffToDeviceRequest(modeDiff?.difference)
        }));

        const modeDiff = await Promise.all(
          desynchronizedConfigsModes.map(async ({ slot, request }) => {
            if (slot !== undefined) await postCommunicateMode(slot);
            const currentConfig = await getDeviceConfigurations(request);
            return { slot, currentConfig };
          })
        );

        // --- Update the Config Using a Single Produce Call ---
        config = produce(config, (draft) => {
          // Update each mode config
          modeDiff.forEach(({ slot, currentConfig }) => {
            const modeIndex = draft.modes.findIndex((mode) => mode.slot === slot);
            if (modeIndex !== -1) {
              draft.modes[modeIndex].config = {
                ...draft.modes[modeIndex].config,
                ...currentConfig
              };
            }
          });
          // Update common config
          draft.common.config = { ...draft.common.config, ...commonDiff };
        });

        // --- Check for Conflicts ---
        isConflict = await afterConfigurationConflictCheck({
          deviceId,
          commonConfig: config.common.config,
          modesConfigs: config.modes
        });
        attempts++;

        setItemConfigStore('config', config);
      } while (isConflict && attempts < this.RECURSION_LIMIT);

      await postCommunicateMode(slotSelected);

      // Apply new config to store
      if (!fromConfigToApi) {
        await this.getInitialConfigAPI();
      }
      setConfigCopy();

      if (isConflict) {
        return false;
      }
      setItemConfigStore('configConflict', false);
      return true;
    } catch (e) {
      console.error(e, 'SYNCHRONIZATION FAILED');
    } finally {
      unblockScreen(BLOCK_MODALS.SYNCHRONIZING_CONFIG);
    }
  }
}

export default DeviceConfigurationService;
