import { BLUETOOTH_EVENTS } from 'bluetooth/bluetoothCommunication/BluetoothEvents';
import { Commands } from 'bluetooth/bluetoothCommunication/Defines';
import { EMERGENCY_MODE_NOTIFICATION } from 'configurator/consts/notifications';
import i18n from 'i18n';
import toast from 'react-hot-toast';
import BluetoothWebController from './bluetoothWeb';

const BluetoothEventsHandler = {
  eventsListening: false,
  eventListenerController: new AbortController(),
  initiateBluetoothEventsListening() {
    this.eventListenerController = new AbortController();
    if (!this.eventsListening) {
      window.addEventListener(`received${Commands.kEvent}`, this.handleBluetoothEvent.bind(this), {
        signal: this.eventListenerController.signal
      });
      this.eventsListening = true;
    }
  },
  abortBluetoothEventsListening() {
    this.eventListenerController.abort();
    this.eventsListening = false;
  },
  handleBluetoothEvent(event) {
    const bluetoothEventType: BLUETOOTH_EVENTS = event.detail[0].payload[0];

    switch (bluetoothEventType) {
      case BLUETOOTH_EVENTS.kExternalEventTypeGripChanged: {
        const currentGrip = event.detail[0].payload[1];
        console.info('EVENT - GRIP CHANGE', currentGrip);
        break;
      }
      case BLUETOOTH_EVENTS.kExternalEventTypeThumbOppositionChanged: {
        const thumbOpposition = event.detail[0].payload[1];
        console.info('EVENT - THUMB OPPOSITION CHANGED', thumbOpposition);
        break;
      }
      case BLUETOOTH_EVENTS.kExternalEventTypeEnteredEmergencyMode:
        console.info('EVENT - ENTERED EMERGENCY MODE');
        BluetoothWebController.disconnectBluetooth();
        toast(
          i18n.t(
            EMERGENCY_MODE_NOTIFICATION.translationKey,
            EMERGENCY_MODE_NOTIFICATION.translationOptions.defaultValue
          ),
          EMERGENCY_MODE_NOTIFICATION.options
        );
        break;
      default:
        console.info('EVENT', bluetoothEventType);
        break;
    }
  }
};

export default BluetoothEventsHandler;
