/* eslint-disable no-bitwise */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import { useEffect } from 'react';
import BluetoothWebController from 'bluetooth/bluetoothWeb';
import { delay } from 'bluetooth/bluetoothCommunication/Utilities';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useMeetingStore } from 'configurator/reducers/meetingStore';

const useTelemetry = (delayInit: boolean = false) => {
  const { localConfigFetched } = useConfigStore((state) => ({
    localConfigFetched: state.localConfigFetched
  }));
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const viewMode = useMeetingStore((state) => state.viewMode);

  const telemetryOn = async () => {
    await delay(500);
    if (!BluetoothWebController.telemetryEnabled) {
      BluetoothWebController.telemetryOn();
    }
  };

  // @ts-ignore
  useEffect(() => {
    if (deviceConnected && localConfigFetched) {
      telemetryOn();
    }

    const clean = async () => {
      if (deviceConnected && localConfigFetched) {
        await BluetoothWebController.telemetryOff();
      }
    };

    return () => {
      clean();
    };
  }, [localConfigFetched, viewMode, deviceConnected]);
};

export default useTelemetry;
